.import-modal-container {
    padding: 20px 0;
}

.import-instructions-container {
    padding: 0 15px;
}

.import-instructions-list {
    padding-left: 15px;
}

.imports-data-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imports-data-tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 480px) {
    .imports-data-container {
        font-size: 11px;
    }
}