@media (min-width: 768px) {
    .header-fixed {
        width: 100%
    }

    .header-fixed > thead,
    .header-fixed > tbody,
    .header-fixed > thead > tr,
    .header-fixed > tbody > tr,
    .header-fixed > thead > tr > th,
    .header-fixed > tbody > tr > td {
        display: block;
    }

    .header-fixed th{
        height: 30px;
    }

    .header-fixed > tbody > tr:after,
    .header-fixed > thead > tr:after {
        content: ' ';
        display: block;
        visibility: hidden;
        clear: both;
    }

    .header-fixed > tbody {
        overflow-y: auto;
        height: unset;
    }

    .header-fixed > tbody > tr > td,
    .header-fixed > thead > tr > th {
        float: left;
    }
}

tr.accepted {
    background-color: #f3ffef !important;
}

tr.declined {
    opacity: 0.6;
}

@media (min-height: 850px) {
    .header-fixed > tbody {
        overflow-y: auto;
        height: unset;
    }
}

@media (min-height: 900px) {
    .header-fixed > tbody {
        overflow-y: auto;
        height: unset;
    }
}