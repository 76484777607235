.doc-attachment-icon {
    height: 50px !important;
    width: 40px !important;
    color: #bababa;
}

.doc-attachment-crew-icon {
    height: 36px !important;
    width: 36px !important;
    color: #bababa;
}

.doc-attachment-icon:hover {
    height: 50px !important;
    width: 40px !important;
    cursor: pointer !important;
}

.doc-attachment-remove-icon {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 99;
}

.doc-attachment-crew-remove-icon {
    position: absolute;
    top: -5px;
    left: 0px;
    z-index: 99;
}

.doc-attachment-download-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 99;
    top: -5px;
    right: -5px;
    z-index: 1000;
}

.doc-attachment-download-icon:hover {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1000;
    cursor: pointer;
}

.doc-attachment-preview {
    vertical-align: top;
    position: relative;
    display: inline-block;
    max-height: 76px;
    max-width: 70px;
    user-select: none;
}
