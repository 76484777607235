.bold .abc-checkbox label {
    font-weight: bold;
}

.color.abc-checkbox input[type="checkbox"]:checked + label::after {
    color: white;
}

.abc-checkbox, .abc-radio {
    margin-top: 5px;
}

.abc-checkbox label {
    padding-left: 14px;
    padding-top: 2px;
}

.abc-checkbox:hover .color-picker,
.visible.color-picker {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    margin-left: 10px;
    border: 1px solid #d9d9d9;
}
.expand-top{
    position: absolute !important;
    top: -350px;
}
.color-picker.bigger{
    width: 43px !important;
    height: 43px !important;
}

.cdefault.abc-checkbox label::before {
    border: 2px solid #3a87ad
}

.cdefault.abc-checkbox input[type="checkbox"]:checked + label::before {
    background: #3a87ad
}