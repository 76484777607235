#my-accepted .react-bs-table-container{
    margin-bottom: 300px;
}
#my-accepted .footer-container {
    width: 100%;
    padding: 0px 200px;
}
@media screen and (max-width: 1700px) {
    #my-accepted .footer-container {
        width: 100%;
        padding: 0px 50px;
    }
}


#my-accepted .footer-container .col-md-4 {
    padding-left: 0px !important;
}
#my-accepted .footer-container .text-info {
    font-weight: 300;
}