.customer-timeline {
  height: calc(100% - 60px);
  margin: auto;

  ::-webkit-scrollbar {
    background: #fff;
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar:hover {
    background: #f3f3f3;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 10px;
    background: #7c7c7c;
  }

  #timeline-body {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: auto;
    height: 100%;
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      max-height: 954px;
    }
    @media(max-width: 991px) {
      max-height: 80vh;
    }
  }

  .timeline {
    position: relative;
    margin: 5px 0;
    background-color: #fff;
  }

  .timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #000;
    top: 0;
    bottom: 0;
    left: 30px;
  }

  .container {
    padding: 10px 0 10px 40px;
    width: calc(100% - 50px);
    position: relative;
    background-color: inherit;
    left: 1px;
  }

  .no-data {
    margin: 0;
    background: #fff;
    padding: 10px;
    text-align: center;
    font-weight: 900;
    font-size: 15px;
    color: #a9a9a9;
  }

  .circle {
    cursor: default;
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    left: -10px;
    background-color: white;
    border: 2px solid #FFF;
    top: 18px;
    border-radius: 50%;
    z-index: 1;
    color: #000;
  }

  .triangle {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
  }

  .content {
    border: 2px solid #FFF;
    background-color: #fff;
    position: relative;
    border-radius: 11px;
    padding: 5px;
  }

  .message-hidden {
    width: 13vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    max-height: 22px;

    p {
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    div:hover {
      p {
        -webkit-text-fill-color: #424242;
        text-decoration: underline;
      }
    }
  }

  .message-hidden:hover {
    color: #424242;
    text-decoration: underline;
  }

  .break-spaces {
    white-space: break-spaces;
  }

  .message-show {
    word-break: break-word;
    max-height: 50vh;
    height: auto;
    transition: 500ms;
    cursor: pointer;
    overflow-y: auto;
    cursor: auto;

    p {
      margin: 0
    }
  }

  .green-text-color {
    color: #28A745FF;
  }

  .add-new-note-wrapper {
    position: sticky;
    bottom: -1px;
    z-index: 1;
    background: #fff;
    padding: 0;
  }

  .pad5-0 {
    padding: 5px 0;
  }

  .add-new-note:hover {
    color: green;
  }

  .add-new-note {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    background: rgba(110, 220, 75, 0.2)
  }

  .editor {
    border-radius: 11px !important;
    border: 1px solid rgb(119, 119, 119) !important;
    margin-bottom: 0;
    word-break: break-all;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }

  .timeline-axis-date {
    color: #fff;
    background-color: #0527cc;
    width: fit-content;
    padding: 0 10px;
    margin: 5px 0 0 5px;
    border-radius: 10px;
    font-weight: 900;
    z-index: 1;
    position: inherit;
  }

  .customer-DateRangeFilter {
    .DateRangePicker_picker {
      z-index: 2;
    }
  }

  .text-right-top {
    text-align: right;
    padding-left: 0;
  }

  @media screen and (min-width: 1200px) {
    .customer-DateRangeFilter {
      .DateRangePickerInput {
        height: 24px !important;
      }

      .DateInput_input {
        height: 20px !important;

      }
    }
  }

  @media screen and (min-width: 1400px) {
    .message-hidden {
      width: 15vw;
    }
  }

  @media screen and (max-width: 1199px) {
    .filter-button {
      padding-left: 0;
    }

    .message-hidden {
      width: 35vw;
    }
  }

  @media screen and (max-width: 991px) {
    .message-hidden {
      width: 80vw;
    }
  }

  @media screen and (max-width: 769px) {
    #timeline-body {
      width: 90vw;
      overflow-x: hidden;
    }

    .timeline::after {
      left: 31px;
    }

    .container {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
      left: 0%;
    }

    .triangle {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
    }

    .circle {
      left: 17px;
    }

    .message-hidden {
      width: 65vw;
    }

    .vcenter {
      display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    #timeline-body {
      width: 85vw;
      overflow-x: hidden;

      .message-hidden {
        width: 80%;
      }
    }
  }
}