.container {
    padding-bottom: 15px;
}

.moreContactBtn {
    outline: none !important;
    background: none !important;
    border: none !important;
}

.defaultCursor {
    cursor: default !important;
}

.titleMobile {
    text-align: left !important;
    margin-left: -7px !important;
    font-size: 14px;
    letter-spacing: 1.025px;
    font-weight: 600;
    margin-bottom: 16px;
}

.alignColumnLeft {
    text-align: left !important;
    padding-left: 0 !important;
}

.btnNewRecord {
    padding: 4px 6px 4px 6px !important;
}

.ml4 {
    margin-left: 3px
}

.disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
}

.removeTableBorder {
    border-bottom: none !important;
    border-right: none !important;
    border-left: none !important;
}

.tw-10 {
    width: 10%;
}

.tw-20 {
    width: 20%;
}

.tw-30 {
    width: 30%;
}

.overflowScroll {
    display: block !important;
    overflow-y: auto !important;
    height: 90px !important;
}

.tableRow {
    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
}


.transformIcon {
    transition: all 0.5s ease-in;
    margin-left: 4px;
    color: grey;
    font-size: 16px;
}

.transformIcon.rotate {
    margin-left: 4px;
    transform: rotate(-180deg);
}

.contactNav {
    transition: opacity 0.6s linear !important;
    opacity: 0 !important;
    overflow: hidden;
}

.contactNav.open {
    opacity: 1 !important;
}

.filterInput {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    height: 28px !important;
}

.formPage {
    margin-top: 10px;
    max-height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.textAreaInput {
    height: 25vh !important;
}

@media (min-height: 500px) and (min-width: 1025px) {
    .formJumbotron {
        border-radius: 10px !important;
        height: 75vh !important;
    }

    .formPage {
        padding: 20px;
        max-height: 20vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .textAreaInput {
        height: 30vh !important;
    }
}

@media (min-height: 750px) and (min-width: 1025px) {
    .formJumbotron {
        border-radius: 6px !important;;
        height: 60vh !important;
    }

    .formPage {
        padding: 0;
        max-height: 25vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .textAreaInput {
        height: 25vh !important;
    }
}

@media (min-height: 1150px) and (min-width: 1025px) {
    .formJumbotron {
        border-radius: 6px !important;
        height: 53vh !important;
    }

    .formPage {
        max-height: 25vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .textAreaInput {
        height: 20vh !important;
    }
}

@media (min-height: 1200px) and (min-width: 1400px) {
    .formJumbotron {
        border-radius: 6px !important;
        height: 52vh !important;
    }

    .formPage {
        max-height: 22vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .textAreaInput {
        height: 20vh !important;
    }
}

@media (max-width: 1024px) {
    .form-group {
        text-align: left;
    }

    .centerJumbotronItems {
        height: 100%;
    }
}

.saveBtnPosition {
    position: absolute;
    right: 0;
}

.accept {
    font-size: 56px;
    color: green;
}

.errorText {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 10px !important;
    color: red !important;
    text-align: left !important;
    margin-left: 3px !important;
}

.centerJumbotronItems {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}