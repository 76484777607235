#dropzone-element {
  .dropzone-area {
    border: 1px dashed grey;
    border-radius: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    min-height: 100px;
  }
}