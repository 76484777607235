.stickies-wrapper {
    height: 100%;
}

.stickies-spacer {
    min-height: 200px;
}

.sticky-textarea {
    width: 100%;
}

.sticky-button {
    width: 90%;
    margin-left: 0;
}

.sticky-body {
    margin-top: 15px;
    margin-bottom: 15px;
}
.compact .sticky-body{
    margin-top: 0;
    margin-bottom: 0;
}
.compact .sticky {
    font-size: 12px;
    margin: 3px;
}

.compact .sticky.panel-body {
    padding: 5px;
}