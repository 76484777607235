#change-service-status-page {
  #wrapper{
    @media (max-height: 950px) {
      overflow: scroll;
      width: 96vw;
    }
  }
  #wo-ps-form, #service-header{
    min-width: 920px;
  }
  .rdtPicker {
    width: 250px;
  }

  #completed-at {
    @media screen and (min-width: 1000px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
    @media screen and (max-width: 999px) {
      display: grid;
      justify-content: end;
    }

    .global-date-time-picker-input {
      width: 155px;
    }
  }
}