.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.Pane {
    padding: 15px;
}

.pane-wrapper {
    height: 85vh;
}

.modal-body .prices h4 {
    font-size: 15px;
}

.master-form.in.modal .master-form-header.modal-header p.inline-block.top10 > span > div > div.col-xs-10 {
    padding-right: 0;
}

.master-form.in.modal .modal-footer {
    padding: 0 !important;
}

.DraftEditor-editorContainer > div > div > div > div {
    margin: 0 !important;

}

.master-form.in.modal .master-form-header.modal-header > div > div:nth-child(1) > div > div:nth-child(2) > div > div > p.inline-block.top10 > span > div > div.col-xs-10 {
    margin: 0 0 10px 10px
}

@media (max-height: 720px) and (min-width: 1200px) {
    .master-form.in.modal .master-form-header b, .master-form.in.modal .master-form-header h4 {
        font-size: 14px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        line-height: 1 !important;
    }
    .master-form.in.modal .modal-body b, .master-form.in.modal .modal-body h4 {
        font-size: 12px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        line-height: 1 !important;
    }
    .master-form.in.modal .mt-15 {
        margin-top: 0 !important;
    }

}

.master-form.in.modal .master-form-header.modal-header .vertical-align.direction-row > small {
    margin-right: 1rem;
}

.master-form.in.modal .master-form-header.modal-header > div > div:nth-child(1) > div > div:nth-child(1) > div.vcenter.minus-bottom15.row > div.col-xs-10 > span > div {
    min-height: 36px;
    margin-bottom: 9px;
    color: white;
}
.master-form-header > div > div > div > div:nth-child(1) > div.vcenter.minus-bottom15.row > div.col-xs-10 > span > div > div.col-xs-10 > span > div {
    color: white;
    margin-bottom: 9px;
}

.master-form.in.modal .master-form-header.modal-header .vcenter.minus-bottom15.row > div.col-xs-10 > span > div > div.col-xs-10 > span > div {
    margin-bottom: 0 !important;
}

.master-form.in.modal .master-form-header.modal-header > div > div:nth-child(1) > div > div:nth-child(2) > div > div > div > div:nth-child(1) > span > div > div.col-xs-10 {
    padding: 0;
}

.master-form.col-md-3 .master-form-body .no-margin.mt-15.minus-right10.row :nth-child(1) > div > div > div {
    overflow: unset;
    height: unset;
}

.cancel-button-mobile {
    margin-right: -11px;
    margin-bottom: 5px !important;
}

@media (min-width: 992px) {
    .master-form.in.modal .modal-lg {
        width: 1000px;
    }
}

@media (min-width: 768px) and (max-height: 700px) {
    .master-form.in.modal .master-form-header b, .master-form.in.modal .master-form-header h4 {
        font-size: 13px !important;
        margin-top: 7px !important;
        margin-bottom: 0 !important;
        line-height: 1 !important;
    }
}