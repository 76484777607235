.dnd-table {
  font-size: 13px;
  font-weight: 200;
  overflow-y: scroll;
  table-layout: fixed;

  .sort {
    vertical-align: sub;
    svg {
      width: 10px;
    }
    .non-sort {
      svg {
        color: #d5d5d5;
      }
    }
    .ascendingly, .descending {
      svg {
        margin-left: 2px;
      }
    }
  }

  th {
    display: table-cell;
    text-align: left;
    padding: 8px;
    cursor: pointer;
    color: #444444;
    border-bottom: 2px solid #e1e1e1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :last-child {
      display: inline;
    }
  }

  tr:not(:first-child) {
    width: 100%;
    border-top: 1px solid #e1e1e1;
  }

  tr:active {
    border: none;
    border-radius: 3px;
    background-color: #f6f6f6;
  }

  tr:nth-child(even) {
    background-color: white;
  }

  tr:nth-child(odd):not(.header) {
    background-color: #f9f9f9;
  }

  tr:hover:not(.header) {
    background-color: #f5f5f5;
  }

  .header {
    background-color: white;
  }

  td {
    text-align: left;
    vertical-align: top;
    padding: 8px;
  }

  .no-data-td {
    text-align: center;
    color: #777777;
  }

  .padlock {
    width: 4%;
    font-size: 17px;
    padding: 6px 0;
  }
  @media only screen and (max-width: 700px) {
    .padlock {
      width: 20px;
    }
  }
}