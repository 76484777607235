.customer-info, .actions-info {
    display: none;
    position: absolute;
    z-index: 9999;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #000;
    font-weight: normal;
    text-transform: none;
    cursor: initial;
    color: initial;
    line-height: initial;
    user-select: text;
}


#proposal-page .actions-info.slate a, #proposal-page .customer-info.slate ul li a {
    color: #2e97d9;
}
#proposal-page .actions-info.slate a:hover, #proposal-page .customer-info.slate ul li a:hover {
    color: #FF8C00;
    border-bottom: 3px solid #FF8C00;
}

.customer-link:hover + .customer-info, .customer-link + .customer-info:hover {
    display: block;
}

@media (min-width: 993px) {
    .customer-info{
        width: 100%;
    }
}

@media (max-width: 992px) {
    .customer-link:hover + .customer-info, .customer-link + .customer-info:hover {
        width: 70%;
    }

    .customer-info .row .col-xs-12 ul {
        display: block !important;
    }

    .customer-info .row .col-xs-12 ul li {
        max-height: 15px;
    }

    .customer-info .row .col-xs-12 ul li a {
        padding: 0 !important;
    }

    .customer-info .text-success {
        margin: 0 !important;
    }

    .customer-info .row:nth-child(2) {
        margin-top: 20px;
    }
}

.actions-link:hover + .actions-info, .actions-link + .actions-info:hover {
    display: block;
}

.inline-select {
    display: inline-block;
    margin: 0 20px !important;
}

.inline-select > span > div {
    width: auto;
    display: inline-block;
}

.inline-select .Select {
    width: 150px;
}

.inline-select > span > div > div {
    width: auto;
    top: 18px;
    padding: 0;
}

.top18 {
    top: 18px;
}

.loading {
    margin-top: 20px;
}

.contact-phone{
    color: #d9230f !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    line-height: 0px !important;
}

.contact-phone:hover{
    border-bottom: 0px !important;
}

@media (max-width: 425px) {
    .inline-select {
        display: unset;
        margin: 0;
    }

    .well-filter .well {
        width: auto!important;
        float: none!important;
        margin: 20px auto!important;
    }

    .inline-select > span > div {
        display: unset;
    }


    .inline-select > span > div > div {
        width: 100%;
        top: unset;
    }

    .display-grid {
        display: grid;
        width: 100%;
    }
}

