#crew-work-orders .panel {
  margin-bottom: -2px !important;
}

#crew-work-orders .panel-body {
  padding: 0 !important;
}

#crew-work-orders .list-group {
  display: flex;
  flex-direction: column;
}

#crew-work-orders .panel-heading {
  border-bottom: 1px solid #dddddd !important;
  font-size: 15px;
  color: #444444;
  background-color: #fcfcfc;
}

.pending {
  background-color: #56C1FE !important;
}

.in.progres {
  background-color: #ffdd75 !important;
}

.completed {
  background-color: #fa9a14 !important;
}

.removed {
  background-color: #ff6338 !important;
}

.job-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selects-container{
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.employee-header{
  font-weight: bold;
}
.crew-edit-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: bold;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;

  }
}

.ml-15 {
  margin-left: -15px;
}

.resource-panel .panel-heading {
  background-color: #fa9a14 !important;
}

.highlighted {
  box-shadow: inset 0 0 8px 8px #fdff6c
}

#crew-work-orders > div.vcenter.row > div:nth-child(2) > .Select {
  width: 100%;
}

#crew-work-orders .rdtPicker {
  right: unset !important;
}

.messages-dock-container {
  overflow-y: scroll !important;
  height: calc(100vh - 200px) !important;
}

.messages-dock-container::-webkit-scrollbar {
  width: 10px;
}

.messages-dock-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.message-dock {
  height: 100vh !important;
  overflow: hidden !important;
}

.messages-dock-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.mark_as_read {
  height: 10px;
  width: 1px;
  background: #808080;
  margin-left: 7px;
  margin-right: 7px
}

.message-thread {
  cursor: pointer !important;
}

.message-thread:hover {
  text-decoration: underline;
}

.message-unread {
  color: #ce4f0c;
}

.crew-messages-icon {
  color: #474949 !important;
}

.message-image-wrapper {
  display: flex;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}
