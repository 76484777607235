#admin_transactions {
    padding: 20px;
}

#admin_transactions .transactions_title {
    font-weight: bold;
    color: #777777;
    letter-spacing: 0.25px;
}

#admin_transactions .transactions_header_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

#admin_transactions .transactions_count {
    margin: 10px 0 0 4px;
    font-size: 12px;
    color: #d9230f;
}

#admin_transactions .react-bs-table {
    max-height: calc(100vh - 250px) !important;
    height: auto !important;
    overflow: auto !important;
}

#admin_transactions .vertical-responsive-height {
    height: calc(100vh - 190px);
}

#admin_transactions .transactions_filter {
    padding: 7px 8px 7px 8px;
}

#admin_transactions .react-bs-container-header.table-header-wrapper {
    position: absolute;
    padding-right: 15px;
    background-color: white;
}

#admin_transactions .react-bs-container-body {
    margin-top: 40px;
}

#admin_transactions .uppercase {
    text-transform: uppercase;
    font-weight: bold;
}

@media (min-width: 1025px) {
    #admin_transactions .react-bs-table-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    #admin_transactions .react-bs-table-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

#admin_transactions .dropdown-toggle{
    margin: 18px 0;
}