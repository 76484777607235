.setup-progress-bar{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  width: 100%;
  .bar-container{
    margin-left: 10px;
    width: 100%;
    .title{
      font-weight: bold;
    }
    .bar-border{
      width: 100%;
      border: 1px solid lightgrey;
      border-radius: 10px;
      background-color: #f3f3f3;
      .bar{
        background-image: linear-gradient(to right, yellow, green);
        border-radius: 10px;
        height: 8px;
      }
    }

  }
}