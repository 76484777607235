.header-fixed {
    width: 100%;
    border: #cecece 1px solid;
    border-collapse: unset;
    border-radius: 4px;
    border-spacing: 0;
}

.header-fixed > thead {
    background-color: #fcfcfc;
    border: 1px solid #dddddd;
    border-bottom: 0;
}

@media (width: 768px) and (height: 1024px) {
    .header-fixed > thead {
        background-color: #d5d5d5;
        padding-top: 0 !important;
    }
}

.table-striped > tbody > #row-striped:nth-of-type(odd) {
    background-color: #eeeded;
}

.table-striped > tbody > #row-striped:nth-of-type(odd) {
    background-color: #ffffff;
}

.table-striped > tbody > #row-striped:hover {
    background-color: #e3e3e3;
}

@media (min-width: 768px) {
    .header-fixed {
        width: 100%;
    }

    .header-fixed > thead,
    .header-fixed > tbody,
    .header-fixed > thead > tr,
    .header-fixed > tbody > tr,
    .header-fixed > thead > tr > th,
    .header-fixed > tbody > tr > td {
        display: block;
    }

    .header-fixed th {
        height: 30px;
    }

    .header-fixed > tbody > tr:after,
    .header-fixed > thead > tr:after {
        content: ' ';
        display: block;
        visibility: hidden;
        clear: both;
    }

    .customer-view > tbody {
        overflow-y: auto;
        height: unset !important;
    }

    .header-fixed > tbody > tr > td,
    .header-fixed > thead > tr > th {
        float: left;
    }
}


.accepted {
    background-color: #f3ffef !important;
    tr:first-child {
        background: red;
    }
}

tr.declined {
    opacity: 0.6;
}