#deletedProposalsList .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;
}

#deletedProposalsList .vertical-responsive-height {
    height: calc(100vh - 162px);
}
#deletedProposalsList .react-bs-container-header.table-header-wrapper {
    position: absolute;
    padding-right: 15px;
    background-color: white;
}

#deletedProposalsList .react-bs-container-body {
    margin-top: 36px;
}

#deletedProposalsList .dropdown-toggle{
    margin: 18px 0;
}

@media (min-width: 1025px) {
    #deletedProposalsList .react-bs-table-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}