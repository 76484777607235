#customerProposal .react-bs-table {
    max-height: calc(100vh - 225px) !important;
    height: auto !important;
    overflow: auto !important;
}

#customerProposal tbody > tr > td {
    table-layout: fixed;
    word-wrap: break-word;
    white-space: normal !important;
}

#customerProposal .SplitPane {
    max-height: calc(100vh - 135px) !important;
    overflow-x: hidden !important;
}

#customerProposal .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#customerProposal .panel {
    margin-bottom: 10px !important;
}

#customerProposal .panel > div > div {
    padding-bottom: 0 !important;
}

#customerProposal .proposalMap {
    height: calc(100vh - 135px) !important;
}

#customerProposal .react-bs-container-header.table-header-wrapper {
    z-index: 10 !important;
}

#customerProposal .Resizer {
    z-index: 11 !important;
}

#customerProposal .react-bs-container-body {
    margin-top: 41px;
}

#customerProposal .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 20px 0 0 !important;
    display: flex !important;
}

.tooltip-inner {
    min-width: fit-content !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.serviceInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between !important;
    white-space: nowrap;
}

.serviceDetails {
    margin-left: 18px !important;
}

.serviceParamsWrapper {
    display: flex !important;
    width: 100% !important;
}

.serviceTitle {
    margin-bottom: 7px;
    font-weight: bold;
}

.css-11unzgr {
    max-height: 200px !important;
}

@media (min-width: 1025px) {
    #customerProposal .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #customerProposal .react-bs-table-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    #customerProposal .react-bs-table-pagination > div > div:nth-child(2) > ul {
        margin: 0 !important;
    }
}

@media (max-width: 468px) {
    .serviceInfo {
        display: flex;
        flex-direction: column;
        text-align: left !important;
        align-items: flex-start;
        justify-content: space-between !important;
        white-space: normal !important;
        font-size: 10px !important;
    }

    .serviceDetails {
        margin-left: 0 !important;
    }
}


.findTimeText {
    color: #d9230f;
    font-weight: 600;
}

