.DateInput_input {
    font-size: 13px;
    line-height: 16px;
}

.DateInput {
    width: 100% !important;
}

.DateRangePicker {
    width: 100% !important;
}

.DateRangePickerInput__withBorder {
    display: flex !important;
    align-items: center !important;
}