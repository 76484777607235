.buttons-bottom {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
}
@media screen and (max-width: 450px) {
    .detail-form {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 451px) {
    .detail-form{
        min-height: 90vh;
    }
}

.detail-form{
    display: flex;
    flex-direction: column;
}