.report-link {
    font-size: 16px;
    border-bottom: 1px solid #625e57;
    padding: 15px 0;
    width: 50%;
    justify-content: space-between;
    display: flex;
}

.report-favourite {
    color: #56C1FE;
}