.locationModal {
  text-align: center;

  .modal-content{
    height: auto !important;
  }

  .locationModal-buttons {
    display: flex;
    justify-content: space-around;
  }
}
