#proposal-page .panel-title {
  font-size: 0.9em;
  padding-left: 10px;
}

#proposal-page .disabled-region {
  opacity: 0.3;
  pointer-events: none;
}

#proposal-page .loading-spinner-ps {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -35px;
}

#proposal-page .panel-heading .panel-title {
  font-size: 1em !important;
}

#proposal-page .panel-title div {
  padding-left: 2px;
  padding-right: 2px;
}

#proposal-page .services {
  overflow-x: hidden;
  height: calc(100vh - 275px);
}

#proposal-page .bundle_services {
  height: calc(100vh - 330px);
}

.assets {
  overflow-x: hidden;
  max-height: calc(100vh - 290px);
}

#proposal-page .overflow-checkbox-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assets-list-row:hover {
  background-color: #e7e7e7 !important;
}

.list-right-icons {
  display: flex;
  align-items: center;
}

#proposal-page .Pane {
  padding: 0;
  margin-left: 6px;
}

#proposal-page .map .panel-body {
  padding: 0;
}

#proposal-page .map {
  padding-bottom: 0 !important;
}

#proposal-page > div.row > div.col-md-3.master-form > form > div.row, #proposal-page > div.row > div.col-md-1.master-form > form > div.row {
  padding-bottom: 0 !important;
}

#proposal-page > div.row > div.col-md-1.master-form > form > div.master-form-body.bottom10 > div:nth-child(1) > div > div > div {
  display: inherit;
  margin-left: 5px;
}

#proposal-page .asset-form-panel > .panel-body {
  padding: 10px 0 !important;
}

#proposal-page .no_text_wrap {
  text-wrap: nowrap;
}

#confirm-proposal-deleted-modal {
  .modal-content {
    max-width: 400px;
    margin: auto;
  }
}

.toggle-button {
  background-image: none !important;
  background-color: unset !important;
  border: none !important;
  outline: none !important;
  position: absolute !important;
  padding: 5px;
  border: 0 !important;
  box-shadow: none !important;
  top: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #proposal-page .wrap-text {
    min-width: 81px !important;
    word-wrap: break-word !important;
  }
}

@media (max-width: 992px) {
  .toggle-button {
    display: none !important;
  }
}

#proposal-page > div.row > .master-form > form {
  overflow: hidden;
}

#proposal-page > div.row > div.panel-fixed.master-form > form > div.master-form-body.bottom10 > div:nth-child(1) > span {
  font-size: 9px;
  text-align: center;
}

.panel-fixed .master-form-header {
  padding-top: 32px;
}

.circle-icon {
  width: 20px;
  height: 20px;
  margin: 0px auto;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  padding: 1px;
  font-size: 0px;
  color: rgb(255, 255, 255);
  display: block;
  border-width: 3px;
  border-style: solid;
}

.panel-fixed {
  position: fixed;
  z-index: 5;
  width: 51px;
}

.margin50 {
  padding-left: 50px !important;
}

.edit-map-button {
  position: absolute;
  top: 9px;
  right: 75px;
}

.edit-map-button button {
  background: white !important;
  color: #666666 !important;
  font-weight: bold !important;
  font-size: 13px !important;
  border: white !important;
  padding: 11px;
  padding-left: 12px;
  padding-right: 12px;
}

.edit-map-button button:hover {
  background: #e5e5e5 !important;
}

#proposal-page .master-form {
  height: calc(100vh - 110px);
}

@media (max-width: 991px) {
  #proposal-page .master-form {
    height: auto;
  }
}

.SplitPane {
  overflow: visible !important;
}

#proposal-page .collapsed-status {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

#proposal-page .buttons {
  text-transform: uppercase;
  font-weight: bold;
}

#proposal-page > div:nth-child(1) > div.top-menu.row > div.col-md-6 > button {
  padding: 1px 5px;
  margin-left: 10px;
}

#proposal-page > div.row > div.col-md-3.master-form > form > div.master-form-body.bottom10 > div:nth-child(4) > div:nth-child(1) > div > div {
  margin-left: -15px !important;
}

@media (max-width: 400px) {
  .form-control-mobile {
    padding: 4px !important;
    font-size: 10px !important;
  }

  .proposal_services_header_wrapper {
    flex-wrap: wrap;
  }

  #proposal-page .no_text_wrap {
    text-wrap: wrap;
  }
}

.map-full-screen {
  height: calc(100vh) !important;
}

.btn-add-marker {
  z-index: 99;
  position: absolute;
  top: 90px;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  right: 0;
  background-image: none;
  background-color: #fff;
}

.btn-add-marker:hover {
  color: unset !important;
}

.btn-add-marker:focus {
  color: unset !important;
}

.new-assets {
  top: 135px;
}

.btn-lock {
  z-index: 99;
  position: absolute;
  top: 45px;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  right: 0;
  background-image: none;
  background-color: #fff;
}

.btn-lock:hover {
  color: unset !important;
}

.btn-lock:focus {
  color: unset !important;
}

.btn-full-screen {
  z-index: 99;
  position: absolute;
  top: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  right: 0;
  background-image: none;
  background-color: #fff;
}

.btn-full-screen:hover {
  color: unset !important;
}

.btn-full-screen:focus {
  color: unset !important;
}

.legend-btn {
  z-index: 99;
  position: absolute;
  top: 90px;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  right: 0;
  background-image: none;
  background-color: #fff;
}

.legend-btn:hover {
  color: unset !important;
}

.legend-btn:focus {
  color: unset !important;
}

.legend {
  z-index: 99;
  position: absolute;
  top: 145px;
  right: 18px;
  padding: 5px 5px;
  width: 130px;
  height: fit-content;
  background-image: none;
  background-color: rgba(255, 255, 255, 0.7);
  list-style-type: none;
  border: 3px solid black;
}

.legend > b {
  color: black;
}

.legend > li {
  margin-top: 5px;
  display: flex;
}

.legend > li > p {
  width: 80px;
  margin-left: 5px;
  font-size: 10px;
  color: black;
}

.btn-measure {
  z-index: 99;
  position: absolute;
  top: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  right: 55px;
  background-image: none;
  background-color: #fff;
}

.btn-measure:focus {
  color: unset !important;
}

.btn-measure:hover {
  color: unset !important;
}

.no_white-space {
  white-space: inherit !important;
}

.full-map {
  overflow: visible !important;
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
}


@media (max-width: 1024px) {
  .full-map {
    position: fixed !important;
    overflow: visible !important;
    margin: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
  }
}

@media (max-width: 767px) {

  #leadMap > div:nth-child(2) > div > div:nth-child(9) {
    top: 50% !important;
    left: 0 !important;
  }

  #leadMap > div:nth-child(2) > div > div:nth-child(9) div {
    float: none !important;
  }

}

.uploaded_files-map_view {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  gap: 5px;
}

.uploaded_files-map_view .attachment {
  padding: 5px;
  border-radius: 5px;
}

.attachment-selected {
  background-color: #d7ead9;
  color: black;
}

.proposal_services_header {
  display: flex;
  align-items: center;
}

.bundle_services_button {
  height: 30px;
  background-image: linear-gradient(dodgerblue, dodgerblue 6%, dodgerblue) !important;
  margin-left: 20px;
  border-color: white !important;
}

.uploaded_files-map_view {
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  gap: 5px;
}

.uploaded_files-map_view .attachment {
  padding: 5px;
  border-radius: 5px;
}

.attachment-selected {
  background-color: #d7ead9;
  color: black;
}

.service_tab {
  position: relative;
  margin-left: 20px;
}

.bundle_services_checkbox {
  position: absolute !important;
  margin: 0 !important;
  left: -20px;
}

.bundle_services_checkbox label input {
  cursor: pointer !important;
}

.proposal-form-panel__bundle > .panel-heading {
  border: solid 1px #2490FF !important;
}

.proposal-form-panel .flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.proposal-form-panel .text-nowrap {
  text-wrap: nowrap;
}

.proposal-form-panel .panel-title div {
  padding-left: 2px;
  padding-right: 2px;
}

@media(min-width: 768px) {
  #bundle-services-warning-modal .modal-content {
    width: 60%;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .proposal-form-panel .flex-nowrap {
    display: block;
  }
}
