.assigned-arborist-modal {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(206, 206, 206, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c7c7c7;
  }

  .header-table-wrapper {
    display: flex;
    flex-wrap: nowrap;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }

  .header-table-element {
    display: inline-block;
    padding: 0 10px !important;
    text-align: center;
    vertical-align: top;
  }

  .react-bs-table-container {
    margin: 0;
  }

  .active-button {
    background-color: #fff !important;
    cursor: pointer;
    transition: all 0.2s;
  }

  .active-button:hover {
    background-color: #efefef !important;
    box-shadow: 1px 1px #d1d1d1;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .inactive-button:hover {
    background-color: #f8f8f8;
    box-shadow: 1px 1px #d1d1d1;
    border-radius: 5px;
    transition: all 0.2s;
  }

  .selected-arborist {
    box-shadow: 1px 1px #d1d1d1;
    border-radius: 5px;
  }

  .selected-arborist:hover {
    box-shadow: 1px 1px #d1d1d1;
    border-radius: 5px;
    transition: all 0.2s;
  }

  .border-right {
    border-right: 1px solid #e8e8e8;
  }
}