.proposal-cost {
  margin-top: 25px;
  padding: 25px;

  .non-billable {
    display: grid;
    grid-template-columns: auto auto;
  }
}

#proposal-cost > div:nth-child(4) > div.flex-column-start.col-md-6 > h4 > ul > li > a {
  padding: 0 !important;
  color: #56C1FE;
}

.proposal-cost > div {
  padding: 0 10px;
}

#proposal-cost {
  .rc-time-picker-input {
    height: 36px;
  }

  .table-container {
    &.loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}