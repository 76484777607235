.watermark-modal {
  .body {
    display: flex;
    flex-direction: column;
    padding: 20px 0;

    .option-label {
      font-weight: 400;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .watermark-modal-row {
      display: flex;
      gap: 20px;

      input[type=checkbox] {
        vertical-align: middle;
      }
    }
  }
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}