/*.alerts{
  z-index: 3773;
 transform: rotate(5deg);
}

.alerts-enter {
  opacity: 0.01;
  transform: translateY(-35px);
}

.alerts-enter.alerts-enter-active {
  opacity: 0.9;
  transition: all 200ms ease-in;
  transform: translateY(0px);
}

.alerts-leave {
  opacity: 0.9;
  transform: translateY(0px);
}

.alerts-leave.alerts-leave-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
  transform: translateY(-35px);
}*/


@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

 .alerts.alerts-enter {
    -webkit-animation-name: bounceIn;
    -webkit-animation-duration: .8s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    animation-name: bounceIn;
    animation-duration: .8s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }

  .alerts.alerts-leave {
    -webkit-animation-name: bounceOut;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
    animation-name: bounceOut;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }

