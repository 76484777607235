#activity-list .filter-button-small{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

#activity-list .dropdown-toggle{
    margin: 18px 0;
}

#activity-list .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;
}

#activity-list .vertical-responsive-height {
    height: calc(100vh - 162px);
    overflow-y: hidden;
}

@media (min-width: 1025px) {
    #activity-list .react-bs-table-pagination {
        position: absolute !important;
        bottom: 0 !important;
        width: 100% !important;
    }
}

@media (max-width: 1024px) {
    #activity-list .react-bs-table-pagination {
        margin-top: 0 !important;
    }

    #activity-list .react-bs-table-pagination .row {
        margin-top: 0 !important;
    }
}