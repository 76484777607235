#crew-work-orders-details {
  .mb-18 {
    margin-bottom: 18px;
  }
}
#crew-work-orders-details .list-group {
  display: flex;
  flex-direction: column;
}

#crew-work-orders-details .panel-heading {
  border-bottom: 1px solid #dddddd !important;
  font-size: 15px;
  color: #333333;
  background-color: #fcfcfc;
}

.work-order-details {
  border: 1px solid;
  margin: 0 !important;
  padding: 10px 0;
}

.work-order-details .col-xs-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.work-order-details .col-xs-3 span:first-child {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

#crew-work-orders-details .vertical-align.row {
  padding: 5px 15px;
}

#crew-work-orders-details .vertical-align.row .col-xs-4:nth-child(2) {
  display: flex;
  justify-content: center;
}

#crew-work-orders-details .vertical-align.row .col-xs-4:last-child {
  display: flex;
  justify-content: flex-end;
}

.plant-panel {
  background-color: #D6D5D5 !important;
}

.time-panel .panel-heading {
  background-color: #88FA4E !important;
  text-align: center;
}

.resources-panel .panel-heading {
  background-color: #ffdd75 !important;
  text-align: center;
}

.glyphicon-exclamation-sign {
  color: #ff0000;
}

.time-panel-header {
  padding: 10px 30px !important;
}

.mobile-margin {
  margin-top: -43px;
  margin-bottom: 10px
}

#crew-work-orders-details > div:nth-child(1) > div:nth-child(5) > span > div > div.col-xs-10 {
  margin-left: 15px;
}

#crew-work-orders-details > div:nth-child(1) > div:nth-child(1) > div.vcenter.row > div > a {
  margin-left: 15px;
  margin-top: 5px;
}

#crew-work-orders-details > div:nth-child(1) > div:nth-child(5) > span > .vcenter.row {
  display: block;
}

#crew-work-orders-details .input-sm {
  height: 36px;
}

.validate {
  border-color: red !important;
  box-shadow: 0 0 5px 0 rgba(255, 0, 0, 1) !important;
}

.exclamation::before {
  content: "\e101";
}

.remove-time {
  position: absolute;
  right: 10px;
  top: 10px;
}

.service-status {
  font-size: 12px;
  white-space: nowrap;
}

.no-header-margin {
  margin-top: 6vh !important;
  margin-right: 15px !important;
  margin-left: 0 !important;
}

.margin-left-15 {
  margin-left: -15px !important;
}


.service-panel {
  max-height: 400px;
  overflow: scroll;
  position: relative;

  .panel-heading {
    position: sticky;
    top: 0px;
    z-index: 2 !important;
  }
}

.edit-chemical-compliance-btn {
  padding: 4px 8px !important;
  outline: none !important;
  display: flex !important;
  gap: 10px;
}

#bundle-panel {
  .panel {
    .panel-heading {
      position: relative !important;
      z-index: 0 !important;
      background: #ffe7b0;

      .bundle-service-header {
        padding: 2px 0;

        a {
          display: flex;
          justify-content: space-between;
          text-decoration: none;
          align-items: center;
          cursor: pointer;

          div {
            p {
              margin: 0px !important;
            }
          }
        }
      }
    }

    .panel-body {
      .bundle-services__body {
        display: flex;
        flex-direction: column;
      }
    }

  }
}






