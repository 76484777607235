.paid_image{
    position: absolute;
    top: 0;
    width: 300px;
    margin-left: 40%;
    margin-top: 50px;
    opacity: 0.3;
}
ul{
    list-style-type: none
}
.arborist-info {
    display: flex;
    justify-content: space-evenly;
}

.counter {
    padding: 2px 3px;
    border-bottom: 3px solid lightgreen;
}
.header-row {
    font-size: 15px;
    padding: 10px 0px;
}
.price {
    float: right;
}
.single-service {
    width: 100%;
    padding: 0px 10px;
}
.single-service:nth-child(odd) {
    background-color: #f3f3f3;
}
.service-details{
    padding-bottom: 10px;
}
.trees{
    padding: 1px 1px;
    border-bottom: 2px solid darkgray;
}

.assets-container{
    padding: 10px 0px;
}

table {
    width: 100%;
}

.header-space {
    display: none;
}

#legend-assetSummary {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-assetSummary > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#legend-assetSummary span {
    margin: 1px 10px;
    display: inline-flex;
}

#legend-assetSummary span div {
    min-width: 25px;
}

#legend-assetSummary span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#legend-serviceType {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-serviceType > div {
    display: flex;
    flex-direction: column;
}

#legend-serviceType span {
    cursor: pointer;
    margin: 1px 10px;
    display: inline-flex;
}

#legend-serviceType span div {
    min-width: 25px;
}

#legend-serviceType span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#legend-serviceSummary {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-serviceSummary > div {
    display: flex;
    flex-direction: column;
}

#legend-serviceSummary span {
    cursor: pointer;
    margin: 1px 10px;
    display: inline-flex;
}

#legend-serviceSummary span div {
    min-width: 25px;
}

#legend-serviceSummary span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#print_invoice {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: left !important;
}

#print_invoice .invoice-page-header {
    margin: 12px 0;
    padding: 24px 0;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
}

#print_invoice .map-wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}
#print_invoice .map-wrapper > img {
    max-width: clamp(100%, 100%, 640px) !important;
}

#print_invoice h3{
    font-size: 1.8em;
}

#print_invoice .title {
    font-size: 1.4em;
}

#print_proposal .header-row{
    font-size: 1.2em;
    padding: 10px 0px;
}

.chart-wrapper {
    height: fit-content;
    width: fit-content;
    display: flex;
    padding-bottom: 20px;
}

.chart-wrapper > canvas {
    height: 200px !important;
}
#print_invoice .service-content{
    display: inline;
}
#print_invoice .asset-wrapper {
    display: block;
}

#print_invoice .asset-wrapper-inline {
    margin: 2px 2px;
    display: inline-block;
    vertical-align: top;
}

#print_invoice .asset-details {
}

#print_invoice .asset-details-vertically > span {
    display: block;
}



#print_invoice .thumbnails-vertically {
    margin: 0px 2px;
    display: block;
}

#print_invoice .thumbnails-vertically > img {
    margin: 2px 0px;

}
#print_invoice .thumbnails-horizontally {
    margin: 0px 2px;
    display: inline;
}
#print_invoice .thumbnails-horizontally > img {
    margin: 2px 0px;
}

#print_invoice .charts-align {
    display: flex !important;
}

@media (min-width: 1200px) {
    #print_invoice {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 1170px !important;
    }
}

@media (min-width: 992px) {
    #print_invoice {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 970px;
    }
}

/*@media (min-width: 768px) {*/
/*    #print_invoice {*/
/*        width: 750px;*/
/*    }*/
/*}*/

@media only screen and (min-width: 426px) and (max-width: 992px) {

    #print_invoice .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_invoice .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
    }

    #print_invoice .chart-wrapper {
        justify-content: center;
        height: fit-content;
        width: 100% !important;
    }
    #print_invoice .charts-align {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 425px) {

    #print_invoice {
        width: 90vw;
    }

    #print_invoice .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_invoice .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
    }

    #print_invoice .chart-wrapper {
        justify-content: center;
        height: fit-content;
        width: 100% !important;
    }
    #print_invoice .charts-align {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #print_invoice .map-wrapper {
        display: flex !important;
        flex-direction: column !important;
        max-width: 640px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }


    #print_invoice .map-wrapper > img {
        width: 100vw !important;
    }
}

@media print {
    #print_invoice, body {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .footer-space {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px
    }

    .header-space {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    .box {
        min-width: 12px !important;
        width: 12px !important;
        height: 7px !important;
        -webkit-print-color-adjust: exact;
    }

    .chart-wrapper > canvas {
        height: 100px !important;
    }

    #print_invoice .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
        display: flex;
        justify-content: flex-end;
    }

    #print_invoice .info-print {
        width: 50% !important;
        font-size: 10px !important;
    }

    #print_invoice .invoice-page-header {
        margin: 12px 0;
        padding: 24px 0;
        border-top: 1px solid #aaaaaa;
        border-bottom: 1px solid #aaaaaa;

        div {
            width: 50%;
        }
    }

    #print_invoice .map-wrapper {
        page-break-inside: avoid;
    }

    #print_invoice .map-wrapper > img {
        width: 60% !important;
    }

    #print_invoice .asset-wrapper {
        page-break-inside: avoid;
    }

    #print_invoice .asset-wrapper-inline {
        page-break-inside: avoid;
    }

    #print_invoice .asset-details-vertically {
        page-break-inside: avoid;
    }

    #print_invoice .asset-details {
        page-break-inside: avoid;
    }

    #print_invoice .services-page {
        page-break-inside: avoid;
        font-size: 10px !important;
    }

    #print_invoice .services-page > thead {
        page-break-inside: avoid;
    }
    #print_invoice .photo {
        page-break-inside: avoid;
        page-break-after: always;
    }

    #print_invoice .services-page > tr {
        border: 1px solid #dddddd !important;
    }

    .terms-wrapper {
        page-break-inside: avoid;
    }

    .map-print {
        page-break-inside: avoid;
    }

}
