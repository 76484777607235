.paid {
    position: absolute;
    top: 0;
    width: 300px;
    margin-left: 48%;
    margin-top: 50px;
    opacity: 0.3;
}

#invoice_payment .react-bs-container-body {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    max-height: 150px !important;
}

@media (min-width: 992px) and (max-width: 1536px){
    .paid {
        width: 250px;
        margin-top: 70px;
    }
}

@media (min-width: 992px) and (max-width: 1290px){
    .paid {
        width: 250px;
        margin-left: 45%;
        margin-top: 70px;
    }
}

@media (min-width: 992px) and (max-width: 1200px){
    .paid {
        width: 200px;
        margin-left: 45%;
    }
}

@media (width: 768px) and (height: 1024px){
    .ipad_btn_margin {
        margin-left: -5px !important;
    }
    .pr-10 {
        padding-right: 10px !important;
    }
    .radio_btn_left_padding {
        padding-left: 5px !important;
    }
}