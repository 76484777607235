.recorder{

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    .icon-container{
      padding: 20px;
      background-color: rgba(255, 99, 71, 0.8);
      border-radius: 50%;
    }
    .buttons{
      display: flex;
      flex-direction: row;
    }
  }



}

