#transfer_customer {
    padding: 20px
}

#transfer_customer .transfer_customer_title {
    font-weight: bold;
    color: #777777;
    letter-spacing: 0.25px;
}

#transfer_customer .transfer_customer_inputs {
    margin-top: 30px
}

#transfer_customer .transfer_customer_input {
    margin-top: 10px
}

#transfer_customer .transfer_btn {
    margin-top: 20px;
    background: #1766A6;
    border: 1px solid #1766A6;
}

#transfer_customer .copy_btn {
    margin-left: 20px;
    margin-top: 20px;
}

.transfer_tooltip_li {
    list-style-type: disc;
    color: #f7aa2d !important;
    margin: 1px 0 !important;
}

#tooltip ol {
    padding-left: 18px;
}