#quickbooks label.btn.btn-default {
    padding: 3px 12px;
    width: 50%;
}

#quickbooks .btn-group {
    width: 100%;
}

#quickbooks .panel-default {
    width: 100%;
    background-color: white;
    z-index: 9;
}

#quickbooks .quickbooks-sticky-panel {
    position: sticky;
    top: 0;
    z-index: 99;
}

#qb-import-modal > div > div {
    max-height: 50vh;
}

#quickbooks .react-bs-table-pagination{
    position: absolute;
    width: 100%;
    top: -50px;
}
#quickbooks #controlled-tab-example-pane-1 .container-fluid > .row {
    padding: 10px 10px;
}
#quickbooks #controlled-tab-example-pane-2 .container-fluid > .row {
    padding: 10px 10px;
}
#quickbooks #controlled-tab-example-pane-3 .container-fluid > .row {
    padding: 25px 10px;
}