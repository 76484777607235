#new_sales_dashboard .vertical-responsive-height {
    max-height: calc(100vh - 340px) !important;
}

#new_sales_dashboard .react-bs-table {
    max-height: calc(100vh - 400px) !important;
    height: auto !important;
    overflow: auto !important;
}

#new_sales_dashboard .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#new_sales_dashboard .react-bs-container-body {
    margin-top: 41px;
}

@media (min-width: 1024px) {
    #new_sales_dashboard .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }
}

#new_sales_dashboard .react-bs-container-header.table-header-wrapper {
    z-index: 10 !important;
}

@media (min-width: 1025px) {
    #new_sales_dashboard .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #new_sales_dashboard .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}

#new_sales_dashboard .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#new_sales_dashboard .panel {
    margin-bottom: 10px !important;
}

#new_sales_dashboard .panel > div > div {
    padding-bottom: 0 !important;
}

#new_sales_dashboard .react-bs-container-body {
    margin-top: 41px;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
}

@media (width: 768px) and (height: 1024px) {
    #new_sales_dashboard .proposalBadge {
        margin-left: 0 !important;
    }
}

@media (max-width: 1024px) {
    .pagination {
        display: flex !important;
    }

    #new_sales_dashboard .react-bs-table-pagination {
        margin-top: 10px !important;
    }

    #new_sales_dashboard .vertical-responsive-height {
        height: calc(100vh - 160px);
    }
}

@media (width: 1024px) {
    .top_nav_btn {
        padding: 3px 0 0 0 !important;
        min-width: 120px !important;
        max-width: 120px !important;
        font-size: 14px !important;
        height: 60px !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn {
        line-height: normal !important;
    }

    #new_sales_dashboard .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    #new_sales_dashboard .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 320px !important;
        max-width: 320px !important;
        font-size: 14px !important;
        height: 50px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #new_sales_dashboard .proposalBadge {
        margin-left: 0 !important;
    }
}

@media (max-width: 768px) {
    .pagination {
        display: flex !important;
    }

    #new_sales_dashboard .react-bs-table-pagination {
        margin-top: 10px !important;
    }

    #new_sales_dashboard .vertical-responsive-height {
        height: calc(100vh - 160px);
    }

    .top_nav_btn {
        padding: 3px 0 0 0 !important;
        min-width: 90px !important;
        max-width: 90px !important;
        font-size: 11px !important;
        height: 60px !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn {
        line-height: normal !important;
    }

    #new_sales_dashboard .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    #new_sales_dashboard .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        max-width: 220px !important;
        font-size: 12px !important;
        height: 50px !important;
        border: none !important;
        border-radius: inherit !important;
    }
}

@media (max-width: 468px) {
    #new_sales_dashboard .pagination > li > a {
        padding: 8px 9px !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn {
        line-height: normal !important;
    }

    #new_sales_dashboard .table_margin {
        margin: 30px 10px 0 10px !important;
    }

    #new_sales_dashboard .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    #new_sales_dashboard .btn_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 80px !important;
        max-width: 80px !important;
        font-size: 10px !important;
        height: 35px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #new_sales_dashboard .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 100px !important;
        max-width: 100px !important;
        font-size: 10px !important;
        height: 35px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #new_sales_dashboard .table_btn {
        padding: 8px 8px !important;
        font-size: 12px !important;
        height: 36px !important;
        border-radius: inherit !important;
    }

    #new_sales_dashboard .btn-wrapper {
        display: flex;
        justify-content: center;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #new_sales_dashboard .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    .tableHeader {
        display: flex;
        flex-direction: column;
    }
}

#new_sales_dashboard > div.top50.row {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 1px white solid;
}

.btn-group > .btn:not(:first-child):not(.dropdown-toggle) {
    border-left: 1px white solid;
}

#new_sales_dashboard > div.text-center.top5.row > div > a:not(:last-child) {
    border-right: 3px white solid;
}

#new_sales_dashboard > div.text-center.top5.row > div > a:not(:first-child) {
    border-left: 3px white solid;
}

#new_sales_dashboard > .date-range {
    margin-left: 15px;
    padding: 0;

}

