#logs-modal {
  .modal-content {
    min-height: 500px;
    max-height: 800px;
  }

  .logs-list {
    overflow-x: hidden;
    max-height: 375px;
  }

  .log-row {
    margin-bottom: 5px;
  }

  .mst {
    color: black;
    text-decoration: underline;
  }

  .mstir {
    color: orange;
    text-decoration: underline;
  }

  .load-more-button {
    color: red;
    cursor: pointer;
    text-align: center;
  }

  .no-data-text {
    text-align: center;
    font-size: 14px;
    color: grey;
    font-style: italic;
  }

  .footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}