#new-customer-info {
  $gray: #E0E0E0;
  $black: #000;
  $red: #FF0000;
  $white: #fff;
  $darker-gray: #d9d9d9;
  $dark-grey: #3E3E3E;
  $deep-dark: #7C7C7C;
  $arrow-dark: #5C5C5C;
  $light-black: #424242;
  $label-gray: #9e9e9e;
  $underline-gray: #cdcdcd;
  $timeline_event_green: #28A745FF;
  $icon_color: #d9230f;
  $billing_type: #ffff00;
  $site_type: #51F443;
  $representative_type: #a86f6f;
  $friend_type: #d24444;
  $board-member_type: #7db25f;
  $vip_type: #f3b40e;
  $resident_type: #8a7fde;
  $owner_type: #becc8b;
  $family-relative_type: #79abc4;
  $active-link: #2e97d9;
  $partner_type: #66b088;
  $contractor_type: #a98cd0;
  $property-manager_type: #e76a9c;
  $sibling_type: #f5afaf;
  $alternative-billing-contact_type: #f1be99;
  $hoa-president_type: #dcc292;
  $assistant_type: #b7d245;
  $tenant_type: #8ea87a;
  $spouse-partner_type: #68b7b9;
  $maintenance-facility-manager_type: #6ea8c9;
  $contractor_type: #bd9ac9;
  $contact-font: #262626;
  $declined_color: #ffb549;
  $scroll-truck: rgba(231, 231, 231, 0.5);
  $scroll-thumb: #C5C5C5;

  font-weight: 400;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: $scroll-truck;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: $scroll-thumb;
    -webkit-box-shadow: none;
  }

  .small-padding-right {
    padding-right: 5px;
  }

  .small-padding-left {
    padding-left: 5px;
  }

  .no-padding-sides {
    padding: 0;
  }

  .padding7 {
    padding: 7px;
  }

  .margin-top4 {
    margin-top: 4px;
  }

  .black {
    color: $black;
  }

  input {
    padding: 12px 5px;
  }

  .invalid {
    border-color: $red;
  }

  @media (max-width: 1024px) {
    input {
      font-size: 12px !important;
    }
    input:not(input[type="checkbox"]) {
      height: 35px !important;
    }
  }

  .header-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 20px;
    border-bottom: 1px solid $darker-gray;


    .header-customer {
      display: flex;
      align-items: center;
      font-size: 16.5px;

      .user-icon {
        color: $icon_color;
        font-size: 25px;
        padding: 0 15px;
      }

      .arrow-icon {
        font-size: 15px;
        padding: 5px;
        color: $arrow-dark;
      }

      .customer-name {
        font-size: 18px;
      }

      .select-wrapper {
        width: 300px;
      }

      @media (max-width: 550px) {
        .select-wrapper {
          width: 40vw;
        }
      }
    }

    .header-navigation {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;

      a, span {
        cursor: pointer;
        padding: 15px 10px 7px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 3px solid rgba(255, 255, 255, 0);
      }

      .header-link:hover {
        color: $active-link;
        border-bottom: 3px solid $active-link;
        text-decoration: none;
      }

      .active {
        color: $active-link;
        border-bottom: 3px solid $active-link;
        text-decoration: none;
        font-weight: 600;
      }

      .dropdown {
        display: inline-block;
        padding: 12px 0 7px;

        .dropdown-toggle:hover {
          color: $active-link;
          border-bottom: 3px solid $active-link;
          text-decoration: none;
        }

        .customer-info-menu-item {
          a {
            padding: 3px 10px;
            margin: 0;
            border-bottom: none;
            font-weight: normal;
            text-transform: none;
          }
        }

        .active {
          border-bottom: none;
          text-decoration: none;
        }

        .disabled {
          a {
            color: $label-gray;
            cursor: not-allowed;
          }

          a:hover {
            background: $gray;
          }
        }
      }
    }

    .header-edit-buttons {
      justify-self: flex-end;
      align-self: flex-end;
      margin: 5px;

      .btn-default:focus, .btn-default.focus {
        outline: none !important;
      }

      .btn {
        cursor: pointer;
        font-weight: 600;
        padding: 2px 8px;
        border-radius: 5px !important;
      }

      .disabled-button:hover {
        cursor: not-allowed !important;
      }
    }

  }

  @media (max-width: 900px) {
    .header-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .customer-info-container-wrapper {
    display: flex;
    flex-direction: column;
    margin: 6px;
    border-radius: 10px;
    padding: 0;

    .container-wrapper-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px 10px 0 0;
      padding: 6px 14px;
      margin: -1px;
      min-height: auto;
      border-bottom: 1px solid $underline-gray;

      .send-credentials-button {
        font-weight: 700;
        padding: 2px 8px;
        border: none;
        border-radius: 5px;
      }
    }

    .container-wrapper-subtitle {
      padding: 10px;
      border-bottom: 1px solid $underline-gray;

      .customer-DateRangeFilter {
        .DateRangePicker_picker {
          z-index: 2;
        }

        .DateInput_input {
          padding: 6px 11px 4px;
          color: $contact-font;
          font-weight: 500;
        }

        .DateRangePickerInput__withBorder {
          border: 1.5px solid $underline-gray !important;
          border-radius: 5px;
        }

        .DateRangePickerInput_clearDates {
          padding: 4px 10px 6px;
        }
      }

      .filter-button {
        .button {
          font-size: 12px;
          font-weight: 700;
          justify-self: flex-end;
          opacity: revert;
          border: none;
          padding: 2px 7px !important;
          border-radius: 5px !important;
        }
      }
    }

    .container-wrapper-contents {
      padding: 9px 13px;
      border-radius: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;

      .color-contact-type {
        display: inline-block;
        border-radius: 4px;
        text-align: center;
        color: $black;
        margin-left: 5px;
        overflow: initial;
        height: fit-content;
        padding: 0 5px;
      }

      .billing-type {
        background-color: $billing_type;
      }

      .site-contact-type {
        background-color: $site_type;
      }

      .representative-type {
        background-color: $representative_type;
      }

      .friend-type {
        background-color: $friend_type;
      }

      .board-member-type {
        background-color: $board-member_type;
      }

      .vip-type {
        background-color: $vip_type;
      }

      .resident-type {
        background-color: $resident_type;
      }

      .owner-type {
        background-color: $owner_type;
      }

      .family-relative-type {
        background-color: $family-relative_type;
      }

      .partner-type {
        background-color: $partner_type;
      }

      .contractor-type {
        background-color: $contractor_type;
      }

      .property-manager-type {
        background-color: $property-manager_type;
      }

      .sibling--type {
        background-color: $sibling_type;
      }

      .alternative-billing-contact-type {
        background-color: $alternative-billing-contact_type;
      }

      .hoa-president-type {
        background-color: $hoa-president_type;
      }

      .assistant--type {
        background-color: $assistant_type;
      }

      .tenant-type {
        background-color: $tenant_type;
      }

      .spouse-partner-type {
        background-color: $spouse-partner_type;
      }

      .maintenance-facility-manager--type {
        background-color: $maintenance-facility-manager_type;
      }

      .contractor--type {
        background-color: $contractor_type;
      }

      .attachment-wrapper {
        height: calc(100% - 155px);

        .dropzone-element {
          height: 100%;
          display: flex;
          flex-direction: column;

          .dropzone-label {
            color: $label-gray;
            font-size: 10px;
            margin-top: 5px;
          }

          .dropzone-area {
            border: 1px dashed $gray;
            border-radius: 5px;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            cursor: pointer;
            min-height: 30px;
            max-height: 50px;

            p {
              margin: 0;
              text-align: center;
            }
          }

          .empty-files-area {
            height: 30px;
          }

          .grid_drop_zone {
            flex-grow: 1;
            height: auto;
            min-height: 70px;
            overflow-x: hidden;
          }
        }

        @media(max-width: 991px) {
          .grid_drop_zone {
            min-height: 200px;
          }
        }
      }

      .input-element-block_editable {
        display: flex;
        flex-direction: column;
        margin-top: 7px;

        .input-element-label {
          font-size: 10px;
          line-height: 15px;
        }

        .input-element-contents {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          input {
            font-size: 10px;
            height: 20px;
          }

          @media (min-width: 768px) {
            .container {
              max-width: 1170px;
            }
          }

          span {
            font-weight: 600;
          }
        }
      }

      .select-element-block_editable {
        display: flex;
        flex-direction: column;
        margin-top: 7px;
        border-color: $red !important;

        .select-element-label {
          font-size: 10px;
        }

        .select-element-contents {
          .css-6ramuz {
            background: $white;
          }

          span {
            font-weight: bold;
          }
        }

        .select-element-contents-non-edited {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .checkbox-element-block_editable {

        .checkbox {
          input {
            width: 15px;
            height: 15px;
          }

          .checkbox-label {
            font-size: 10px;
          }
        }
      }

      .site-row {
        display: flex;
        align-items: flex-start;
        padding: 0 10px;

        .site-description {
          display: flex;
          align-items: center;
          font-size: 10px;
          color: $deep-dark
        }
      }

      .customer-contact {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;

        > * {
          padding: 5px 25px;
        }

        > :nth-child(2n) {
          background-color: rgba(0, 0, 0, 0.1);
        }

        .customer-contact-row {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          align-items: center;

          .customer-contact-name {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex-grow: 1;
            margin-right: 10px;

            b {
              white-space: break-spaces;
            }

            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }

    #input-search {
      font-weight: 700;
      border-radius: 5px;
      border-width: 1.5px;
      border-color: $underline-gray;
      color: $black;
      font-size: 14px;
    }

    .container-wrapper-footer {
      display: flex;
      justify-content: center;
      border-top: 1px dashed $gray;
      cursor: pointer;
      padding: 5px;
    }

    #customer-timeline {
      height: 100%;
      margin: auto;

      #timeline-body {
        position: relative;
        margin: 0 !important;
        padding: 0;
        overflow: auto;
        height: 100%;
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          max-height: 954px;
        }
        @media(max-width: 991px) {
          max-height: 80vh;
        }
      }

      .timeline {
        position: relative;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .timeline::after {
        content: '';
        position: absolute;
        width: 2px;
        background-color: $black;
        top: 0;
        bottom: 0;
        left: 30px;
      }

      .container {
        padding: 10px 0 10px 40px;
        width: calc(100% - 50px);
        position: relative;
        background-color: inherit;
        left: 1px;
      }

      .no-data {
        height: calc(100% - 20px);
        margin: 0;
        background: $white;
        padding: 10px;
        text-align: center;
        font-weight: 900;
        font-size: 15px;
        color: $label-gray;
      }

      .circle {
        cursor: default;
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        left: -15px;
        border: 1px solid $white;
        top: 13px;
        border-radius: 50%;
        z-index: 1;
        overflow: hidden;
        font-size: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .triangle {
        content: " ";
        height: 0;
        position: absolute;
        top: 22px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid $white;
        border-width: 10px 10px 10px 0;
      }

      .content {
        border: 1.5px solid $white;
        background-color: $white;
        position: relative;
        border-radius: 11px;
        padding: 9px 18px;
      }

      .message-hidden {
        width: 13vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        max-height: 22px;

        p {
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        div:hover {
          p {
            -webkit-text-fill-color: $light-black;
            text-decoration: underline;
          }
        }
      }

      .message-hidden:hover {
        color: $light-black;
        text-decoration: underline;
      }

      .break-spaces {
        white-space: break-spaces;
      }

      .message-show {
        word-break: break-word;
        max-height: 50vh;
        height: auto;
        transition: 500ms;
        cursor: pointer;
        overflow-y: auto;

        p {
          margin: 0
        }
      }

      .green-text-color {
        color: $timeline_event_green;
      }

      .add-new-note-wrapper {
        position: sticky;
        bottom: -1px;
        z-index: 1;
        background: $white;
        padding: 0;
        border-radius: 0 0 10px 10px;
      }

      .pad5-0 {
        padding: 5px 0;
      }

      .add-new-note {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px dashed $gray;
        cursor: pointer;
      }

      .editor {
        border-radius: 11px !important;
        border: 1px solid $deep-dark !important;
        margin-bottom: 0;
        word-break: break-all;
      }

      .no-margin-bottom {
        margin-bottom: 0;
      }

      .timeline-axis-date {
        width: fit-content;
        padding: 3px 12px;
        margin: 5px 0 0 5px;
        border-radius: 15px;
        font-weight: 900;
        z-index: 1;
        position: relative;
        font-size: 11px;
        border: 1px solid #cdcdcd;
      }

      .text-right-top {
        text-align: right;
        padding-left: 0;
      }

      @media screen and (min-width: 1200px) {
        .customer-DateRangeFilter {
          .DateRangePickerInput {
            height: 24px !important;
          }

          .DateInput_input {
            height: 20px !important;
          }
        }
      }

      @media screen and (min-width: 1400px) {
        .message-hidden {
          width: 15vw;
        }
      }

      @media screen and (max-width: 1199px) {
        .filter-button {
          padding-left: 0;
        }

        .message-hidden {
          width: 15vw;
        }
      }

      @media screen and (max-width: 991px) {
        .message-hidden {
          width: 80vw;
        }
      }

      @media screen and (max-width: 769px) {
        #timeline-body {
          width: 90vw;
          overflow-x: hidden;
        }

        .timeline::after {
          left: 31px;
        }

        .container {
          width: 100%;
          padding-left: 70px;
          padding-right: 25px;
          left: 0%;
        }

        .triangle {
          left: 60px;
          border: medium solid $white;
          border-width: 10px 10px 10px 0;
        }

        .circle {
          left: 17px;
        }

        .message-hidden {
          width: 65vw;
        }

        .vcenter {
          display: flex;
        }
      }

      @media screen and (max-width: 450px) {
        #timeline-body {
          width: 85vw;
          overflow-x: hidden;

          .message-hidden {
            width: 80%;
          }
        }
      }
    }

    .last-edit-info {
      font-size: 10px;

      .button-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .button {
          font-size: 12px;
          font-weight: 700;
          justify-self: flex-end;
          opacity: revert;
          border: none;
          padding: 2px 7px !important;
          border-radius: 5px !important;
        }
      }

      .row {
        margin: 0 !important;
        display: flex;
        align-items: flex-end;
      }

      .last-edit-info_label {
        white-space: nowrap;
        text-align: right;
      }

      .last-edit-info_description {
        display: flex;
        flex-wrap: wrap;
        color: $deep-dark;
        font-weight: 500;
        font-size: 12px;
      }

      .last-edit-info_declined-services {
        padding-top: 10px;
        align-items: center;

        :last-child {
          color: $declined_color;
          font-weight: 700;
          font-size: 12px;
        }
      }

      .last-edit-info_wrapper-row {
        display: grid;
        grid-template-columns: .5fr 1.5fr;
      }

      @media (min-width: 900px) and (max-width: 1350px) {
        .last-edit-info_wrapper-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          * {
            white-space: nowrap;
            margin: 0px !important;
            padding: 0px !important;
          }
        }
      }
    }

    .customer-sales-arborist {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;

      .customer-sales-arborist-info {
        text-align: right;

        p {
          font-size: 10px;
          margin: 0;
        }

        h4 {
          margin: 3px 0 0 5px;
        }

        .customer-sales-arborist-square {
          height: 20px;
          width: 20px;
        }
      }

      .customer-sales-arborist-photo {
        width: 40px;
        height: 40px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin-left: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .customer-info-checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin: 10px;
    }

    .checkbox-label {
      font-size: 13px !important;
      font-weight: 600;
    }

    .quickbooks-show-link {
      img {
        max-width: 20px;
        margin-left: -3px;
        margin-right: 3px;
      }

      a {
        font-weight: 600;
      }

      .disabled-link {
        color: #777777;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }

  .customer-info-column {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 110px);

    .customer_list {
      .nav:nth-child(2n) {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .site-container {
    position: relative;
    max-height: 500px;

    .site-address {
      padding: 5px !important;
    }

    .site-dropdown {
      position: absolute;
      right: 5px;
      top: 13px;

      .dropdown-menu {
        left: -140px;
      }
    }
  }

  @media (max-width: 991px) {
    .customer-info-column {
      height: auto;
    }

    .customer-info-container-wrapper {
      font-size: 14px;

      .container-wrapper-contents {
        max-height: 50vh;

        > .content {
          justify-content: space-between;
        }
      }
    }
  }
}

#customer-sales-data {
  $closed_color: #0BB243;
  $open_color: #0485B6;
  $declined_color: #ffb549;
  $statistic_line_height: 7px;
  $gray: #E0E0E0;
  $background-gray: #eeeeee;
  $border-gray: #d0d0d0;
  $dark-grey: #696969;

  .open {
    color: $open_color
  }

  .closed {
    color: $closed_color
  }

  .declined {
    color: $declined_color
  }

  .customer-sales-data-row {
    display: flex;
    align-items: center;

    .customer-sales-data-description {
      padding: 25px 15px;
      border: 1px solid $border-gray;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
    }

    @media(max-width: 500px) {
      .customer-sales-data-statistic {
        > * {
          font-size: 12px;
        }
      }
    }

    .customer-sales-data-statistic {
      width: 100%;

      * {
        margin-top: 2.2px;
        font-weight: 700;
      }

      .no-record {
        margin-top: 10px;
        font-weight: normal;
      }
    }

    .statistic-line {
      border-bottom: $statistic_line_height solid;
    }

    .declined {
      border-color: $declined_color;
    }

    .closed {
      border-color: $closed_color;
    }

    .open {
      border-color: $open_color;
    }
  }
}

@media(max-width: 500px) {
  #send-credentials-modal {
    .button-left-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 0
    }

    .button-left {
      width: fit-content;
      margin: 2px 0 0 0 !important;
    }

    .button-right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-right: 0
    }

    .button-right {
      width: fit-content;
      margin: 2px 0 0 0 !important;
    }
  }
}

#send-credentials-modal {
  .wrapper {
    display: flex;
    align-items: center;
    font-size: clamp(10px, 3vw, 14px);
  }
}

#customer-site-edit-modal, #customer-contact-edit-modal {
  @media (max-width: 1024px) {
    .control-label, .css-bg1rzq-control {
      font-size: 13px;
    }
  }
}

#duplicates-modal {
  $main-wrapper-background: #ececec;
  $single-background: #ffffff;
  $matching-value: #27ad00;
  $not-matching-value: #ff0000;

  .modal-body {
    max-height: 500px;

    .chosen-duplicates-main-wrapper {
      background: $main-wrapper-background;
      padding: 10px 20px;
      border-radius: 10px;
      margin: 5px 0;

      .chosen-duplicates-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        cursor: pointer;
        align-items: center;
      }

      .single-customer {
        margin-bottom: 20px;
        border: 1px solid;
        border-radius: 20px;
        background: $single-background;

        .single-compare {
          display: grid;
          grid-template-columns: 40% 20% 40%;

          .single-left-value {
            text-align: right;
            padding-right: 20px;
          }

          .single-right-value {
            padding-left: 20px;
          }

          .single-center-value {
            text-align: center;
          }

          .matching-value {
            color: $matching-value;
          }

          .not-matching-value {
            color: $not-matching-value;
          }
        }
      }
    }
  }
}