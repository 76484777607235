#customerView {
  .menu-column{
    height: fit-content;
    margin-bottom: 10px;
  }
}

#customer-view-menu {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #657884;
  padding: 10px 8px;
  height: calc(100vh - 200px);

  .active {
    border-left: 5px solid #7DB00E;
    a {
      background-image: none !important;
    }
    li{
      border-left: none;
    }
  }
}

.line {
  border-bottom: 1px solid lightgrey;
}

#customer-view-menu .menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-weight: bold;
  border-radius: 4px;
  border: 2px solid #7DB00E;
  color: #7DB00E;
  font-size: 13px;
  color: white;
  background: #7DB00E;
}

#customer-view-menu .menu-button:hover {
  color: #4e6e0a;
  border: 2px solid #4e6e0a;
}

#customer-view-menu .item-line {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

#customer-view-menu .item:hover {
  color: #47535c;
}

#customer-view-menu .item:hover > div:nth-child(2) > li > a {
  color: #47535c;
}


#customer-view-menu {
  .item{
    a{
      display: flex;
      width: 100%;
      color: #657884;
      font-weight: bold;
      font-size: 14px;
      text-decoration: none;
    }
  }

}
