.attachment-preview {
    position: relative;
    display: inline-block;
    cursor: pointer;
    max-width: 70px;
}

.attachment-preview img {
    max-width: 70px !important;
}

.remove-attachement-icon {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 99;
}
.download-attachement-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 99;
}
.download-attachement-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1000;
}

.issue-image-attachment {
    width: auto !important;
    margin-right: 10px;
}

.rotate-attachement-icon {
    position: absolute;
    bottom: 0px;
    z-index: 1000;
}

.rotate-attachement-icon.right {
    right: -5px;
}

.rotate-attachement-icon.left {
    left: -5px;
}

.change-header-icon {
    position: absolute;
    top: 15px;
    left: -5.5px;
    z-index: 1000;
}

.disable-drag {
    pointer-events: none;
    user-select: none;
}
