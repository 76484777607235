.avatar {
    box-shadow: 0 2px 4px #0000004d;
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    border-radius: 100%;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    -webkit-justify-content: center;
    justify-content: center;
    font-weight: 700;
    color: #fff !important;
    font-size: 1rem;
    z-index: 11;
}

.messages-container {
    display: flex;
    flex-direction: column;
}

.message-container-content {
    max-height: 60vh !important;
    overflow-y: scroll;
}

.messages-container::-webkit-scrollbar {
    width: 10px;
}

.messages-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.messages-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.message-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    color: #777777;
    width: 100%;
}

.orientation-left {
    justify-content: flex-start;
}

.orientation-right {
    flex-direction: row-reverse !important;
}

.message-item .inline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-word;
}

.message-item .message-content {
    max-width: 85%;
    min-width: 85%;
    padding: 14px 18px;
    margin: 0 8px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    box-shadow: 0 2px 4px #0000001a;
    position: relative;
}

.message-content-date {
    font-size: 11px;
    margin-bottom: 3px;
}

.message-content-edit {
    position: absolute;
    bottom: 5px;
    right: 8px;
    color: #777777;
}

.message-send-area-wrapper {
    margin-top: 10px;
}

.message-text-area {
    height: 30px !important;
}

.message-send-btn {
    padding: 4px 8px !important;
    background: none !important;
    border: none !important;
    outline: none !important;
}

.message-send-container {
    position: absolute;
    bottom: -30px;
    width: 100%;
    height: 30px;
    background: #F5F5F5;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.message-send-icon {
    color: #777777 !important;
    font-size: 16px !important;
    cursor: pointer !important;
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    font-weight: bold !important;
    color: white !important
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
    display: flex !important;
    align-items: center !important;
}

.ReactModal__Overlay {
    z-index: 99999999999999 !important;
}

.crew-message-wrapper {
    text-align: right !important;
    width: 100% !important;
    margin-right: 5px !important;
}

.crew-message-notification {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    width: 100% !important;
}


@media (max-width: 1024px) {
    .message-container-content {
        max-height: 35vh !important;
    }
}

