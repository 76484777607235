.error {
    padding-left: 5px;
    color: #9c0900;
}

@media screen and (max-width: 992px)  {
    .registration-progress-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
    }
    .registration-progress {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: flex-start !important;
    }
    .progress-wrapper{
        display: flex;
        flex-direction: row !important;
        padding: 20px 20px;
    }
}
.registration-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;

}
.progress-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.registration-active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0px 30px;
    border-radius: 50%;
    background-color: #4CAF50;
    color: white;
}
.registration-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0px 30px;
    border-radius: 50%;
    background-color: lightgrey;
    color: white;
}
.registration-progress-bar {
    margin-bottom: 15px;
    width: 40px;
    height: 3px;
    background-color: lightgrey;
}

.registration-progress-card-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.registration-progress-card {
    width: 100%;
}
.register-finish{
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register-finish > h4 {
    color: #4CAF50;
}
.register-finish > h5 {
    margin-bottom: 20px;
}
.button-container {
    display: flex;
    justify-content: flex-end;
}
.button-container > button{
    width: 80px;
    margin-left: 10px;
}
input[type=range]:hover{
    cursor: pointer;
}