html,
body {
    font-size: 12px;
    margin: 0;
    padding: 0;
    height: 100%;
}

.jodit_icon_preview {
    background-image: url("./preview.png") !important;
}

.ui {
    display: grid;
    height: 100vh;
    grid-template-rows: 30px 1fr;
}

.invoice-border {
    padding: 10px;
    border: #f89406 dashed 1px !important;
}

.leadsCheckbox {
    margin-left: 14px !important;
    padding-left: 0 !important;
}

.preserve-text-area-format {
    white-space: pre-line;
}

.logoHeader {
    max-height: 200px;
    max-width: 100%;
    margin-bottom: 40px;
    width: auto;
    height: auto;
}

.maxHeight30vh {
    max-height: 30vh;
    overflow: auto;
}

.max-height-300 {
    max-height: 300px;
    overflow-y: scroll !important;
}

.max-height-600 {
    max-height: 600px;
    overflow-y: scroll !important;
}

.height-fit-content {
    height: fit-content !important;
}

.width-fit-content {
    width: fit-content !important;
}

.panel-ps-default-panel-wrap {
    box-shadow: none !important;
    background: transparent !important;
    margin-bottom: 0 !important;
}

.nav-stacked li.active a {
    background-image: linear-gradient(#62c462, #62c462 40%, #62c462) !important;
}

.inactive_customers_legend {
    width: 12px;
    height: 12px;
    background-color: #ffebee;
    border: 1px solid #777777;
}

.no_se_arborist_legend {
    width: 12px;
    height: 12px;
    background-color: rgb(255, 235, 238);
    border: 1px solid #777777;
}

hr.grey {
    width: 100%;
    border-top: #cccccc solid 1px !important;
}

.borderDiv {
    border: #cccccc solid 1px;
    border-radius: 4px;
    padding: 7px;
    overflow: auto;
}

.navbar {
    margin-bottom: 0px;

}

.maxHeight90 {
    max-height: 90vh;
}

.word-wrap {
    word-wrap: break-word !important;
}

.maxHeight10 {
    max-height: 10vh;
    height: auto !important;
}

.input38 > div > input {
    height: 38px !important;
    font-size: 13px !important;
}

.margin-5 {
    margin: 5px;
}

.margin-20 {
    margin: 0 0 20px 0;
}

.leadsTableHeder {
    margin: 5px;
}

.marginSide {
    margin: 0 5px 0 5px;
}

.padding-right-25 {
    padding-right: 25px
}

.padding-right-8 {
    padding-right: 8px !important;
}

.padding-left-8 {
    padding-left: 8px !important;
}

.heightAuto > div > div {
    height: auto !important;
}

.margin-only-5-top {
    margin: 5px 0 0 0;
}

.our-navbar-link {
    color: #999999;
}

.our-navbar-link:hover {
    color: #333333;
    text-decoration: none !important;
    background-color: transparent !important;
}

.our-navbar-link:focus {
    text-decoration: none !important;
}

.bootstrap_debug_row_outline > div {
    margin-top: 10px;
    padding: 20px;
    outline: 2px solid #ff0000;
    outline-offset: -10px;
    -moz-outline-radius: 10px;
    -webkit-outline-radius: 10px;
}

.menu-show-fixer {
    overflow: inherit !important;
}

.wrapped_column {
    white-space: inherit !important;
}

.react-bs-container-body,
.table-hover > tbody > tr, .table-hover > tbody > tr > th, .table-hover > tbody > tr > td {
    overflow: inherit !important;
}

.clean-menu-item {
    text-decoration: none !important;
}

.danger-menu-item {
    text-decoration: none !important;
    color: #fffbff !important;
    background-color: red !important;
    border-radius: 3px !important;
    border: red;
    padding: 3px;
}

.danger-menu-item strong {
    color: white;
}

.termination-input {
    display: block;
    width: 100%;
    height: 36px;
    padding: 8px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #fff;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #dddddd;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.attention-menu-item {
    text-decoration: none !important;
    color: white !important;
    background-color: #ff8c00 !important;
    border-radius: 3px !important;
    border: #ff8c00;
    padding: 3px;
}
.disabled-input-bg {
    background-color: #f3f3f3 !important;
}
.attention-menu-item strong {
    color: white;
}

.accepted-menu-item {
    text-decoration: none !important;
    color: #545c6e !important;
    background-color: #09ff04 !important;
    border-radius: 3px !important;
    border: #09ff04;
    padding: 3px;
}

.accepted-menu-item strong {
    color: #545c6e;
}

.wo-link {
    color: red !important;
    text-decoration: none !important;
    list-style: none !important;
}

.wo-link:hover,
.wo-link:focus {
    color: #333333;
    text-decoration: underline;
}

.home-fu-link {
    color: #625e57 !important;
    text-decoration: none !important;
    list-style: none !important;
}

.home-fu-link:hover {
    color: whitesmoke !important;
}

.list_style_none {
    list-style: none !important;
}

ul.list-group:after {
    clear: both;
    display: block;
    content: "";
}

.list-group-item {
    float: left;
}

ul.nav li.dropdown:hover > ul.dropdown-menu, ul.dropdown-menu:hover {
    display: block;
    text-decoration: none !important;
}

.dropdown-toggle:hover + .dropdown-menu, .dropdown-menu:hover {
    display: block;
}

.show-for-large {
    display: block;
}

.show-for-small {
    display: none !important;
}

.floating-box {
    display: inline-block;
    margin: 10px;
    border: 1px solid;
}

@media (min-width: 979px) {
    ul.nav li.dropdown:hover > ul.dropdown-menu, ul.dropdown-menu:hover {
        display: block;
    }

    .make-fluid.pull-marg-bottom {
        margin-bottom: -16px;
    }

    .accept-proposal-header {
        margin-top: -22px;
    }
}

#home-dropdown {
    position: relative;
    display: inline-block;

}

#home-dropdown:hover {
    color: #f3fdff;
}

#home-menu {
    color: #747474;
    padding: 0;
}

#home-menu .caret {
    display: none;
}

#home-menu:hover {
    color: #1810ff;
}

#proposal-menu .caret, #customer-menu .caret {
    display: none;
}

#customer-print-proposal .caret {
    display: none;
}

#customer-menu, #proposal-menu {
    padding-bottom: 0;
    padding-top: 0;
}

#customer-print-proposal {
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;

}

#customer-print-proposal:hover {
    background: none;
    color: blue;
    font-size: 125%;
}

.no-caret-in-dropdown .caret {
    display: none;
}

.inline-assets {
    display: inline-block;
    float: left;
    margin-left: 5px;
    line-height: 25px;
}

.marginLeft5 {
    margin-left: 5px !important;
}

.make-fluid .dropdown {
    width: 100%;
}

.panel-boxes {
    min-height: 155px !important;
}

.reset-left-padding {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.padding-lr-8 {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.reset-top-padding {
    padding-top: 0 !important;
}

.reset-bottom-padding {
    padding-bottom: 0 !important;
}

.reset-right-padding {
    padding-right: 0 !important;
}

.pad-left {
    padding-left: 15px !important;
}

.big-mar-left {
    margin-left: 60px;
}

.paddingLeft {
    padding-left: 15px;
}

.d-flex {
    display: flex !important;
}

.d-grid-bottom {
    display: grid;
    align-items: end;
}

.heading-orders {
    width: 230px;
    margin: auto;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-20 {
    margin-right: 20px
}

.mr-40 {
    margin-right: 40px !important;
}

.add-margin-right {
    margin-right: 1rem;
}


.add-margin-right-scheduler {
    margin-right: 0.8rem;
}

.add-bigger-margin-right {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3px;
}

.panel-overflow-x {
    overflow-x: inherit !important;
}

.proposal-menu-padding-reset #proposal-menu {
    padding: 0;
    text-decoration: none !important;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.jumbotronAlign {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

@media screen and (max-width: 450px) {
    .jumbotronAlign .mobile-padding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


.space-between-end {
    display: flex !important;
    justify-content: space-between !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.margin-0 {
    margin: 0px !important;
}

.margin-2 {
    margin: 2px !important;
}

.react-datepicker input {
    height: 45px;
    line-height: 45px;
}

.react-datepicker__close-icon {
    display: block !important;
}

.top25 {
    margin-top: 25px !important;
}

.top5 {
    margin-top: 5px !important;
}

.top7 {
    margin-top: 7px !important;
}

.top10 {
    margin-top: 10px !important;
}

.top15 {
    margin-top: 15px !important;
}

.top-25 {
    margin-top: -25px !important;
}

.margin10 {
    margin: 10px !important;
}

.top50 {
    margin-top: 50px !important;
}

.top40 {
    margin-top: 40px !important;
}

.top80 {
    margin-top: 80px !important;
}

.fontSize17 {
    font-size: 17px;
}

.fontSize15 {
    font-size: 15px;
}

.fontSize12 {
    font-size: 12px !important;
}

.fontSize10 {
    font-size: 10px !important;
}

.fontSize8 {
    font-size: 8px !important;
}

.bottom10 {
    margin-bottom: 10px !important;
}

.bottom5 {
    margin-bottom: 5px !important;
}

.bottom3 {
    margin-bottom: 3px !important;
}

.bottom15 {
    margin-bottom: 15px;
}

.bottom0 {
    margin-bottom: 0px !important;
}

.left25 {
    margin-left: 25px !important;
}

.bottom20 {
    margin-bottom: 20px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.ml-7 {
    margin-left: 7px !important;
}

.inputTextColor {
    color: #333333 !important;
}

.bottom20 {
    margin-bottom: 20px !important;
}

.bottom25 {
    margin-bottom: 25px !important;
}

.dropdown-menu {
    margin: -2px !important;
}

.red-button {
    background-color: #F70800;
    color: #f0f0f0;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.hand {
    cursor: hand;
}

th .checkbox-inline {
    display: inline;
}

.inline-link {
    margin-bottom: 0;
    vertical-align: middle;
}

.well-filter .well {
    float: left;
    width: 175px;
    min-height: 155px;
    margin-right: 20px;
}

.notification {
    color: #f8ae00 !important;
}

.follow_up {
    color: #f80f00;
}

.glyphicon.bigger {
    font-size: 17px;
}

.svg-inline--fa.bigger {
    height: 20px;
    width: 20px;
}

.svg-inline--fa.big {
    height: 24px;
    width: 24px;
}

.glyphicon.big {
    font-size: 24px;
}

.font20 {
    font-size: 20px !important;
}

.font24 {
    font-size: 24px !important;
}

.removedBtnStyles {
    background: none !important;
    border: none !important;
    padding: 1px 3px !important;
}

.glyphicon.biggest {
    font-size: 70px;
    color: white;
}

.glyphicon.biggy {
    font-size: 55px;
    color: white;
}

.glyphicon.home-fu {
    position: relative;
    left: -7px;
    color: white;
    font-size: 11px;
}

.color-white {
    color: white;
}

.home-fu:hover {
    color: mediumseagreen;
}

.form-inline .Select {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.form-inline label {
    width: 25%;
    text-align: right;
    padding-right: 15px;
}

.form-inline .form-group {
    width: 100%;
    margin-bottom: 10px !important;
}

.form-inline .checkbox {
    padding: 10px;
}

.Select-menu-outer {
    z-index: 99999999;
}

.Select.is-open {
    z-index: 9999999999;
}

.menu-button a {
    padding-left: 0 !important;
}

.notification_btn > a {
    display: flex !important;
}

.checkbox-inline input {
    cursor: pointer;
}

.work-order .row div {
    padding-left: 5px;
    padding-right: 5px;
}

.work-order {
    padding: 5px;
    margin-bottom: 10px;
    font-size: 13px;
    background-color: #c6c6c6 !important;
    border: 1px solid black;
    color: #393939;
    max-width: 230px;
}

@media (max-width: 400px) {
    .work-order {
        max-width: 230px !important;
    }
}

@media (max-width: 360px) {
    .work-order {
        max-width: 210px !important;
    }
}

/* #MARGIN PADDINGS
==================================================*/
.mb-15 {
    margin-bottom: 15px !important;
}

/* #BLOCKQUOTES
==================================================*/
blockquote {
    font-size: 11px;
}

.blockquote-reverse, blockquote.pull-right {
    padding-right: 25px;
}

.well blockquote.custom-blockquote {
    border-color: #ffea00;
    border-left: 40px solid #ffea00;
}

.marginLeft10 {
    margin-left: 10px;
}

.ml-12 {
    margin-left: 12px !important;
}

.font11 {
    font-size: 11px !important;
}

.textCenter {
    display: flex;
    justify-content: center;
}

.textStart {
    display: flex;
    justify-content: flex-start;
}

.line_height15 {
    line-height: 1.5 !important;
}

.line_height17_5 {
    line-height: 1.75 !important;
}

.textLeft {
    display: flex;
    justify-content: flex-start;
}

blockquote.custom-blockquote, blockquote.custom-blockquote p {
    font-size: 11px;
    line-height: 18px;
    color: #777;
    /*font-style: italic;*/
}

blockquote.custom-blockquote {
    margin: 0 0 10px 0;
    padding: 8px 30px 8px 30px;
    position: relative;
    background-color: #f8f8f8;
    border-left: 40px solid #ffea00;
}

blockquote.custom-blockquote:before {
    z-index: 9;
    height: 100%;
    width: 38px;
    display: block;
    content: "\2709";
    position: absolute;
    left: -30px;
    top: 30%;
    font-size: 30px;
    font-style: normal;
    color: #323336;
}

.well blockquote.jobs-blockquote {
    border-color: #FF8C00;
    border-left: 40px solid #FF8C00;
}

blockquote.jobs-blockquote, blockquote.jobs-blockquote p {
    font-size: 11px;
    line-height: 18px;
    color: #777;
    /*font-style: italic;*/
}

blockquote.jobs-blockquote {
    margin: 0 0 10px 0;
    padding: 8px 30px 8px 30px;
    position: relative;
    background-color: #f8f8f8;
    border-left: 40px solid rgba(255, 140, 0, 0.97);
}

blockquote.jobs-blockquote:before {
    z-index: 9;
    height: 100%;
    width: 38px;
    display: block;
    content: "\2261";
    position: absolute;
    left: -32px;
    top: 30%;
    font-size: 30px;
    font-style: normal;
    color: #323336;
}

.follow-up-list {
    color: #625e57 !important;
    font-size: 13px;
    background-color: #f8d600 !important;
    border-left-style: solid !important;
    border-left-color: rgb(43, 101, 236) !important;
    border-left-width: 20px !important;
    border-radius: 8px !important;
    margin-bottom: 2px !important;
    margin-left: 0 !important;
    padding-left: 5px !important;
    position: relative !important;
    left: -40px !important;
    display: inline-block;
}

ul.follow-up {
    padding-left: 0;
}

li.follow-up {
    /*margin: 10px;*/
    /*max-height: 40px;*/
    list-style: none;
    /*padding: 0;*/
}

/*li.follow-up:first-child {*/
/*margin-top:0;*/
/*}*/

.follow-up-list:hover {
    color: floralwhite !important;
    background-color: #f8bb00 !important;
    border-left-color: rgb(3, 77, 236) !important;
}

/*.follow-up-list .panel-body {*/
/*margin-left: -20px !important;*/
/*padding-left: 5px !important;*/
/*padding-right: 5px !important;*/
/*padding-top: 5px !important;*/
/*padding-bottom: 5px !important;*/
/*display: inline-block;*/
/*}*/

.nomarginLR {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mr-5 {
    margin-right: 5px;
}

.nml-10 {
    margin-left: -10px;
}

.home-dash-panel .panel {
    min-height: 86vh !important;
    max-height: 400px !important;
    overflow-y: scroll !important;
    background-color: transparent !important;
    padding-top: 0 !important;
    /*border: 0 !important;*/
}

.home-dash-panel .panel-body {
    padding-top: 0 !important;
    /*border: 0 !important;*/
}

.home-schedule {
    background-color: #00c853 !important;
    font-size: 10px;
    border-left-style: solid !important;
    border-left-color: rgb(46, 125, 50) !important;
    border-left-width: 20px !important;
    border-radius: 8px !important;
    margin-bottom: 2px !important;
    margin-left: 20px !important;
    padding-left: 5px !important;
    position: relative !important;
    left: -40px !important;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

.home-schedule:hover {
    color: floralwhite !important;
    background-color: #15a751 !important;
    border-left-color: rgb(9, 75, 9) !important;
}

.home-schedule .panel-body {
    margin-left: -20px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    display: inline-block;
}

.paddingBottom10 {
    padding-bottom: 10px;
}

.text {
    text-decoration: none;
}

.text:hover {
    text-decoration: underline !important;
}

.col-bg {
    background-color: rgb(43, 101, 236) !important;
}

.border_none {
    border: 0 !important;
}

.row-gap-5px {
    row-gap: 5px;
}

.ch-grid {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.ch-grid:after,
.ch-item:before {
    content: '';
    display: table;
}

.ch-grid:after {
    clear: both;
}

.ch-grid li {
    max-width: 225px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 5px;
    padding: 0;
}

.ch-item {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: default;
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.6),
    0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
}

.ch-date {
    color: #5e5e5e;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    /*cursor: default;*/
    /*box-shadow:*/
    /*inset 0 0 0 4px rgba(255,255,255,0.6),*/
    /*0 1px 2px rgba(0,0,0,0.1);*/
    /*transition: all 0.2s ease-in-out;*/
}

.ch-date h3 {
    color: #545c6e;
    text-transform: uppercase;
    /*letter-spacing: 2px;*/
    font-size: 23px;
    margin: 50px 0 5px 0;
    padding: 10px 0 0 0;
    height: 20px;
    font-family: 'Open Sans', Arial, sans-serif;
    text-shadow: 0 0 1px #fff,
    0 1px 2px rgba(0, 0, 0, 0.3);
}

.ch-bg-1 {
    background-color: rgba(43, 134, 182, 0.82);
}

.ch-bg-2 {
    background-color: #f88b05;
}

.ch-bg-3 {
    background-color: #00bfa5;
}

.ch-bg-4 {
    background-color: #1e88e5;
}

.ch-bg-5 {
    background-color: #2196f3;
}

.ch-bg-6 {
    background-color: #007c33;
}

.ch-bg-7 {
    background-color: #00953e;
}

.ch-bg-8 {
    background-color: #00af48;
}

.ch-bg-9 {
    background-color: #00c853;
}

.ch-bg-10 {
    background-color: #00e25e;
}

.ch-info h2 {
    color: #fff;
    text-transform: uppercase;
    /*letter-spacing: 2px;*/
    font-size: 13px;
    margin: 0;
    padding: 0;
    height: 20px;
    font-family: 'Open Sans', Arial, sans-serif;
    /*text-shadow:*/
    /*0 0 1px #fff,*/
    /*0 1px 2px rgba(0,0,0,0.3);*/
}

.ch-info h3 {
    color: #fff;
    text-transform: uppercase;
    /*letter-spacing: 2px;*/
    font-size: 23px;
    margin: 30px 0 5px 0;
    padding: 10px 0 0 0;
    height: 40px;
    font-family: 'Open Sans', Arial, sans-serif;
    text-shadow: 0 0 1px #fff,
    0 1px 2px rgba(0, 0, 0, 0.3);
}

.ch-info h4 {
    color: #fff;
    text-transform: uppercase;
    /*letter-spacing: 2px;*/
    font-size: 11px;
    margin: 0;
    padding-top: 3px;
    font-family: 'Open Sans', Arial, sans-serif;
    text-shadow: 0 0 1px #fff,
    0 1px 2px rgba(0, 0, 0, 0.3);
}

.ch-info p {
    color: #fff;
    padding: 10px 0;
    /*font-style: italic;*/
    margin: 0 5px;
    font-size: 9px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 0;
    padding-top: 5px;
    /*transition: all 1s ease-in-out 0.2s;*/
}

.ch-info h6 {
    color: #fff;
    padding: 0;
    font-style: italic;
    margin: 0;
    padding: 3px 15px 0 15px;
    font-size: 11px;
}

.ch-info p a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing: 1px;
    padding-top: 4px;
    font-family: 'Open Sans', Arial, sans-serif;
}

.ch-info p a:active {
    color: rgba(255, 242, 34, 0.8);
}

.ch-item:active {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.1);
}

.ch-item:active .ch-info {
    transform: scale(1);
    opacity: 1;
}

.ch-item:active .ch-info p {
    opacity: 1;
}

.ch-item:active .ch-info h3 {
    height: 20px;
    padding-top: 10px;
    /*font-size: 11px;*/
}

.ch-item:active .ch-info h2 {
    height: 50px;
    padding-top: 30px;
    font-size: 11px;
}

.ch-item:active .ch-info h6 {
    opacity: 0;
    padding: 0;
    margin: 0;
    height: 5px;
}

.ch-info p a:hover {
    color: rgba(255, 242, 34, 0.8);
}

.ch-item:hover {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.1);
}

.ch-item:hover .ch-info {
    transform: scale(1);
    opacity: 1;
}

.ch-item:hover .ch-info p {
    opacity: 1;
}

.ch-item:hover .ch-info h2 {
    /*height: 40px;*/
    padding-top: 30px;
    font-size: 18px;
}

.ch-item:hover .ch-info h3 {
    height: 10px;
    padding-top: 10px;
    font-size: 31px;
}

.ch-item:hover .ch-info h4 {
    /*height: 40px;*/
    /*padding-top: 30px;*/
    font-size: 12px;
}

.ch-item:hover .ch-info h6 {
    opacity: 0;
    padding: 0;
    margin: 0;
    height: 5px;
}

.my-container {
    position: relative;
    background: #5C97FF;
    overflow: hidden;
}

.my-container img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0.6;
}

.fc-event-container {
    font-size: 15px !important;
}

.vcenter {
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .maxHeight30vh {
        max-height: 30vh;
        overflow: auto;
    }

    .fontIOS {
        font-size: 16px;
    }

    .vcenter {
        display: block;
    }

    .master-form-header .vcenter, .full-height .vcenter {
        display: flex;
        align-items: center;
    }

    .employee-select {
        height: auto !important;
    }

    .padding25LR {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    #scheduler > .fc-toolbar > div {
        margin: auto;
    }

    #scheduler > .fc-toolbar > div > button,
    #scheduler > .fc-toolbar > div > h2 {
        float: none;
    }
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.hcenter {
    display: flex;
    justify-content: center;
}

.hstart {
    display: flex;
    justify-content: flex-start;
}

.hright {
    display: flex;
    justify-content: flex-end;
}

.underline b:hover,
.underline h4:hover,
.underline div:hover {
    text-decoration: underline;
}

.padding-top-bottom-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.padding-top-bottom-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.small-left-padding {
    padding-left: 2px !important;
}

.mr-0-ml-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.vhCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-right-padding {
    padding-right: 2px !important;
}

.small-padding {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.stripe_disconnect_btn {
    padding-left: 6px !important;
    background: #e22620 !important;
}

.stripe_connect_btn {
    padding-left: 6px !important;
    background: #0a6ebd !important;
}

.mr-9 {
    margin-right: 9px !important;
}

.mr-11 {
    margin-right: 11px !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.ml-9 {
    margin-left: 9px !important;
}

.nmt-10 {
    margin-top: -10px !important;
}

.small-margin {
    margin-left: 9px !important;
    margin-right: 9px !important;
}

.no-left-padding {
    padding-left: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

.no-left-margin {
    margin-left: 0 !important;
}

.ml6 {
    margin-left: 6px !important;
}

.mr25 {
    margin-right: 25px;
}

.pr7 {
    padding-right: 7px !important;
}

.employee-select {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 350px;
}

.filter-types{
    display: flex;
    flex-direction: column;
}

.work-orders-select {
    overflow-x: visible;
    height: calc(100vh - 147px);
    padding: 10px;
}

.small .form-group {
    margin-bottom: 5px;
}

.small .Select-control, .small .Select-input {
    height: 25px;
}


.small .Select-control {
    overflow: inherit;
}

.scrollDiv {
    overflow: auto;
    height: calc(40vh - 240px);
}

.small .Select-control .Select-placeholder,
.small .Select-control .Select-value {
    line-height: 25px !important;
}

.small .Select--multi .Select-control .Select-value {
    line-height: 12px !important;
}

.fc-content {
    white-space: normal !important;
    font-size: 11px;
    line-height: 18px;
}

.inline {
    display: inline;
}

.marginBottom10 {
    margin: 0 0 10px 5px;
}

.mb-2-mt-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.marginBottom10 {
    margin: 0 0 18px 0px;
}

.github-picker {
    z-index: 999;
}

.pr-10 {
    padding-right: 10px !important;
}

.padding10LR {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.datetime-picker--section {
    display: flex;
    margin-top: -15px;
}


.datetime-picker--section .rdtPicker {
    position: inherit;
    border: 0;
    box-shadow: none;
    z-index: 9 !important;
    width: 100%;
}

.datetime-picker--section .rdt {
    width: 100%;
}

.datetime-picker--section .rdt input {
    display: none;
}

.datetime-picker--section .rdtTimeToggle {
    display: none;
}

.scheduler-filter .form-group {
    margin-bottom: 5px;
}

.abc-radio {
    padding-left: 10px;
}

.fc-state-highlight {
    background: #d7f1ff;
}

.padding25 {
    padding: 25px;
}

.ml-15 {
    margin-left: 15px !important;
}

.padding25LR {
    padding-left: 25px;
    padding-right: 25px;
}

.pl-20 {
    padding-left: 20px !important;
}

.asset-thumbnail {
    width: 25px;
    margin-right: 5px;
}

.panel.no-border {
    margin-bottom: 5px;
    background-color: inherit;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.panel.sales-graph {
    height: auto;
    width: auto;
}

.panel.sales-graph .footer {
    background: inherit;
}

.panel.sales-widget {
    background: inherit;
    cursor: pointer;
    height: 125px;
    width: 125px;
}

.panel.sales-widget:hover {
    background: #fafafa;
    cursor: pointer;
    border-width: 2px;
}

.sales-widget small {
    color: #52af52;
    cursor: pointer;
    font-size: 11px;
}

.sales-widget:hover small {
    color: #2f5f2f;
    cursor: pointer;
    font-size: 13px;
}

.sales-widget h6 {
    color: #397f0c;
    cursor: pointer;
    font-size: 11px;
}

.sales-widget:hover h6 {
    color: #2b560c;
    cursor: pointer;
    font-size: 13px;
}


.sales-widget h5 {
    color: #52af52;
    cursor: pointer;
    margin-top: 20px;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.sales-widget:hover h5 {
    color: #2f5f2f;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

.full-width {
    width: 100% !important;
}

.leadsToggleButtonGroup {
    position: absolute;
    right: 15px;
}

.full-height {
    height: 100%;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4{
    margin-top: 4px !important;
}

.boxContainer {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.asset-modal {
    z-index: 1;
    left: 0 !important;
}

.modal-content {
    display: flex;
    flex-flow: column;
    margin-top: 5vh;
}

@media (max-width: 768px) {
    .modal-content {
        margin-top: 3vh;
        margin-bottom: 3vh;
    }
}

@media (min-width: 992px) {
    .modal-content {
        height: 90vh;
    }
}

.modal-body {
    position: relative;
    padding: 20px;
    overflow: auto;
    flex: 1;
}

.wrapped .react-bs-table table td {
    white-space: normal;
}

.padding-bottom-150 {
    padding-bottom: 150px;
}

.preview_modal {
    margin: 20px 5px !important;
}

.preview_modal_scroll {
    overflow: scroll !important;
}

.navbar-default .navbar-nav > li > a, .navbar-inverse .navbar-nav > li > a {
    padding-left: 20px !important;
    padding-right: 20px;
}

.loader a {
    width: 42px;
    height: 42px;
    padding-top: 10px !important;
    border: 0 !important;
}

.checkbox-inline {
    min-height: 20px;
}

.proposal-button {
    min-width: 165px;
}

.small-select__control {
    min-height: 30px !important;
}

.work-orders-filter .form-group {
    margin-bottom: 5px;
}

.small-select__value-container {
    padding: 0 !important;
    padding-left: 5px !important;
}

.small-select__dropdown-indicator {
    padding: 0 !important;
}

.customer-name {
    font-size: 1.5em;
}

.select__menu {
    z-index: 99999999 !important;
}

.no-top-margin {
    margin-top: 0 !important;
    margin-bottom: 15px !important;
}

.mt0 {
    margin-top: 0 !important;
}

.nmt-5 {
    margin-top: -5px !important;
}

.nmr-15 {
    margin-right: -12px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.no-right-margin {
    margin-right: 0 !important;
}

.remaining {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.height72 {
    /*TODO: rename*/
    height: 72px;
}

.small-button {
    min-width: 150px;
}

.width-15 {
    width: 15% !important;
}

.half {
    width: 40%;
    display: inline-block;
}

.datePickerWidth {
    width: 42%;
    display: inline-block;
}

.small-button {
    min-width: 150px;
}

.fc-title small {
    font-size: 9px;
}

.highlight {
    background: #fdff6c !important;
}

.react-bs-table td {
    outline: none !important;
}

.imper_token {
    float: left;
}

.imper_token > button {
    margin-left: 10px;
}

a.disabled {
    color: gray !important;
}

#scheduler-date-picker {
    position: absolute;
    margin-left: 5vw;
    margin-top: 6vh;
    width: 250px;
}

.dropdown {
    list-style: none;
}

.mb-10-mt-0 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.vertical-responsive-height {
    overflow-x: hidden;
    height: calc(100vh - 230px);
}

.full-width {
    width: 100%;
}

.line-height15 {
    line-height: 1.5 !important;
    font-weight: normal !important;
    padding: 0 5px 0 5px !important;
    text-transform: none !important;
    margin: 10px;
}

.info-window {
    line-height: 1.35;
    overflow: hidden;
    white-space: nowrap;
    min-width: 200px;
}

.removal_btn_width {
    width: 200px !important;
}

svg.biggest {
    width: 36px !important;
    height: 36px !important;
}
svg.warning {
    width: 20px !important;
    height: 20px !important;
    filter: invert(55%) sepia(96%) saturate(745%) hue-rotate(7deg) brightness(106%) contrast(104%);
}

.arrow-down::after {
    content: '\25bc';
    padding-left: 0.5em;
}

.arrow-up::after {
    content: '\25b2';
    padding-left: 0.5em;
}

.text-ellipsis {

    text-overflow: ellipsis;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.flex-column-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.flex-column-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-column-center {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.justify-flex-end {
    display: flex;
    justify-content: flex-end;
}

.justify-flex-start {
    display: flex;
    justify-content: flex-start;
}

.bold {
    font-weight: 600 !important;
}

.columnDirection {
    display: flex;
    flex-direction: column;
}

.direction-row {
    flex-direction: row !important;
}

.small {
    font-size: 9px;
}

.table-auto {
    table-layout: auto;
}

.text-blue {
    color: #56C1FE;
    font-weight: 600;
}

.font-weight-normal {
    font-weight: normal !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.text-green {
    color: #0DCC0D;
    font-weight: 600;
}

.text-info {
    color: #4da309;
    font-weight: 600;
}

.align-text-end {
    display: flex;
    align-items: flex-end;
}

.text-orange {
    color: #FF9900;
    font-weight: 600;
    font-size: 14px;
}

.max_width_70 {
    max-width: 70px !important;
}

.width-80 {
    width: 80% !important;
}

.text-purple {
    color: #9b479f;
}

.text-white {
    color: #ffffff !important;
}


.text-gray {
    color: #999999 !important;
    font-weight: normal;
}

div.gm-style > div:first-child > div:first-child > div > div > div > div > div {
    margin-top: -16px !important;
}

.marginTop-16 {
    margin-top: -16px !important;
}

div.react-bs-table > div.react-bs-container-body > table > tbody > tr > td > div > div > div > table {
    table-layout: initial;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.modal-smaller .modal-content {
    height: 400px !important;
    margin-top: unset;
}

.modal-smaller .modal-body {
    padding: 15px !important;
}

.modal-smaller .Select > .select__menu > div {
    overflow-y: unset;
    max-height: unset;
}

.select-small {
    display: inline-block;
    width: 25%;
}

.padding10 {
    padding: 10px !important;
}

.paddingMapMobile {
    padding: 10px 10px 0 10px !important;
}

.rdtPicker {
    right: 0;
    width: 100%;
}

.width-auto {
    width: auto !important;
}

.width55 {
    width: 55%;
}

.width50 {
    width: 50%;
}

.width20 {
    width: 20%;
}

.min-fit-content {
    min-width: fit-content !important;
}

.nav > li.disabled:hover > a + ul {
    display: none !important;
}

.top-menu a.disabled:hover {
    cursor: not-allowed !important;
}

.inline-block {
    display: inline-block;
}

.top27 {
    top: 27px;
}

.top-50 {
    top: 50%
}

.self-start {
    align-self: flex-start;
}

.self-center {
    align-self: center;
}

.checkbox-smaller {
    font-size: 12px;
}

.checkbox-smallest {
    font-size: 10px;
}

.font16 {
    font-size: 16px !important;
}
font18{
    font-size: 18px !important;
}

.font14 {
    font-size: 14px !important;
}

.font12 {
    font-size: 12px !important;
}

.font10 {
    font-size: 10px !important;
}

.proposalBadge {
    background: #8a9196 !important;
    cursor: pointer !important;
}

.font18 {
    font-size: 18px !important;
}

.menu-item-padding {
    padding: 3px 20px;
}

.paddingSide {
    padding: 10px 20px 0 20px;
}

.float-right {
    float: right;
}

.float-unset {
    float: unset;
}

.modalLeadsFilter {
    margin: 0 !important;
}

.modalLeadsFilterBody > div > div > div.modal-body {
    overflow: visible;
}

.dataTimeLeads {
    width: calc(50% - 16.5px);
    display: inline-block;
}

.marginBottom8 {
    margin-bottom: 8px !important;
}

.right-5 {
    right: 5px !important;
}

.timeScheduler {
    /*position: fixed;*/
    margin: 0 0 0 5px;
    /*right: calc(50% + 15px);*/
}

.colorRed {
    color: #d9230f;
}

.border-top-color-red {
    border-top-color: #d9230f;
}

.line-height-35 {
    line-height: 35px;
}

.preview_modal table {
    border-collapse: separate;
    margin-bottom: unset;
    margin-top: unset;
    line-height: unset;
    border-spacing: unset;
}

.preview_modal table tr td {
    border: unset;
    padding: unset;
}

.preview_modal p {
    margin: unset;
}

.jodit-container-col .jodit-wysiwyg table {
    border-collapse: separate;
    margin-bottom: unset;
    margin-top: unset;
    line-height: unset;
    border-spacing: unset;
}

.jodit-container-col .jodit-wysiwyg table tr td {
    border: unset;
    padding: unset;
}

.jodit-container-col .jodit-wysiwyg p {
    margin: unset;
}

.connect-button {
    display: inline-block;
    margin-bottom: 1px;
    background-image: linear-gradient(#28a0e5, #015e94);
    -webkit-font-smoothing: antialiased;
    border: 0;
    padding: 1px;
    height: 32px;
    text-decoration: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.connect-button.disconnect {
    background-image: linear-gradient(#e50000, #b20000);
}

.connect-button.quickbooks {
    background-image: linear-gradient(#25a012, #16600a);
}

.connect-button.sync {
    background-image: linear-gradient(#eef00e, #a6a809);
}

.connect-button span {
    display: block;
    position: relative;
    padding: 0 12px;
    height: 30px;
    background: #1275ff;
    background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    font-weight: 700;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
    -webkit-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .25);
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .25);
    border-radius: 3px;
}

.connect-button.disconnect span {
    background: #e50000;
    background-image: linear-gradient(#ff0000, #b20000 85%, #e50000);
}

.connect-button.quickbooks span {
    background: #25a012;
    background-image: linear-gradient(#25a012, #16600a, #1d800e);
}

.connect-button.sync span {
    background: #eef00e;
    background-image: linear-gradient(#eef00e, #a6a809, #D6D80C);
}

.connect-button:hover, .connect-button:active, .connect-button:visited, .connect-button:focus {
    text-decoration: none;
}

.pr-5 {
    padding-right: 5px !important;
}

@media (max-width: 476px) {
    .work-order {
        max-width: 260px !important;
    }

    .holiday_event_text {
        font-size: 9px !important;
        word-break: break-word !important;
    }

    .holiday {
        padding: 2px !important;
    }
}

.pl-5 {
    padding-left: 5px !important;
}

.full-opacity {
    opacity: 1 !important;
}

.padding4 {
    padding: 4px !important;
}

.wrap {
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
}

#scheduler .fc-time b {
    font-size: .85em;
    font-weight: normal !important;
}

.small-btn-padding {
    padding: 2px 4px !important;
}

.text-small {
    font-weight: normal;
    font-size: 12px;
}

.sales-checkbox.checkbox-smaller input[type="checkbox"]:checked + label::after {
    top: -5px !important;
}

.mt-50 {
    margin-top: 50%;
}

.mt-10 {
    margin-top: 10%;
}

.mt10 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 5px !important;
}

.right-20 {
    right: 20px;
}

.mr--40 {
    margin-left: -40px;
}

.mobile-nav-width > .dropdown-menu {
    width: 100% !important;
}

.width-20 {
    width: 20px;
}

.width-30 {
    width: 30px;
}

.modal-body {
    padding: 0 15px !important;
}

.achTransactionBtn {
    padding: 6px 4px 6px 4px !important;
    margin-left: 10px !important;
}

@media (max-width: 768px) {
    .modal-body {
        margin-top: -10px;
    }
}

.mt13 {
    margin-top: 13px;
}

.mt5 {
    margin-top: 5px;
}

.mt7 {
    margin-top: 7px !important;
}

.mt27 {
    margin-top: 27px;
}

.mt33 {
    margin-top: 33px;
}

.mt-15 {
    margin-top: 15px !important;
}

@media (max-width: 992px) {
    .pl5 {
        padding-left: 5px !important;
    }
}

@media (min-width: 992px) {
    .modal-open .modal {
        display: flex !important;
        align-items: center !important;
    }

    .max_width_36 {
        max-width: 36% !important;
    }
}

@media (max-width: 768px) {
    .pl5 {
        padding-left: 5px !important;
    }
}

.minus10margin {
    margin-left: -10px !important;
}

.width120 {
    width: 120px;
}

.mb2 {
    margin-bottom: 2px;
}

.ml0 {
    margin-left: 0 !important;
}

.width-bigger {
    margin-left: -10px !important;
    background-color: white;
    width: calc(100% + 20px);
}

.minus10margin {
    margin-left: -10px !important;
}

.pb10 {
    padding-bottom: 10px;
    padding-left: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.minus-bottom10 {
    margin-bottom: -10px !important;
}

.nmargin-lr10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
}

.minus-bottom15 {
    margin-bottom: -15px !important;
}

.minus-top15 {
    margin-top: -15px !important;
}

.minus-right10 {
    margin-right: -10px !important;
}

.no-wo-height {
    height: calc(42vh - 200px);
}

.word-break {
    word-break: break-all
}

.mt-15 {
    margin-top: 15px !important;
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: auto !important;
    }

    .modal-content {
        height: 95vh !important;
        margin-top: 0;
    }
}

.mrb-10 {
    margin: 0 10px 10px 0 !important;
}

.plb-10 {
    padding: 0 0 10px 10px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.word-break {
    word-break: break-all
}

.mt-20 {
    margin-top: 20px;
}

.space-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sb {
    justify-content: space-between;
}

.min-height-40 {
    min-height: 40px;
}

.min-height-0 {
    min-height: 0 !important;
}

.borderDiv > div {
    height: 100%;
}

.crewMessages.borderDiv > div {
    height: unset;
}

.arrow-disabled {
    color: #b20000;
    cursor: not-allowed !important;
}

.absolute-left-align {
    position: absolute;
    left: 10px;
}

.absolute-left {
    position: absolute;
    left: 43%;
    z-index: 999;
}

.background-green {
    background-color: rgb(240, 255, 240);
}

.align-right {
    text-align: right !important;
}

.align-center {
    text-align: center !important;
}

.align-left {
    text-align: left !important;
}

.no-border {
    border: none !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.white-background {
    background-color: #fff !important;
}

.font14 {
    font-size: 14px !important;
}

.hidden-notification {
    opacity: 0 !important;
}

.message-notification {
    position: absolute !important;
    top: 5px !important;
    right: 6px !important;
    border-radius: 24px !important;
    text-align: center !important;
    width: 19px !important;
    height: 18px !important;
    line-height: 16px !important;
    font-size: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.issue-notification {
    border-radius: 24px !important;
    text-align: center !important;
    width: 19px !important;
    height: 18px !important;
    line-height: 16px !important;
    font-size: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.issue-notification:hover {
    border: 1px solid white !important;
}

.no-border-radius {
    border-radius: 0 !important;
}

.issue_badge {
    right: 7px !important;
}

.message-notification-view {
    position: absolute !important;
    top: 3px !important;
    right: 2px !important;
    border-radius: 50% !important;
    padding: 1px 4px !important;
    background: red !important;
}

@media (max-width: 1024px) {
    .message-notification {
        position: absolute !important;
        top: -11px !important;
        right: 6px !important;
        border-radius: 50% !important;
    }

    .menu-button > a > span {
        position: relative !important;
    }

    .issue_badge {
        right: 8px !important;
        top: -9px !important;
    }
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.white-background {
    background-color: #fff !important;
}

.font14 {
    font-size: 14px !important;
}

.alert-font-color {
    color: #ff8c00 !important;
}

.rate-modal-body-padding {
    padding: 20px 0 20px 0 !important;
}

.version {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -18px;
    font-size: 10px;
    margin-right: 3px;
}

.btn-info {
    background: #474949 !important;
    border: #474949 !important;
}

.padding20 {
    padding: 20px !important;
}

.active-preset {
    color: #ffffff !important;
    background: #00d659 !important;
}

.email-checkbox .form-check-input {
    display: none;
}

.btn-info {
    background: #474949 !important;
    border: #474949 !important;
}


.d-block {
    display: block;
}

.m-auto {
    margin: auto;
}

.padding-right-23 {
    padding-right: 23px !important;
}

.padding-left-23 {
    padding-left: 23px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}


.table-pagination {
    width: 100%;
    text-align: left;
    border: none;
}
.rdtOpen.drop-to-the-right .rdtPicker {
    right: inherit !important;
}

.flex-wrap {
    display: flex !important;
    flex-wrap: wrap !important;
}

.padding-lr-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.search-events-clear-btn {
    padding: 8px 2px !important;
    background: #2C3E50 !important;
    outline: none !important;
    border: none !important;
}

.customer-link-color {
    color: #FCFCFC !important;
}

.email-checkbox .form-check-input {
    display: none;
}

.table-pagination {
    width: 100%;
    text-align: left;
    border: none;
}

.recipient-btn {
    padding: 0px 3px !important;
    border-radius: 0 !important;
    outline: none !important;
}

.pb-26 {
    padding-bottom: 26px !important;
}

.top32 {
    margin-top: 32px !important;
}

.not-allowed {
    cursor: not-allowed !important;
}

.padding5 {
    padding: 5px !important;
}

.mt-40 {
    margin-top: 40px;
}

.mt-25 {
    margin-top: 25px;
}

.pl-12 {
    padding-left: 12px
}

.pre-line {
    white-space: pre-line !important;
}

.issue-warning {
    position: absolute;
    bottom: 10px;
    left: 30px;
}

.issue-table-column {
    word-break: break-word !important;
}

.issue-active-btn {
    background: #d9230f !important;
    border: 1px solid #d9230f !important;
    outline: none !important;
}

.issue-record-btn {
    display: flex !important;
    align-items: center !important;
    padding: 4px 6px !important;
    border-radius: 0 !important;
    background: #d9230f !important;
    outline: none !important;
}

.issue-record-media {
    width: 100% !important;
    height: 150px !important;
    cursor: pointer !important
}

.issue-record-media-tools {
    position: absolute;
    height: 30px;
    background: rgba(0,0,0,0.3);
    width: 100%;
    top: 0;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #FFFFFF;
    cursor: pointer;
}

.media-tools-btn {
    background: none !important;
    outline: none !important;
    border: none !important;
    padding: 2px 4px !important;
}

.media-tools-btn:hover {
    opacity: 0.6;
}

.break-word-issue {
    word-break: break-word !important;
}

.flex-gap-10{
    display: flex;
    gap: 10px;
}

.height30{
    height: 30px !important;
}

.flex-align-center {
    align-items: center;
}

@media (max-width: 450px) {
    html,
    body {
        font-size: 12px;
        margin: 0;
        padding: 0;
        height: 100%;
        position: relative;
    }

    button:not([disabled]) {
        opacity: 1;
        transition: opacity 0.3s;
    }

    button:not([disabled]):active {
        opacity: 0.7;
    }

    .mobile-nav-fixed > div {
        position: fixed;
        background: white;
        width: 100%;
        top: 0;
        max-width: 100vw;
    }

    .bump-up-version-banner {
        margin-top: 50px;
    }

    .mobile-nav-menu {
        height: 100dvh;
        overflow-y: scroll;
    }
}

.input-invalid{
    border-color: #FF0000 !important;
}

.error-message {
    color: #FF0000;
    font-size: 10px;
}

.overlay{
    width: 100%;
    height: 100%;
    background: black;
    left: 0;
    top: 0;
    opacity: 0.05;
    position: absolute;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-message-master-detail {
    position: relative;
    color: red;
    font-size: 10px;
    bottom: -4px;
}

/*<InfoWindow> close button global style*/
.gm-style-iw-chr {
    justify-content: flex-end;
}

.gm-style-iw-chr .gm-style-iw-ch {
    display: none;
}

.gm-style-iw-chr .gm-ui-hover-effect {
    width: 13px !important;
    height: 13px !important;
    margin: 3px 3px 0 0 !important;
}

.gm-style-iw-chr .gm-ui-hover-effect span {
    width: 13px !important;
    height: 13px !important;
    margin: 0 !important;
}

.app-loading-text {
    font-size: 24px;
    font-family: Roboto;
}

.loading-screen-splash {
    height: 97vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.padding-top-25 {
    padding-top: 25px;
}

.center-absolute-h-w {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media print {
    .bump-up-version-banner {
        display: none;
    }
}