.truck-modal.modal-dialog{
  transform: none !important;
  -webkit-transform: none !important;
}
.truck-modal {
  .modal-content {
    position: relative !important;
    height: 500px !important;
    width: 450px;
    margin: auto;

    .modal-body {
      padding: 20px 20px 0 20px !important;
      display: grid;
      grid-template-rows: auto 60% auto;
      height: 80%;
      position: relative;
      align-content: space-around;

      .header-wrapper {
        display: grid;
        grid-row-gap: 10px;

        .option-header {
          input {
            text-transform: capitalize;
            font-weight: 700;
            background: #F2F2F2;
          }
        }

        .option-tools {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .colored-options {
            display: flex;
            align-items: center;

            button {
              background: none;
              border: none;
              cursor: pointer;
            }
          }

          .alphabetize {
            display: flex;
            align-items: center;
            background: #F2F2F2;
            padding: 4px;
            border-radius: 4px;
            cursor: pointer;

            p {
              margin-left: 5px;
            }
          }
        }
      }

      .body-wrapper {
        display: grid;
        grid-template-rows: 1fr auto;
        border-bottom: #F2F2F2 solid 2px;
        border-top: #F2F2F2 solid 2px;
        padding: 5px 0;
        position: relative;

        .hue-picker-container {
          position: absolute;
          width: 100%;
          height: 33px;
          display: flex;
          bottom: 0;
          z-index: 2;

          .hue-picker {
            position: relative !important;
            width: 90% !important;
            height: 100% !important;

            div {
              div {
                div {
                  div {
                    height: 33px !important;
                    width: 33px !important;
                    transform: translate(-18px) !important;
                  }
                }
              }
            }
          }

          .color-picker-btn {
            width: 10%;
            position: relative;
            height: 100%;
            display: grid;
            place-items: center;
            background: white;

            svg {
              font-size: 16px;
              color: black;

              &:hover {
                cursor: pointer;
              }
            }
          }

        }


        ul {
          padding-inline-start: 0px !important;
          margin-block-end: 0px !important;
        }

        .add-new {
          padding: 5px 0;
          background: #F2F2F2;
          border-radius: 4px;
          cursor: pointer;

          p {
            margin-left: 7px !important;
            font-weight: 400;
          }
        }

        .description {
          font-style: italic;
        }

        .option-list {
          list-style-type: none;
          margin: 0;
          overflow-y: auto;
          padding-right: 17px;


          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;


            span {
              display: flex;
              align-items: center;
              grid-column-gap: 5px;
            }

            .option-arrow {
              color: black;
              border-radius: 50%;
              height: 18px;
              width: 18px;
              cursor: pointer;
            }

            .remove-icon {
              cursor: pointer;
            }
          }

        }
      }

    }

    .modal-footer {
      position: relative;
      height: 20%;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  p {
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
  }
}
