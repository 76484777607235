.app-settings-container {
    padding: 20px 10px;
}

.app-settings-header {
    display: flex;
    align-items: center;
}


.app-settings-wrapper {
    padding: 20px 15px;
    display: flex;
    align-items: center;
}
