.unsubscribe-button {
    background: #98E6DF !important;
    border: 1px solid #98E6DF !important;
    font-weight: bold !important;
}

.subscribe-button {
    background: #797EF6 !important;
    border: 1px solid #797EF6 !important;
    font-weight: bold !important;
}

.subscribe-product {
    max-width: 50%;
    min-width: 50%;
    height: fit-content;
    padding: 14px 12px 30px 12px;
    margin: 0 8px;
    background-color: #fff;
    border-radius: 10px;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    box-shadow: 0 2px 4px #0000001a;
    position: relative;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.subscription-disabled {
    background: lightgrey !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
}

.subscription-status {
    position: absolute;
    bottom: 5px;
}

.subscribe-product:hover {
    opacity: 0.7;
}

.license-row {
    min-height: 60px;
}

.license-row > div {
    height: 60px;
}

.license-row .col-md-2 {
    text-align: right;
}

.monthly-fee {
    font-size: 2rem;
}
.subscription-checkbox {
    cursor: pointer;
}

@media (max-width: 992px) {

    .license-row .col-md-2 {
        text-align: left;
    }

}