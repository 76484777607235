#newRegistrations{
    .react-bs-table {
        max-height: calc(100vh - 220px) !important;
        height: auto !important;
        overflow: auto !important;
    }
    .react-bs-table-pagination > div > div:nth-child(2) > ul {
        margin: 0 !important;
    }

    .vertical-responsive-height {
        height: calc(100vh - 180px);
        overflow-y: hidden;
    }

    .SplitPane {
        max-height: calc(100vh - 135px) !important;
        overflow-y: auto;
    }

    .Resizer {
        z-index: 11 !important;
    }

    .react-bs-container-header.table-header-wrapper {
        z-index: 10 !important;
    }

    @media (min-width: 1025px) {
        .react-bs-container-header.table-header-wrapper {
            position: absolute;
            background-color: white;
        }

        .react-bs-table-pagination {
            position: absolute;
            padding-right: 20px;
            bottom: 0;
            width: 100%;
        }
    }

    @media (max-width: 1024px) {
        .pagination {
            display: flex !important;
        }

        .react-bs-table-pagination {
            margin-top: 10px !important;
        }

        .vertical-responsive-height {
            height: calc(100vh - 160px);
        }
    }

    .Pane {
        padding: 0 !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .panel {
        margin-bottom: 10px !important;
    }

    .panel > div > div {
        padding-bottom: 0 !important;
    }

    @media (min-width: 1025px) {
        .react-bs-container-body {
            margin-top: 36px;
        }
    }

    .tableHeader {
        display: flex;
        justify-content: space-between;
    }

    td {
        word-break: break-word;
    }

    @media (max-width: 468px) {
        .pagination > li > a {
            padding: 8px 9px !important;
        }

        .btn-default {
            padding: 8px !important;
        }
    }
}

