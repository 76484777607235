#customer-work-orders {

  .react-bs-container-header.table-header-wrapper {
    position: absolute;
    max-width: calc(100% - 36px);
    background-color: white;
    z-index: 1;
  }

  .react-bs-container-body {
    margin-top: 40px;
  }

  .react-bs-table {
    min-height: calc(77.5vh - 59px) !important;
    max-height: calc(77.5vh - 59px) !important;
    height: auto !important;
    overflow: auto !important;
  }

  .pagination {
    margin: -2px 0;
  }

  .css-kj6f9i-menu {
    z-index: 10;
  }

  .dropdown-menu {
    z-index: 999999999;
  }

  @media (max-width: 768px) {
    max-width: 100vw;
    .react-bs-table-container {
      max-width: 100vw;
      overflow: auto;
    }

    .react-bs-table-pagination {
      position: fixed;
      padding-right: 40px;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }

    .react-bs-table-container {
      padding-bottom: 100px;
    }
  }
}