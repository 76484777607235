#past-due-invoices-modal{
    @media (min-width: 769px) {
        .modal-content {
            max-height: 600px !important;
            margin-top: 0;
        }
    }
    @media (max-width: 768px) {
        .modal-content {
            max-height: 90vh !important;
        }
    }

    .modal-body {
        margin-top: 0 !important;
    }

    .react-bs-table-container{
        margin-bottom: 0 !important;
    }


}