.new-attachment-preview {
  width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    max-width: 200px !important;
    height: 100px;
    object-fit: cover;
    border: none;
  }
  a{
    width: fit-content;
  }
  .image-with-description {
    img {
      width: 100%;
      user-select: none;
    }

    .image-with-icon {
      position: relative;

      .watermark-icon-wrapper {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px;
        background: #029acf;
        border-radius: 4px;
      }

      .watermark-icon-wrapper {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px;
        background: #029acf;
        border-radius: 4px;
        transition: opacity 0.3s ease; /* Smooth transition on opacity */
      }

      .watermark-icon-wrapper:active {
        background: #007aa2;; /* Lower opacity when clicked */
      }

      .watermark-icon-wrapper:hover {
        opacity: 0.8; /* Lower opacity when clicked */
      }
    }

    .image-date {
      margin: 5px 0 0 0;
      text-align: center;
    }

    .image-tag {
      margin: 3px 0 0 0;
      display: flex;
      justify-content: center;
      width: fit-content;
      height: fit-content;
      .image-visibility {
        width: 60px;
        padding: 3px 8px;
        font-size: 10px;
        font-weight: bold;
        color: white;
        text-align: center;
        border-radius: 10px;
      }

      .image-visible {
        background: #4da309;
      }

      .image-hidden {
        background-color: #8b211e;
      }
    }
  }

  .visibility-buttons{
    display: flex;
    justify-content: space-between;
    .btn {
      margin: 0 1px 7px 1px;
      padding: 6px 10px;
      background: #029acf;
      border-color: #029acf;
    }
    .btn-delete {
      background: #029acf;
    }
  }
  .button-group {
    display: flex;
    flex-direction: column;

    .edit-buttons {
      display: flex;
      justify-content: center;

      .btn {
        width: 25%;
      }
    }

    .btn {
      margin: 0 1px 7px 1px;
      padding: 6px 10px;
      background: #029acf;
      border-color: #029acf;
    }


  }
}

