.col-header {
  font-size: 14px !important;
}

.height30 {
  height: 30px !important;
}

.customer-panel {
  display: flex;

  .row {
    width: 50%;
  }
}

@media (min-width: 1025px) {
  input[type="checkbox"] {
    margin-top: 1px !important;
  }
}
