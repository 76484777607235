#reset_password_page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;

  .reset_password_wrapper {
    width: 100%;
    padding: 48px 5px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      max-width: clamp(100px, 90vw, 400px);
    }

    h1 {
      font-weight: bold;
      color: #2dcb2c;
      padding-top: 50px;
    }

    h3 {
      text-align: center;
    }

    .inputs-wrapper {
      width: clamp(100px, 80vw, 400px);

      .form-group {
        position: relative;
        padding-bottom: 20px;

        .error {
          font-size: 10px;
          position: absolute;
        }
      }

      .buttons {
        width: clamp(100px, 80vw, 400px);;
        display: flex;
        justify-content: flex-end;

        .btn {
          padding: 8px 24px;
        }
      }
    }
  }
}