.close-dock {
    position: absolute;
    z-index: 999999;
    background: white;
    width: 100%;
    border-bottom: 1px solid lightgray;
}

.dock-content {
    overflow: auto;
    height: calc(100vh - 39px);
    position: relative;
    top: 39px;
}

.dock-content section {
    overflow: auto;
    height: 100%;
}

#root > div > div.dock > div > div > div:nth-child(2) {
    overflow: hidden !important;
}