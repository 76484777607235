#print-work-order .map-print > img {
  max-width: 100% !important;
}

@media (max-width: 435px) {

  .options {
    display: flex;
    flex-wrap: wrap;

    .well {
      flex-grow: 1;
      max-width: calc(50% - 20px);
    }
  }

  #print_work_order {
    width: 90vw;
    padding: 0 !important;
  }

  #print_work_order .fontSize8 h6 {
    font-size: 8px !important;
  }

  .dott-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .actual-hrs-col, .price-col, .completed-col {
    min-width: 40px;
    padding-right: 0 !important;
  }

  .completed-col {
    min-width: 45px;
  }

  #print_work_order .mobile-text-left {
    text-align: left !important;
  }

  #print_work_order .pad-left {
    padding: 0 !important;
  }
}

.photo-image > img {
  max-width: 90vw;
}

#print-work-order {
  .weather-condition-panel {
    .panel-body {
      overflow: scroll;
    }
  }

  .applicator-notes-panel {
    .panel-body {
      overflow: scroll;
    }
  }
}