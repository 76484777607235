#organizational-chart-popover {
  min-width: 400px !important;

  .popover-content {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .color-picker-wrapper {
    text-align: -webkit-center;
    position: relative;

    .color-picker {
      position: absolute;
      z-index: 1;
      left: 30px;
      top: 27px;
    }
  }
}

#organizational-chart {
  display: flex;
  font-size: 10px;
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 88vh;
  user-select: none;
  -webkit-user-drag: none;

  img, h5, p, span, div, ul, li, svg, path, input, label, select {
    user-select: none;
    -webkit-user-drag: none;
  }

  .buttons-island-top {
    position: absolute;
    top: 20px;
    left: 20px;
    width: min-content;
    z-index: 4;
    font-size: 15px;

    button {
      cursor: pointer;
      border-radius: 10px;
      width: 100%;
      margin-top: 5px;
    }

    .allow-editing {
      background-color: #56bb56 !important;
      border: 1px solid green !important;
      transition: all 0.3s;
    }

    .avoid-editing {
      background-color: #f36161 !important;
      border: 1px solid red !important;
      transition: all 0.3s;
    }
  }

  .buttons-island-bottom {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: min-content;
    z-index: 4;
    font-size: 15px;

    span {
      font-size: 30px;
      color: #fff;
      background: #474949;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .block-move-function {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 4;
    font-size: 50px;
    color: red;
  }

  #container {
    position: absolute;
    left: 0px;
    top: 0px;
    transform-origin: 0 0;

    #item {
      position: absolute;
      left: 0px;
      top: 0px;

      .empty-chart {
        height: calc(100vh - 120px);
        width: calc(100vw - 20px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: #464646;
        padding: 70px;
      }

      .tree {
        padding: 10px 0 250px 0;
        min-width: 100vw;

        .empty-organizational-chart-info {
          font-size: 30px;
          color: #464646;
          height: 82vh;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        #employee-card {
          width: 250px;
          display: inline-block;
          border-radius: 5px;

          .full-employee-card {
            position: relative;
            border-radius: 5px;
            border-radius: 5px;
            box-shadow: 1px 1px #212121;

            .add-new-employee {
              font-size: 20px;
              padding: 10px 0;
              cursor: pointer;
              transition: all 0.2s;
            }

            .add-new-employee:hover {
              font-size: 22px;
              padding: 9px 0;
              transition: all 0.2s;
            }

            .admin-header-on {
              position: relative;
              border-radius: 50% / 100%;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              background: radial-gradient(circle, rgb(95, 194, 48) 0%, rgba(255, 255, 255, 1) 100%);
              color: black;
              margin: 0 5px;
              min-height: 15px;

              span {
                cursor: pointer;
              }

              .icons-employee-card {
                position: absolute;
                cursor: pointer;
                right: 0;
                top: 5px;

                .disable-icon {
                  cursor: not-allowed;
                }
              }
            }

            .admin-header-off {
              min-height: 15px;
            }

            .show-employee-card {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 3;
            }

            .admin-footer {
              border-radius: 50% / 100%;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              background: radial-gradient(circle, rgb(95, 194, 48) 0%, rgba(255, 255, 255, 1) 100%);
              cursor: pointer;
              color: black;
              margin: 0 5px;
              min-height: 15px;

              p {
                margin: 0;
              }

              .trapeze {
                left: 89px;
              }

              .triangle {
                left: 115px;
              }
            }

            .footer {
              height: 15px;

              .trapeze {
                left: 95px;
              }

              .triangle {
                left: 120px;
              }
            }

            .admin-footer, .footer {
              position: relative;

              .trapeze {
                width: 60px;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 13px solid #000000;
                position: absolute;
                bottom: -13px;
                z-index: 1;
              }

              .triangle {
                position: absolute;
                font-size: 10px;
                top: 17px;
                display: flex;
                align-items: flex-end;
                color: #fff;
                z-index: 2;
                cursor: pointer;
                height: fit-content;
              }

              .show_subordinates {
                transform: rotate(0);
                transition: all 0.2s;
              }

              .hide_subordinates {
                transform: rotate(180deg);
                transition: all 0.2s;

              }
            }

            .color_background_text {
              padding: 2px 10px;
              border-radius: 20px;
            }

            img {
              max-height: 150px;
              max-width: 90%;
            }

            .employee_name {
              margin: 5px 15px;
            }

            .role {
              padding: 0 15px;
              text-align: left;
              word-break: break-all;

              p {
                margin-bottom: 10px;
              }
            }

            .duty {
              padding: 10px 5px 0 15px;
              text-align: left;
              word-break: break-all;

              p {
                margin: 0;
              }

              .draggable-duty {
                padding: 5px;
                border-radius: 5px;
              }

              .duty-title-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .duty-description-wrapper {

                .duty-description {
                  padding: 5px 10px 10px;
                }
              }
            }
          }

          .shown_employee-card {
            opacity: 1;
          }

          .hidden_employee-card {
            opacity: 0.5;
          }

          .add-new-below {
            width: 100%;
            height: 30px;
            font-size: 20px;
            opacity: 0;
            background: #fff;
            transition: all 0.5s;
            transform: rotate(180deg);
          }

          .add-new-below:hover {
            opacity: 1;
            border-radius: 50% / 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: radial-gradient(circle, rgba(226, 226, 226, 1) 0%, rgba(255, 255, 255, 1) 100%);
            transition: all 0.5s;
          }

          .empty-employee-card {
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 5px;
            box-shadow: 1px 1px #212121;
            border: 1px solid rgb(0, 0, 0);

            .select {
              width: 85%;
            }

            .glyphicon {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

#duty-modal {
  .modal-body {
    max-height: 400px;
  }

  .duty-description-modal {
    border: 1px solid #dddddd !important;
    border-radius: 4px !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  }
}

@media (min-width: 1226px) {
  @media (min-height: 913px) {
    #organizational-chart {
      min-height: 93vh;
    }
  }
  @media (max-height: 913px) {
    #organizational-chart {
      min-height: 90vh;
    }
  }
  @media (max-height: 700px) {
    #organizational-chart {
      min-height: 87vh;
    }
  }
  @media (max-height: 500px) {
    #organizational-chart {
      min-height: 84vh;
    }
  }
  @media (max-height: 400px) {
    #organizational-chart {
      min-height: 81vh;
    }
  }
}

@media (max-width: 1226px) {
  @media (max-height: 913px) {
    #organizational-chart {
      min-height: 84vh;
    }
  }
  @media (max-height: 700px) {
    #organizational-chart {
      min-height: 78vh;
    }
  }
  @media (max-height: 500px) {
    #organizational-chart {
      min-height: 74vh;
    }
  }
  @media (max-height: 400px) {
    #organizational-chart {
      min-height: 67vh;
    }
  }
}

@media (max-width: 767px) {
  @media (min-height: 913px) {
    #organizational-chart {
      min-height: 93vh;
    }
  }
  @media (max-height: 913px) {
    #organizational-chart {
      min-height: 90vh;
    }
  }
  @media (max-height: 700px) {
    #organizational-chart {
      min-height: 87vh;
    }
  }
  @media (max-height: 500px) {
    #organizational-chart {
      min-height: 84vh;
    }
  }
  @media (max-height: 400px) {
    #organizational-chart {
      min-height: 81vh;
    }
  }
}