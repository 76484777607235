.truck-filter-modal {
  .modal_header {
    display: flex;
    justify-content: space-between;
  }
  .filter-header{
    text-transform:capitalize;
  }

  .modal-content {
    .filter-numbers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .filter-dates {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
    }
  }
}