.image-draw-wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  @media screen and (max-width: 456px) {
    #large-container {
      width: fit-content;
      height: fit-content;
      overflow-x: hidden;
    }

    #scroll-container {
      width: fit-content;
      height: fit-content;
      border: 1px solid grey;
    }
  }

  .active {
    border: 1px white solid !important;
  }
  .btn{
    position: relative;
    display: inline-block;
    .tooltiptext {
      visibility: hidden;
      width: 100px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: -75%;
      left: 50%;
      margin-left: -50px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    .tooltiptext::after {
      content: "";
      position: absolute;
      top: -35%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #555  transparent;
    }


  }
  .btn:hover{
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
  .modal-dialog {
    width: 100vw !important;
    margin: 0px 0px;

    .modal-content {
      height: 100vh !important;
      background: #3b3b3b;

      .modal-header-ref{
        display: flex;
        width: 100%;
        height: 100%;
      }
      .modal-header {
        display: flex;
        width: 100%;
        border-bottom: 2px solid #e5e5e5;
        padding: 5px 10px;
        justify-content: space-between;

        .title {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: white;
          padding: 5px 10px;
          max-width: 200px;
          span {
            font-size: 22px;
            font-weight: bold;
          }
          @media screen and (max-width: 456px) {
            display: none;
          }
        }
        .confirm-buttons{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 5px 10px;
          max-width: 200px;
          @media screen and (max-width: 456px) {
            justify-content: flex-start;
          }
        }
        .tool-wrapper{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        @media screen and (max-width: 1160px){
          .tools{
            flex-direction: column;
          }

        }
        .tools {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 10px;
          width: 100%;

          button {
            height: 55px;
            margin: 2px 2px;
          }

          .text {
            width: fit-content;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 6px 6px;
            background: #484848;
            border-radius: 5px;
            flex-wrap: wrap;
          }

          .main {
            margin-right: 6px;
          }

        }
      }
      @media screen and (max-width: 456px) {
        .modal-header{
          flex-direction: column;
        }
        .modal-header-ref{
          flex-direction: column;
        }
      }

      .modal-body {
        margin: 0px 0px !important;
        padding: 0px 0px!important;
        .canvas {
          padding: 10px 10px 10px 10px;
          touch-action: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: crosshair;
        }
      }

      .modal-footer {
        border-top: 2px solid #e5e5e5;
        padding: 5px 10px;
      }
    }
  }
}