.custom-footer {
  position: absolute !important;
  bottom: 0px;
  left: 120px;
  right: 120px;
  width: auto !important;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  z-index: 1;
}