/** The Magic **/
.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 43px solid transparent;
    border-bottom: 44px solid transparent;
    border-left: 20px solid white;
    position: absolute;
    top: 50%;
    margin-top: -44px;
    left: 100%;
    z-index: 3;
    opacity: 1;
    transition: unset;
}

.light .btn-breadcrumb .btn,
.light .btn-breadcrumb .btn:hover,
.light .nodes .btn,
.light .nodes .btn:hover {
    background-color: white !important;
    background-image: none !important;
}

.btn-breadcrumb .btn,
.btn-breadcrumb .btn:hover,
.nodes .btn,
.nodes .btn:hover {
    background-color: #414343 !important;
    background-image: none !important;
}

.btn-breadcrumb .btn:active,
.btn-breadcrumb .btn:active {
    border: 0 !important;
}

.btn-breadcrumb .btn:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 48px solid transparent;
    border-bottom: 46px solid transparent;
    border-left: 22px solid rgb(173, 173, 173);
    position: absolute;
    top: 47%;
    margin-top: -45px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}

.btn-breadcrumb .btn.selected {
    border: 2px solid yellow;
    box-shadow: 0 2px yellow,
    0 -2px yellow;
}

.btn-breadcrumb .btn.selected:before {
    border-left: 22px solid yellow;
}

.btn-breadcrumb .btn.beforeSelected:before {
    border-left: 22px solid yellow;
}


.light .btn-breadcrumb .btn.selected {
    border: 2px solid #6f6f6b;
    box-shadow: 0 2px #6f6f6b,
    0 -2px #6f6f6b;;
}

.light .btn-breadcrumb .btn.selected:before {
    border-left: 22px solid #6f6f6b;
}

.light .btn-breadcrumb .btn.beforeSelected:before {
    border-left: 22px solid #6f6f6b;
}

.light .btn-breadcrumb .btn.afterSelectedYellow {
    border-left: yellow;
}

.light .btn-breadcrumb .btn.selectedYellow:before {
    border-left: 22px solid yellow;
}

.light .btn-breadcrumb .btn.beforeSelectedYellow:before {
    border-left: 22px solid yellow;
}

.btn-breadcrumb .btn.afterSelectedYellow {
    border-left: yellow;
}

.btn-breadcrumb .btn.selectedYellow:before {
    border-left: 22px solid yellow;
}

.btn-breadcrumb .btn.beforeSelectedYellow:before {
    border-left: 22px solid yellow;
}

.btn-breadcrumb .btn.beforeSelected {
    margin-right: 1px;
}

.nodes .dollars {
    color: #3db7ff;
}

.btn-breadcrumb .btn {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

/** The Spacing **/
.btn-breadcrumb .btn,
.btn-breadcrumb .btn:hover,
.btn-breadcrumb .btn:active,
.nodes .btn,
.nodes .btn:hover,
.nodes .btn:active {
    padding: 6px 12px 6px 24px;
    height: 90px;
    min-width: 160px;
    font-weight: bold;
    font-size: 14px;
    line-height: 33px;
    color: white !important;
    transition: unset;
    margin-right: 1px;
}

.light .btn-breadcrumb .btn,
.light .btn-breadcrumb .btn:hover,
.light .btn-breadcrumb .btn:active,
.light .nodes .btn,
.light .nodes .btn:hover,
.light .nodes .btn:active {
    color: #444444 !important;
}

.btn-breadcrumb .small.btn,
.btn-breadcrumb .small.btn:hover,
.btn-breadcrumb .small.btn:active {
    line-height: 25px;
}

.btn-breadcrumb .btn:first-child {
    padding: 6px 6px 6px 20px;
}

.btn-breadcrumb .btn:last-child {
    padding: 6px 18px 6px 24px;
}

/** Default button **/

.light .btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border-left: 20px solid white;
}

.btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border-left: 20px solid #414343;
}

.nodes {
    padding: 0;
}

.nodes .btn,
.nodes .btn:hover {
    height: auto;
    font-weight: bold;
    margin-left: 10%;
    width: 80%;
    font-size: 10px;
    line-height: 20px;
    margin-top: 15px;
    white-space: normal;
    word-wrap: break-word;
}

.light .nodes .btn.selected {
    border: 2px solid #6f6f6b;
}

.nodes .btn.selected {
    /*height: 120px;*/
    border: 2px solid yellow;
    margin-left: 0;
    width: 100%;
    font-size: 15px;
    line-height: 30px;
}