#switch-answer-type {
  .horizontally-wrapper {
    display: flex;
    justify-content: space-around;
  }

  .description {
    word-break: break-all;
  }

  .bottom-position {
    display: flex;
    flex-direction: column;

    .description {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 5px;
      margin-left: 0 !important;
    }
  }

  .top-position {
    display: flex;
    flex-direction: column-reverse;

    .description {
      width: 100%;
      margin-bottom: 15px;
      margin-left: 0 !important;
    }
  }

  .right-position {
    display: flex;
    align-items: center;

    .description {
      width: 100%;
    }
  }

  .material-switch > input[type="checkbox"] {
    display: none;
  }

  .material-switch > label {
    cursor: pointer;
    height: 0;
    position: relative;
    width: 40px;
  }

  .material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
  }

  .material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
  }

  .material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
  }

  .material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
  }
}