#customerSearch {
  .react-bs-container-body {
    tbody {
      tr:last-child {
        height: 250px;
      }
    }
  }
}
@media(max-width: 500px) {
  .send-credentials-modal .button-left-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0
  }

  .send-credentials-modal .button-left {
    width: fit-content;
    margin: 2px 0 0 0 !important;
  }

  .send-credentials-modal .button-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 0
  }

  .send-credentials-modal .button-right {
    width: fit-content;
    margin: 2px 0 0 0 !important;

    .navbar {
      margin-bottom: 8px !important;
    }
  }
}

.send-credentials-modal {
  .wrapper {
    display: flex;
    align-items: center;
    font-size: clamp(10px, 3vw, 14px);
  }
}
#customer-info {
  .customer_scheduled_events_wrapper {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
  }

  .well_custom_padding {
    padding: 5px 19px !important;
  }

  .customer_button {
    width: clamp(10px, 100%, 110px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #customerSearch {
    .react-bs-container-body {
      tbody {
        tr:last-child {
          height: 250px;
        }
      }
    }
  }

  .navbar {
    margin-bottom: 8px !important;

    }
    .well{
        margin-bottom: 4px !important;
    }
    .scroll-y{
        overflow-y: scroll;
    }

  .custom-grid-container {
    height: 100%;
    display: grid;
    justify-items: stretch;
    grid-gap: 10px;
    align-items: stretch;
    @media(min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .contacts {
        max-height: 591.19px;
        height: 50%;

        .scroll-y {
          max-height: 464px;
        }
      }
      .sites {
        max-height: 582.91px;
        height: 49.3%;

        .scroll-y {
          max-height: 456px;
        }
      }

      .customer-notes {
        height: 90%;
      }
      .last-edit {
        height: 9.3%;
      }

      .customer-sales-data {
        height: 27%;
      }
      .map-container {
        height: 72.3%;

        .map {
          height: 94%;
        }
      }

      grid-row-gap: 4px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    @media(max-width: 991px) {
      grid-gap: 4px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      .contacts, .sites {
        max-height: 80vh;

        .scroll-y {
          max-height: calc(80vh - 120px)
        }
      }
      .map {
        height: 80vh;
      }
      .customer-notes {
        height: fit-content;
      }
    }
  }

  @media(min-width: 1200px) {
    margin-top: -8px !important;
    .customer-notes {
      min-height: 670px;
      height: calc(88.8vh - 94px);
    }

    .middle-column-height {
      min-height: 383px;
      height: 44.5vh;
    }
    .control-label {
      font-size: 11px;
    }
    .form-control {
      font-size: 10px !important;
      height: 20px !important;
    }

    .form-group {
      margin-bottom: 0px !important;
    }

    h4 {
      font-size: 14px !important;
      margin-top: 2px !important;
      margin-bottom: 2px !important;
    }

    .dropzone {
      background-color: #e5e5e56b;
      height: 50px !important;
    }

    .grid_drop_zone {
      min-height: 112px !important;
      height: calc(88.8vh - 653px) !important;
    }

    .customer-sales-data {
      padding-bottom: 4px !important;
      margin-bottom: 4px !important;
      padding-top: 5px !important;
    }

    .container-h308 {
      min-height: 308px;
      height: calc(44vh - 75px)
    }

    .last-edit {
      margin-top: 4px;
    }

    .map {
      min-height: 435px;
      height: calc(88.8vh - 300px);
    }

    .css-1pcexqc-container {
      height: 24px !important;
      font-size: 10px !important;

      .css-bg1rzq-control {
        min-height: 10px !important;
        height: 24px !important;

        .css-1hwfws3 {
          min-height: 10px !important;
          height: 24px !important;
        }

        .css-1wy0on6 {
          min-height: 10px !important;
          height: 24px !important;
        }
      }

      .css-1szy77t-control {
        min-height: 10px !important;
        height: 24px !important;

        .css-1hwfws3 {
          min-height: 10px !important;
          height: 24px !important;

          .css-151xaom-placeholder {
            min-height: 10px !important;
            height: 16px !important;
          }

          .css-1g6gooi {
            height: 24px !important;
          }
        }

        .css-1wy0on6 {
          min-height: 10px !important;
          height: 24px !important;
        }
      }
    }
  }

  #duplicates-modal {
    $main-wrapper-background: #ececec;
    $single-background: #ffffff;
    $matching-value: #27ad00;
    $not-matching-value: #ff0000;

    .modal-body {
      max-height: 500px;

      .chosen-duplicates-main-wrapper {
        background: $main-wrapper-background;
        padding: 10px 20px;
        border-radius: 10px;
        margin: 5px 0;

        .chosen-duplicates-header {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          cursor: pointer;
          align-items: center;
        }

        .single-customer {
          margin-bottom: 20px;
          border: 1px solid;
          border-radius: 20px;
          background: $single-background;

          .single-compare {
            display: grid;
            grid-template-columns: 40% 20% 40%;

            .single-left-value {
              text-align: right;
              padding-right: 20px;
            }

            .single-right-value {
              padding-left: 20px;
            }

            .single-center-value {
              text-align: center;
            }

            .matching-value {
              color: $matching-value;
            }

            .not-matching-value {
              color: $not-matching-value;
            }
          }
        }
      }
    }
  }

  @media(max-width: 500px) {
    .send-credentials-modal .button-left-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 0
    }

    .send-credentials-modal .button-left {
      width: fit-content;
      margin: 2px 0 0 0 !important;
    }

    .send-credentials-modal .button-right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-right: 0
    }

    .send-credentials-modal .button-right {
      width: fit-content;
      margin: 2px 0 0 0 !important;
    }
  }

    .send-credentials-modal {
        .wrapper {
            display: flex;
            align-items: center;
            font-size: clamp(10px, 3vw, 14px);
        }
    }
}

#duplicates-modal {
  $main-wrapper-background: #ececec;
  $single-background: #ffffff;
  $matching-value: #27ad00;
  $not-matching-value: #ff0000;

  .modal-body {
    max-height: 500px;

    .chosen-duplicates-main-wrapper {
      background: $main-wrapper-background;
      padding: 10px 20px;
      border-radius: 10px;
      margin: 5px 0;

      .chosen-duplicates-header {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        cursor: pointer;
        align-items: center;
      }

      .single-customer {
        margin-bottom: 20px;
        border: 1px solid;
        border-radius: 20px;
        background: $single-background;

        .single-compare {
          display: grid;
          grid-template-columns: 40% 20% 40%;

          .single-left-value {
            text-align: right;
            padding-right: 20px;
          }

          .single-right-value {
            padding-left: 20px;
          }

          .single-center-value {
            text-align: center;
          }

          .matching-value {
            color: $matching-value;
          }

          .not-matching-value {
            color: $not-matching-value;
          }
        }
      }
    }
  }
}