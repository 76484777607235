#admin-documents {

  ul.nav li.dropdown:hover > ul.dropdown-menu, ul.dropdown-menu:hover {
    display: block !important;
    text-decoration: none !important;
  }

  .dropdown-toggle:hover + .dropdown-menu, .dropdown-menu:hover {
    display: block !important;
  }

  .add-new-tab {
    a {
      padding: 0;
    }

    .glyphicon {
      padding: 12px 15px;
    }
  }
  .edited-tab {
    a {
      padding: 0;
    }

    .delete-tab {
      padding: 10px 15px 10px 15px;
      margin: 0;
    }

    .tab-title-wrapper {
      display: flex;
      align-items: center;

      .tab-title {
        padding: 10px 15px 10px 15px;
        margin: 0;
      }

      .non-deletable-tab-title {
        padding: 10px 15px 10px 15px;
        margin: 0;
      }

      .edit-glyph {
        opacity: 0;
        padding: 0;
        width: 0;
      }

      .remove-glyph {
        opacity: 0;
        padding: 0;
        width: 0;
      }
    }

    .tab-title-wrapper:hover {
      .non-deletable-tab-title, .tab-title {
        padding-right: 10px;
        transition: all 0.5s;
      }

      .edit-glyph {
        cursor: pointer;
        width: 20px;
        opacity: 1;
        padding: 12px 5px 12px 0;
        transition: all 0.5s;
      }

      .remove-glyph {
        cursor: pointer;
        padding: 12px 10px 12px 0;
        width: 20px;
        opacity: 1;
        transition: all 0.5s;
      }
    }
  }

  .document-title, .document-note {
    word-break: break-all;
  }

  .document-file {
    display: flex;
    flex-wrap: wrap;
  }

  .react-bs-table-bordered {
    border: none;
    display: none;
  }

  .react-bs-table-search-form {
    margin-top: 5px;
  }

  .react-bs-table-pagination {
    position: fixed;
    padding-right: 40px;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .dnd-table {
    margin-bottom: 100px;

    tr:not(:first-child) {
      width: auto;
      border-top: none;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }

  .react-bs-table-no-data {
    padding: 0;
    border-top: none;
  }

  .table-actions {
    span {
      font-size: 20px;
      margin: 5px;
      cursor: pointer;
    }

    .remove {
      color: red;
    }

    .approved {
      color: green;
    }
  }

  .grid_drop_zone {
    width: -webkit-fill-available;
  }
}

#new-document-modal {
  .new_document_dropzone {
    .grid_drop_zone {
      overflow: scroll;
      overflow-x: hidden !important;
    }
  }
}

#popover-restore {
  .popover-content {
    display: flex;

    .popover-select {
      width: 200px;
    }

    .btn {
      margin-left: 5px;
    }
  }
}

#popover-options {
  .popover-select {
    width: 200px;
  }

  .btn {
    margin-left: 5px;
  }
}

#document-history-modal {
  .modal-body {
    max-height: 400px;
    word-break: break-all;

    hr {
      margin: 10px 30px;
      border: 1px solid #dddddd;;
    }
  }
}