#recorder-toolbar {
    z-index: 1000;
    position: absolute;
    bottom: 30px;
    right: 70px;
    align-items: center;
    color: white;
    display: flex;
}

#recorder-toolbar .recorder-toolkit-wrapper {
    display: flex;
    flex-direction: column !important;
    position: relative;
    box-shadow: 0 2px 4px #0000004d;
    border-radius: 10px;
    cursor: pointer;
}

#recorder-toolbar .recorder-btn {
    border: 1px solid white;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 2px 4px #0000004d;
    cursor: pointer;
    background: none;
    outline: none;
}

#recorder-toolbar .recorder-btn:hover {
    opacity: 0.6
}

#recorder-toolbar .issue-record-media-wrapper {
    box-sizing: content-box !important;
}

#recorder-toolbar .recorder-tools {
    background: white;
    padding: 8px 10px 8px 35px;
    width: 175px;
    display: flex;
    justify-content: space-around;
    border-radius: 20px;
    align-items: center;
}

#recorder-toolbar .recorder-btn > svg {
    margin: 0 !important;
}

#recorder-toolbar .finish-btn {
    background: lawngreen;
}

#recorder-toolbar .resume-btn {
    color: grey;
}

#recorder-toolbar .reset-btn {
    color: red;
}

#recorder-toolbar .recorder-user-avatar {
    box-shadow: 0 2px 4px #0000004d;
    width: 86px;
    height: 86px;
    display: -webkit-flex;
    display: flex;
    border-radius: 100%;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    -webkit-justify-content: center;
    justify-content: center;
    font-weight: 700;
    color: grey;
    font-size: 24px;
    z-index: 11;
    background: white;
    position: absolute;
    top: -20px;
    left: -55px;
}

.media-wrapper {
    margin: 0 0 10px 0 !important;
}

.active-tools {
    opacity: 1 !important;
    transition: all 0.35s ease-in !important;
}

.inactive-tools {
    opacity: 0 !important;
    transition: all 0.35s ease-in !important;
}

.recording-alert-modal {
    margin: 20px 0 0 0 !important;
}

.recording-alert-modal-item {
    list-style: none;
    line-height: 1.25;
}