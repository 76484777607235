#scheduler-modal .modal-dialog {
    max-height: 95vh !important;
}

#scheduler-modal .recipient-text {
    line-height: 1 !important;
}

.fc-time-grid .fc-slats td {
    height: 1.25em !important;
}

#scheduler-no-modal .Pane {
    padding: 10px 10px 0 10px !important;
}

#scheduler-no-modal .pane-wrapper {
    height: calc(100vh - 230px) !important;
}

#scheduler-no-modal .Resizer {
    margin-right: 10px !important;
}

#scheduler-no-modal .fc-scroller {
    height: auto !important;
    overflow-y: auto !important;
}

#scheduler-no-modal .fc .fc-scrollgrid table {
    border-right-style: inherit !important;
}

.fc-day-grid-event {
    max-height: 40px !important;
    overflow: hidden !important;
}


.rdw-link-decorator-wrapper > a {
    cursor: pointer !important;
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
    padding-bottom: 0.3em !important;
}

.fc-time-grid-event.fc-selected {
    overflow: hidden !important;
}

.fc-time-grid-event .fc-v-event .fc-event .fc-start .fc-end .fc-draggable .fc-resizable .fc-selected {
    overflow: hidden !important;
}


.event-modal-header {
    padding: 10px;
}

.master-form.in.modal .event-modal-header {
    padding: 10px 10px 0 10px !important;
}

.rdw-link-decorator-wrapper {
    z-index: 9999 !important;
}

@media (max-width:767px) {
    .event-modal-header {
        padding-top: 15px !important;
        margin: 0 !important;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        min-width: 768px !important;
    }
}

@media (min-width: 768px) and (max-height: 1000px) {
    #scheduler-modal .modal-content {
        height: 94vh !important;
    }

    #scheduler-modal .modal-body {
        margin-top: 10px !important;
    }

    .fc-time-grid .fc-slats td {
        height: 1em !important;
    }

    .fc-content {
        line-height: 12px !important;
    }

    .fc-day-grid-event {
        max-height: 35px !important;
        overflow: hidden !important;
    }

    .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
        padding-bottom: 0.3em !important;
    }

    .fc-time-grid-event.fc-selected {
        overflow: hidden !important;
    }

    .fc-time-grid-event .fc-v-event .fc-event .fc-start .fc-end .fc-draggable .fc-resizable .fc-selected {
        overflow: hidden !important;
    }
}

.material-switch > input[type="checkbox"] {
    display: none;
}

.pane-wrapper .material-switch > label {
    background: rgb(255, 255, 255);
    cursor: pointer;
    width: 40px;
    position: relative;
}

.pane-wrapper .material-switch > label::before {
    background: rgb(118, 118, 118);
    box-shadow: 0px 0px 5px rgba(144, 144, 144, 0.3);
    border-radius: 8px;
    content: '';
    height: 10px;
    left: 8px;
    top: -4px;
    position: absolute;
    transition: all 0.4s ease-in-out;
    width: 25px;
}

.pane-wrapper .material-switch > label::after {
    background: rgb(165, 165, 165);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(144, 144, 144, 0.3);
    content: '';
    height: 18px;
    left: 4px;
    position: absolute;
    top: -8px;
    transition: all 0.3s ease-in-out;
    width: 18px;
}

.pane-wrapper .material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
}

.pane-wrapper .material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 18px;
}

@media (min-width: 768px) and (max-height: 700px) {
    .fc-time-grid .fc-slats td {
        height: 1em !important;
    }

    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 4px !important;
    }

    .fc-center > h2 {
        font-size: 18px !important;
    }

    .fc button {
        height: 1.75em !important;
    }

    .fc-body .fc-row {
        min-height: 1.75em !important;
    }

    .fc-ltr .fc-axis {
        height: 16px
    }

    .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
        padding-bottom: 0.4em !important;
    }

    #scheduler-modal .modal-body {
        margin-top: 5px !important;
    }

    .fc-content {
        line-height: 12px !important;
    }

    .gm-svpc {
        margin-top: 25px !important;
    }

    #scheduler-modal .abc-checkbox, .abc-radio {
        margin-top: 0 !important;
        font-size: 12px !important;
    }

    #scheduler-modal .abc-checkbox label::before {
        width: 15px !important;
        height: 15px !important;
    }

    .fc-time-grid-event.fc-selected {
        overflow: hidden !important;
    }
}

@media (max-width: 476px) {
    .fc-center > h2 {
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .fc .fc-toolbar > * > * {
        margin-left: 0 !important;
    }

    .fc-content {
        line-height: 11px !important;
    }

    .fc-time-grid .fc-slats td {
        height: 0.8em !important;
    }

    .btn {
        border-radius: 0 !important;
    }

    .fc th {
        font-size: 10px !important;
    }

    .fc-left {
        width: 100% !important;
        font-size: 15px !important;
        margin: 14px 0 2px 0 !important;
    }

    .fc-right {
        width: 100% !important;
        font-size: 14px !important;
        margin-bottom: 14px !important;
    }

    .fc-axis > span {
        font-size: 10px !important;
    }

    .fc-day-grid-event {
        max-height: 35px !important;
    }

    .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
        padding-bottom: 0.3em !important;
    }

    .fc-time-grid-event.fc-selected {
        overflow: hidden !important;
    }

    .rdw-link-decorator-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}





