#emailsList .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;

    .he_stage {
        td {
            overflow: unset;
            white-space: unset;
            border: unset;
            padding: unset;
        }
        table {
            border-collapse: separate;
            margin-bottom: unset;
            margin-top: unset;
            line-height: unset;
            border-spacing: unset;
        }
        tr {
            border: unset;
            padding: unset;
        }
        p {
            margin: unset;
        }
    }
}

#emailsList .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#emailsList .vertical-responsive-height {
    height: calc(100vh - 180px);
    overflow-y: hidden;
}

#emailsList .SplitPane {
    max-height: calc(100vh - 135px) !important;
    overflow-y: auto;
}

#emailsList .Resizer {
    z-index: 11 !important;
}

#emailsList .react-bs-container-header.table-header-wrapper {
    z-index: 10 !important;
}

#emailsList .message p {
    white-space: normal;
}

@media (min-width: 1025px) {
    #emailsList .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 8px;
        background-color: white;
    }

    #emailsList .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .pagination {
        display: flex !important;
    }

    #emailsList .react-bs-table-pagination {
        margin-top: 10px !important;
    }

    #emailsList .vertical-responsive-height {
        height: calc(100vh - 160px);
    }
}

#emailsList .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#emailsList .panel {
    margin-bottom: 10px !important;
}

#emailsList .panel > div > div {
    padding-bottom: 0 !important;
}

#emailsList .react-bs-container-body {
    margin-top: 36px;
}

#emailsList .attachment_name{
    width: 60px;
    font-size: 10px;
    word-wrap:break-word;
    display:inline-block;
    text-align: center;
}

#emailsList .attachment_name:hover {
    text-decoration: underline;
}

#emailsList .doc-attachment-preview{
    display: inline-grid;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 468px) {
    #emailsList .pagination > li > a {
        padding: 8px 9px !important;
    }

    #emailsList .btn-default {
        padding: 8px !important;
    }
}