#issue-view {
    max-height: 100dvh;
}

#issue-view .react-bs-table {
    max-height: calc(100dvh - 200px) !important;
    height: auto !important;
    overflow: auto !important;
}

#issue-view .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#issue-view .vertical-responsive-height {
    height: calc(100dvh - 140px);
    overflow-y: hidden;
}

#issue-view .react-bs-container-body {
    margin-top: 41px;
    overflow-y: hidden !important;
}

#issue-view .dropdown-menu {
    top: -300% !important;
}

@media (min-width: 1024px) {
    #issue-view .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #issue-view .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 10px;
        width: 100%;
        cursor: pointer;
    }
}