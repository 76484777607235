@media print {
    .container {
        width: 100% !important;
    }
    .print-full-width {
        width: 100% !important;
    }
    .well {
        padding: 5px !important;
    }
    .panel {
        padding: 0px !important;
        border: 1px !important;
        color: red !important;
        margin-bottom: 0px !important;
    }
    .panel-body {
        padding: 0px !important;
    }


    .avoid_page_breaking_inside {
        page-break-inside: avoid;
        break-inside: avoid !important;
    }

    .always_page_break_after {
        page-break-after: always;
    }

    .always_page_break_before {
        page-break-before: always;
    }

    .applicatorNotes {
        background-color: #fff;
        border: 1px solid #ccc;
        border-collapse: collapse;
        border-radius: 6px;
        width: 100%;
    }

    .removalServices {
        background-color: #fff;
        border: 1px solid red;
        border-collapse: collapse;
        border-radius: 2px;
        width: 100%;
        padding: 10px;
    }

    .removal-services-header {
        color: red !important;
        font-size: large !important;
    }

    .removal-text {
        font-size: large !important;
        color: red !important;
    }

    .inline-assets {
      display: inline-block;
      float: left;
      margin-right: 10px;
    }
    .photo {
        margin-bottom: 10px;
        width: fit-content;
    }

    .photo-image {
        padding: 1px 1px 0px 1px;
        border: 1px solid #dddddd;
    }

    .photo-text {
        border: 1px solid #dddddd;
        border-spacing: 0;
        border-top: none;
        padding: 10px 0px;
    }

    .photo img {
        max-height: 800px;
        max-width: 968px;
    }
}

@media print {
  a[href]:after {
    content: none !important;
  }
}
