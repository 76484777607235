#new-question-modal {
  .flex-center {
    display: flex;
    align-items: center;
  }

  .trash-icon {
    margin-left: 20px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .trash-icon:hover {
    color: #ff0000;
    transition: all 0.3s;
  }
}