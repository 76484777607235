.manage-data {

  @media screen and (max-width: 450px) {
    .wrapper {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .wrapper {
    height: calc(100vh - 150px);
    margin-top: 60px;
  }

  .list_wrapper {
    height: calc(100vh - 250px);
    background-color: white;
    -webkit-box-shadow: -1px 2px 23px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 2px 23px -9px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 2px 23px -9px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .list_column {
    margin-right: 60px;
  }

  .title {
    color: #777777;
    font-size: 16px;
    margin: 15px 0 0 0;
    text-align: center;
    padding-bottom: 12px;
    padding-left: 10px;
    border-bottom: 3px solid #c2c2c2;
  }

  .list_item {
    height: 100px;
    display: flex !important;
    justify-content: space-between;
    font-size: 18px;
  }

  .list_item .active {
    background-color: #dd9d12;
  }

  .component_wrapper {
    background-color: white !important;
    padding: 20px 0 20px 40px !important;
    -webkit-box-shadow: 3px 9px 27px -25px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: 3px 9px 27px -25px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 3px 9px 27px -25px rgba(0, 0, 0, 0.75) !important;
  }

  .active_list_item {
    background-color: #fdff6c !important;
    font-weight: bold !important;
  }
}
