#transfer_plant {
    padding: 0 20px 20px 50px;
}

#transfer_plant .transfer_plant_title {
    font-weight: bold;
    font-size: 20px;
    color: #777777;
}

#transfer_plant .transfer_plant_inputs {
    margin-top: 30px
}

#transfer_plant .transfer_plant_input {
    margin-top: 10px
}

#transfer_plant .transfer_btn {
    margin-top: 20px;
    background: #1766A6;
    border: 1px solid #1766A6;
    width: 200px !important;
}

.transfer_tooltip_li {
    color: #f7aa2d !important;
    margin: 1px 0 !important;
}