#questionnaire {
  display: flex;
  justify-content: center;

  .loader {
    width: clamp(200px, 100%, 768px);
    margin: 10px;
    padding: 30px;
    box-shadow: 5px 5px 15px -4px rgba(0,0,0,0.20) inset, -5px -5px 15px -4px #00000033 inset;
    border-radius:	10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
  }

  .questionnaire-wrapper {
    width: clamp(200px, 100%, 768px);
    margin: 10px;
    padding: 30px;
    box-shadow: 5px 5px 15px -4px #00000033 inset, -5px -5px 15px -4px #00000033 inset;
    border-radius:	10px;
    position: relative;

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      img {
        max-width: -webkit-fill-available;
        max-height: 120px;
      }
    }

    .welcome-text {
      text-align: center;
      padding: 10px 0;
    }

    .welcome-text-preview, .empty-questionnaire-preview, .question-preview-wrapper {
      &:hover {
        cursor: pointer;
        border-radius:	5px;
        box-shadow: 5px 5px 15px -4px #00000033, -5px -5px 15px -4px #00000033;

        &:before {
          content: "\270E";
          font-size: 30px;
          position: absolute;
          left: 35px;
        }
      }
    }

    .header-edit-component-wrapper {
      border-radius:	5px;
      box-shadow: 5px 5px 15px -4px #00000033, -5px -5px 15px -4px #00000033;
      padding: 5px;

      textarea {
        resize: vertical;
      }
    }

    .empty-questionnaire-preview {
      margin: 0;
      padding: 20px;
    }

    .question-edit-component-wrapper {
      border-radius: 5px;
      box-shadow: 5px 5px 15px -4px #00000033, -5px -5px 15px -4px #00000033;
      padding: 5px;

      .question {
        height: 300px;
        overflow-x: hidden;
      }
    }

    .rolled-button-text {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 5px;
    }

    .rolled-text {
      text-align: right;
      margin-top: 5px;
      text-overflow: ellipsis;
      padding-right: 0;
      overflow: hidden;
    }

    @media (max-width: 768px) {
      .rolled-text {
        text-align: left;
      }
    }

    .question-wrapper {
      font-size: 14px;
      padding: 5px 30px;
      word-break: break-all;
      text-align: justify;

      .order {
        padding-right: 5px;
      }

      .checkbox-options-wrapper {
        display: flex;

        input[type=checkbox] {
          scale: 1.5;
          cursor: pointer;
        }

        label {
          word-break: break-all;
          cursor: pointer;
        }
      }

      .horizontally-wrapper {
        margin: 30px 0 10px;
        justify-content: space-evenly;

        .horizontally{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: fit-content;

          input[type=checkbox] {
            margin-bottom: 5px;
          }

          label {
            text-align: center;
            padding: 0 5px;
          }
        }
      }

      .vertically-wrapper {
        margin: 10px 0;
        flex-direction: column;

        .vertically {
          display: flex;
          align-items: flex-start;

          input[type=checkbox] {
            margin: 3px 10px 0 0 !important;
          }
        }
      }

      .description {
        textarea {
          resize: vertical;
        }
      }
    }

    .buttons {
      text-align: right;
    }
  }
}