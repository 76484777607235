.login-center{
    display: flex;
    justify-content: center;
    height: 100vh;
    .well{
        margin: 20px 20px;
        width: 40vw;
        height: fit-content;
    }
    @media screen and (max-width: 767px) {

        .well{
            width: 80vw;
        }
    }
}