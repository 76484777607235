
#customer-payment-modal .modal-content {
    height: unset!important;
}

.CardSubscritionDemo {
    margin-top: 24px;
    width: 100%;
}

.Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
}

.CardSubscritionDemo label {
    align-content: center;
    width: 100%;
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
}

.CardSubscritionDemo button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

.CardSubscritionDemo form {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border: none !important;
}

.CardSubscritionDemo button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}
.CardSubscritionDemo input[type=range]:hover{
    cursor: pointer;
}
.CardSubscritionDemo input[type=text],
.StripeElement {
    display: block;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.CardSubscritionDemo input[type=text]::placeholder {
    color: #aab7c4;
}

.CardSubscritionDemo input[type=text]:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

div[disabled]
{
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.7;
}

.credential_input {
    margin-bottom: 7px !important;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
}

.trial-version {
    margin-top: 6px;
    color: #f80f00;
    cursor: pointer;
    font-size: 11px;
}

.trial-version-used {
    margin-top: 6px;
    color: #f80f00;
    font-size: 11px;
}

.trial-version-expired {
    margin-top: 6px;
    color: #f80f00;
    font-size: 11px;
}

.trial-version:hover {
    text-decoration: underline;
}

.active-users-notitification {
    font-size: 11px !important;
    color: #6b7c93 !important;
}

.active-users-progress-bar {
    height: 6px !important;
    margin-bottom: 2px !important;
}

.subscribe-btn-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.limit-users-notification {
    font-size: 11px !important;
    color: #d9230f !important;
}

.subscribe-trial-renew-request {
    font-weight: bold;
}

.subscribe-trial-renew-request:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 450px) {
    .credential_input {
        font-size: 12px;
    }

    .CardDemo button {
        font-size: 13px !important;
    }

    .trial-version {
        font-size: 10px;
    }
}
