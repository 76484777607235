.progress-section {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 10px 10px;
  display: flex;
  width: 100%;
  height: fit-content;

  .left-side {
    padding: 10px 10px;

    .title {
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .progress-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100px;
      height: 100px;
      border: 1px solid #D9D9D9;
      padding: 10px 10px 0px 10px;

      .percent {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .right-side {
    width: 100%;
    padding: 10px 10px;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .title {
        text-transform: uppercase;
        font-weight: bold;
      }

      .status {
        width: 100px;
        text-align: left;
        font-weight: bold;
      }
    }

    .progress-list {
      list-style-type: none;
      padding-left: 0px;

      .progress-list-item {
        display: flex;
        justify-content: space-between;

        .link {
          color: #d9230f;
        }

        .link:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        .dotted-line {
          flex-grow: 1;
          border-bottom: 1px dashed #9d9d9d;
        }

        .item-status {
          width: 100px;
          text-align: left;
        }
      }
    }

  }
}