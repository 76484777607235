#time-tracks {

  .vertical-responsive-height {
    height: 83vh;
    overflow-y: hidden;

    .react-bs-table-container {
      transition-delay: 300ms;
      transition-duration: 100ms;
      max-height: calc(100vh - 290px) !important;
    }

    &.filter-visible {
      max-height: calc(100vh - 300px) !important;

      .react-bs-table-container {
        transition-delay: unset;
        max-height: calc(100vh - 430px) !important;
      }
    }
  }

  .react-bs-table {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .react-bs-container-body {
    height: calc(100% - 40px) !important;
  }

  .rc-time-picker-input {
    height: 36px;
  }

  .rc-time-picker-input::placeholder {
    color: #ccc;
  }

  .rdtPicker {
    min-width: max-content;
  }

  #timeTracksMap {
    height: calc(100vh - 180px) !important;
    transition-duration: 500ms;

    &.filter-visible {
      height: calc(100vh - 300px) !important;
    }
  }
}