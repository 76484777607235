#customer_feedback {
  height: auto;

  .react-bs-table-container {
    max-width: 100vw;
    overflow: auto;
  }

  .react-bs-container-footer, .react-bs-container-header {
    overflow: visible;
  }

  .react-bs-table-pagination {
    position: fixed;
    padding-right: 40px;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .react-bs-table-container {
    padding-bottom: 100px;
  }

  .mobile-customer-feedback {
    padding-right: 15px;
    padding-left: 15px;

    .quick-filters {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .star-with-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}