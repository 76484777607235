#trucks {
  .option-select-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .option-name {
      border-radius: 5px;
      padding: 2px 4px;
      margin-right: 2px;
      text-align: center;
    }

    .option-checkbox {
      max-width: 100px;
      text-align: right;

      input {
        vertical-align: -2px;
        cursor: pointer;
      }

      span {
        vertical-align: middle;
      }
    }
  }

  .carousel-inner img {
    margin: auto;
  }

  .image-carousel {
    height: 300px !important;
  }

  .margin-top-bottom-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .margin-top-20 {
    margin-top: 20px;
  }

  a {
    cursor: pointer;
  }

  .service-status {
    padding: 3px 6px;
    border-radius: 5px;
  }

  .table-actions {
    cursor: pointer;
    color: #d9230f;
    &.disabled{
      color: grey;
      pointer-events: none;
    }
  }

  .remove-icon {
    cursor: pointer;
  }

  .service-documents {
    margin-top: 10px;
    display: inline-flex;

    img {
      height: 90px;
    }

    a {
      text-align: center;

      img {
        height: 90px;
      }
    }
  }

  .table-toolbar {
    display: flex;
    padding: 15px;
    width: 100%;
    justify-content: space-between;

    .buttons {
      button {
        padding: 6px 12px;
        margin-right: 10px;
      }

      @media screen and (max-width: 1024px) {
        button {
          margin-bottom: 2px;
          min-width: 150px;
          border-radius: 4px !important;
        }
      }
    }
  }

  .table-image {
    width: 30px;
    height: 30px;
  }

  .react-bs-table-container {
    max-width: 90vw;
  }

  @media screen and (max-width: 450px) {
    .react-bs-container-body {
      overflow-y: auto !important;
      overflow-x: auto !important;
    }
  }

  @media screen and (min-width: 451px) {
    .react-bs-container-body {
      overflow: auto !important;
      table {
        height: auto !important;
      }
    }

    .truck-financial-table {
      .react-bs-container-body {
        overflow-y: auto !important;
        overflow-x: hidden !important;
      }
    }
  }

  .react-bs-table-container {
    margin-bottom: 30px !important;
  }

  .table-export-csv-only {
    margin-bottom: 0px !important;

    .react-bs-table-bordered {
      border: none !important;
    }
  }

  .trucks-table-container {
    height: 525px !important;
    position: relative !important;

    .trucks-table {
      position: relative !important;
      height: 100% !important;
    }
  }

  .custom-footer {
    position: absolute !important;
    bottom: 0px;
    left: 120px;
    right: 120px;
    width: auto !important;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    z-index: 1;
  }
}

.service-documents {
  margin: 10px 0 0 10px;
  display: inline-flex;

  img {
    height: 50px;
  }

  a {
    text-align: center;
    overflow: hidden;

    img {
      height: 50px;
      width: 60px;
    }
  }

  .doc-name {
    width: 50px;
    word-break: break-word;
  }
}

.trucks-payment-modal {
  .modal-content {
    position: relative !important;
    height: 600px !important;
    width: 450px;
    margin: auto;

    .modal-body {
      padding: 20px 20px 0 20px !important;
      height: 80%;
      position: relative;
      align-content: space-around;
    }
  }
}
