
.asset_photo_widget{
  position:relative;
  display: inline-block;
}
.remove_asset_icon{
  position: absolute;
  top: -5px;
  left: -5px;
  cursor: pointer;
}
