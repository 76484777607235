#tasks .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;
}

#tasks .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#tasks .react-bs-container-header.table-header-wrapper{
    z-index: 10 !important;
}

#tasks .SplitPane {
    max-height: calc(100vh - 135px) !important;
}

#tasks .Resizer {
    z-index: 11 !important;
}

#tasks .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#tasks .table-wrapper {
    height: 95%;
    overflow-y: scroll;
    overflow-x: clip;
    width: 100%;
}

#tasks .vertical-responsive-height {
    height: calc(100vh - 135px);
}

#tasks .vertical-responsive-max-height {
    overflow-x: hidden;
    max-height: calc(100vh - 135px);
}

#tasks .panel {
    margin-bottom: 10px !important;
}

#tasks .panel > div > div {
    padding-bottom: 0 !important;
}

#tasks .react-bs-container-body,
#tasks .react-bs-table-container{
    margin: 0;
}

#tasks .table-striped > tbody > tr:nth-of-type(odd) {
    background: none;
}

#tasks .react-bs-table-no-data {
    border: none;
    padding: 0;
}

#tasks .abc-checkbox label{
    padding-left: 10px;
}

#tasks .customersMap{
    position: relative;
}

#tasks .full-map {
    overflow: visible !important;
    margin: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 999;
}

#tasks .full-map body{
    position: fixed !important;
}

@media (max-width: 1024px) {
    #tasks .full-map{
        position: fixed !important;
        overflow: visible !important;
        margin: 0 !important;
        margin-left: -15px !important;
        width: 100vw !important;
        height: 100vh !important;
    }
    #tasks .full-map body{
        position: fixed !important;
    }
}

#tasks .btn-full-screen {
    z-index: 99;
    position: absolute;
    top: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 10px;
    right: 0;
    background-image: none;
    background-color: #fff;
}

#tasks .btn-full-screen:hover {
    color: unset !important;
}

#tasks .btn-full-screen:focus {
    color: unset !important;
}

@media (min-width: 1025px) {
    #tasks .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }

    #tasks .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }
}

@media (max-width: 468px) {
    #tasks .pagination > li > a {
        padding: 8px 9px !important;
    }

    #tasks .btn-default {
        padding: 8px !important;
    }

    .pl-5 {
        padding-left: 5px !important;
    }
}

#tasks .material-switch > input[type="checkbox"] {
    display: none;
}

#tasks .material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

#tasks .material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
#tasks .material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
#tasks .material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
#tasks .material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

#tasks .settings-dropdown-menu{
    .dropdown-menu {
        min-width: 120px;
    }
}

#tasks _::-webkit-full-page-media, _:future, :root .webkit-fix-checkbox {
    display: grid;
}
