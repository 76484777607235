.version-history-modal {
  .modal-dialog {
    width: 768px;
    max-width: 90vw;
  }

  .modal-content {
    height: 800px !important;
    max-height: 90vh !important;
    color: #505050;

    .modal-header {
      .vh-modal-header {

        display: flex;
        justify-content: space-between;

        p {
          font-size: 14px;
        }

        &__btns {
          display: flex;
          column-gap: .5em;
          font-size: 1.3em;
          &:hover{
            cursor: pointer;
          }
        }
      }

    }


    .modal-body {
      height: 100% !important;
      position: relative;

      #vh-bs-table {
        .col-md-12 {


          @media (max-width: 560px) {
            min-width: 175%;
            overflow-x: scroll;
          }

          .react-bs-table-container {
            margin-bottom: 0 !important;

            .react-bs-container-body {
              margin-bottom: 65px;
            }

            .react-bs-table-pagination {
              position: fixed;
              width: 99%;
              bottom: 1px !important;
              left: 1px !important;
              right: 0;
              background: white;
              border-radius: 6px;
              height: 60px;

              .row {
                margin: 0 !important;
                transform: translateY(-50%);
                padding: 0 15px;
                top: 50%;
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                overflow-x: scroll;

                > div {
                  padding: 0 !important;
                }

                .dropdown-menu {
                  top: unset !important;
                  bottom: 150% !important;
                  min-width: unset !important;
                }
              }
            }
          }
        }
      }

      .vh {
        padding: 1em 0;


        &__event {
          display: grid;
          gap: 10rem;
          grid-template-columns: 3fr 1.5fr 1.5fr;

          &--on-wo {
            grid-template-areas:
              '. work-order proposal';
          }

          &--on-scheduled {
            grid-template-areas:
              'assignee work-order proposal';
          }

          &--on-invoiced {
            grid-template-areas:
              'invoice work-order proposal';
          }

          &--proposal {
            grid-area: proposal;
          }

          &--work-order {
            grid-area: work-order;
          }

          &--invoice {
            grid-area: invoice;
          }

          &--assignee {
            grid-area: assignee;
          }

          p {
            font-size: 1.2em;
            width: max-content;
          }
        }

        &__records {
          display: grid;
          grid-row-gap: 1.2em;
          padding: .75em;

          &--add-border {
            border: #969696 solid .02px;
            border-radius: 5px;
          }

          .vh-row {
            display: grid;
            background: #FCFCFC;
            grid-column-gap: 1.5em;
            grid-template-areas:
            'idx service-name assets-ids man-hours wo-date priorities status-transition service-total promised-dates';
            grid-template-columns: auto 2fr 1fr 1fr 6fr 2fr 1.5fr auto auto;
            padding: .75em 1.5em;
            border-color: #dddddd;
            border-radius: 5px;
            box-shadow: 2px 1px 5px 2px rgb(218, 218, 218);

            &--full{
              display: block;
            }

            &--idx {
              grid-area: idx;
            }

            &--service-name {
              grid-area: service-name;
            }

            &--assets-ids {
              grid-area: assets-ids;
            }

            &--man-hours {
              grid-area: man-hours;
            }

            &--wo--date{
              grid-area: wo-date;

              .prev-wo-date {
                span, p {
                  text-decoration: line-through;
                }
              }
            }

            &--priorities {
              grid-area: priorities;
            }

            &--status-transition {
              grid-area: status-transition;
            }

            &--service-total {
              grid-area: service-total;
            }

            &--promised-dates{
              grid-area: promised-dates;
            }
          }
        }
      }
    }
  }
}