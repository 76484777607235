#customer-view-tabs {
  margin: 2em auto 20px auto;
  top: 0;
  z-index: 2;
  width: max-content;

  ul {
    display: flex;
    justify-content: space-between;
    background: #eeeded;
    font-size: 16.5px;

    .active {
      &::before{
        content: "";
        height: calc(100% + 1px);
        width: 100%;
        z-index: 1;
        background: white;
        position: absolute;
      }

      a {
        color: #777777;
        background-color: #fcfcfc;
        border-bottom-color: transparent;
        width: max-content;
        z-index: 2;
      }
    }

    li {
      margin: 0 15px;
      border-left: #d9d9d9 solid 1px;
      border-top: #d9d9d9 solid 1px;
      border-right: #d9d9d9 solid 1px;
      background: white;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #d9230f;
        margin: 0;
      }
    }

    &::before, &::after {
      display: none !important;
    }
  }
}

.pull-right {
  #customer-view-tabs {
    min-width: 100% !important;
    margin: 0 !important;
  }
}
