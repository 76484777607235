@media (min-width: 959px) {
	.navbar-default {
		top: 0;
		left: 0;
		right: 0;
	}

	.mapview-fixed-height-body {
		height: 100%;
		overflow:hidden;
	}

	.vertical-responsive-height .proposal-form-panel .panel-body {
		padding-bottom: 50px !important;
		padding-top: 15px;
	}


	.mapview-proposal-services .panel-footer {
	  /*position: absolute; */
	  bottom: 0;
	  width: 100%;
	  z-index: 99;
	  width: 100%;
	}

	.mapview-proposal-services .panel-body{
		padding-bottom: 0 !important;
		padding-top: 0;
	}

	.assets-list{
		overflow-x: hidden;
		height: calc(100vh - 150px);
	}

	.vertical-responsive-height h5{
		padding-left: 10px;
	}

	.mobile-padding {
		max-height: 10vh;
	}

	.vertical-responsive-map {
		height: calc(100vh - 152px);
		overflow: auto;
	}
}

@media (max-width: 960px) {
	.vertical-responsive-map {
		height: 100vh;
		overflow: auto;
	}
}

