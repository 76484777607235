.site-moving-section {
    padding: 15px;
    margin: 15px 0 40px 0;
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.site_note_textarea {
    resize: vertical;
}

.site_editor_modal .modal-dialog .modal-content {
    height: auto !important;
}