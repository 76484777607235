.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #eeeded;
}

#customer-view-info-modal .modal-content {
  height: auto !important;
}

#customer-view-modal .modal-dialog > div {
  width: 80vw;
  height: 90vh !important;
}

.modal-image {
  display: block;
  max-height: calc(90vh - 150px);
  width: auto;
  height: auto;
}

.customerView {
  background-color: #eeeded;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100vw;

  &__map {
    opacity: 0;
    transition: all 1.5s ease-in-out;
  }

  .showMap {
    opacity: 1;
  }

  > div {
    padding: 0 10px;
  }

  .table {
    height: auto !important;
  }

  &__header {
    padding: 10px !important;
    background: white;
    position: relative !important;
    display: flex;
    justify-content: space-between;
    column-gap: .75em;

    .white-stripe {
      min-height: 90px;
    }

    * p {
      margin: 0 !important;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0;
      background: #fff;
    }

    &__Mob {
      flex-direction: row;
    }

    &--disable-grid {
      display: block;
    }

    .cVh {
      &__logo {
        img {
          max-height: 80px;
          max-width: 200px;
        }
      }

      &__company-details {
        font-size: .85em;
      }

      &__divide {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-gap: .75em;

        &--align-left {
          text-align: left;
        }

        &--align-right {
          text-align: right;
        }
      }

      &__divide-gap-column {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-column-gap: .75em;
        height: fit-content;
      }

      &__actions {
        display: flex;
        align-items: flex-start;
        column-gap: 1em;
      }
    }

    .title {
      font-weight: 900;
      font-size: clamp(1rem, 5vw, 3rem);
      text-transform: uppercase;
      color: #2a2a2a;
    }

    .subtitle {
      font-weight: bold;
      font-size: clamp(1rem, 5vw, 1.5rem);
      color: #2a2a2a;
    }

    .company-logo > img {
      max-height: 80px;
      max-width: 120px;

      > div {
        padding: 0 !important;
      }

    }

    .site-arborist-header {
      p {
        margin: 0 !important;
        color: #2f6405;
      }
    }

    .request-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border-radius: 4px;
      border: 2px solid #7DB00E;
      font-size: 13px;
      color: white;
      background: #7DB00E;

      &:hover, &:focus {
        color: #4e6e0a;
        border: 2px solid #4e6e0a;
        background: #7DB00E;
      }

      &--phone {
        display: flex;
        justify-content: center !important;
      }
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    height: 100%;
    margin: 0 !important;
    padding-bottom: 10px !important;
    overflow: auto;

    .items-container {
      overflow-y: auto;
      position: relative;
      overflow-x: hidden;
      padding: 0 0 0 10px !important;
      height: 100%;

      .item, .item-link {
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        border-radius: 2px;
        background-color: white;
        padding: 20px 20px;
        width: 90%;
        max-width: 750px;
        margin: 10px auto !important;

        .text-transform-none {
          text-transform: none !important;
        }

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          text-transform: uppercase;

          .item-title {
            text-align: left;
            font-weight: bold;
            font-size: 16px;
            width: 100%;

            &__sites {
              &--name {
                font-weight: bold;
              }

              &--address {
                font-size: .62em;
              }
            }
          }

          .item-name {
            font-weight: bold;
            color: #4e6e0a;
          }

        }

        .item-data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 10px;

          .flex {
            display: flex;
          }

          .request {
            display: flex;
            flex-direction: column;

            > div {
              display: flex;
              margin-top: 5px;

              > span {
                font-weight: bold;
                margin-right: 5px;
              }
            }
          }
        }

        .item-status, .item-status-clickable {
          cursor: default;
          font-size: 12px;
          padding: 2px 5px;
          border-radius: 5px;
          color: #8b0000;
          background-color: #ffd9d9;
        }

        .item-status-clickable:hover {
          transform: scale(1.1);
          transition: all 0.3s ease;
          filter: brightness(0.90);
          cursor: pointer;
        }

      }

      .item-link:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.3s ease;
        background-color: cornsilk;
      }

      .messages-container {
        box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
        border-radius: 1px;
        width: 100%;
        max-width: 750px;
        margin: 10px auto;
        display: grid;
        position: relative;
        text-align: initial;
        background: white;

        .message-header {
          padding: 20px 20px;
          z-index: 1;
          font-size: 1.75rem;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          p {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
          }

          span {
            font-weight: bold;
            color: #AC5349;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .message-wrapper {
          overflow-y: auto;
          position: relative;
          display: grid;
          justify-items: end;
          padding: 10px 15px;

          .message-container {
            display: grid;
            grid-auto-rows: quote("min(100px, auto)");
            grid-row-gap: 1rem;
            width: 100%;
            position: relative;
            background: white;

            .message {
              padding: 10px 15px;
              width: 80%;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              position: relative;
              border: solid 2px #d3d3d3;
              border-radius: 5px;

              &--phone {
                width: 95%;
              }

              .date {
                display: flex;
              }

              .sender {
                display: flex;
                justify-content: flex-end;
                text-align: right;
                font-weight: bold;
              }

              .message-body {
                display: flex;
                grid-column: 1/3;
                align-items: center;
              }

            }

            .is-author {
              justify-self: right;
            }
          }
        }


      }

      .few-messages {
        height: auto;
      }
    }


    display: flex;
    justify-content: center;

    &--mobile {
      display: block;
    }

    .events {
      &__left-menu {
        position: sticky;
        top: 0;
        color: #d9230f;
        display: flex;
        flex-direction: column;
        row-gap: .5em;

        &--mobile {
          color: #363636;
        }

        p {
          font-size: 16px;
          background: white;
          padding: 7.5px 15px;
          border: #d9d9d9 solid 1px;
          margin-bottom: 2px;

          &:hover {
            cursor: pointer;
          }
        }

        &--selected {
          color: #777777;
          background-color: #fcfcfc !important;
        }
      }
    }

    .add-new-button {
      margin-top: 15px;
      border-radius: 4px;
      border: 2px solid #7DB00E;
      background-image: none;
      background-color: #7DB00E;
      color: #fff;
      font-size: 13px;
      font-weight: 800;
      margin-right: 5px;
      margin-left: 5px;
    }

    .payment-info {
      margin-top: 30px;
      font-size: 16px;
      font-weight: bold;
    }

    .cards-icons {
      margin-top: 30px;
    }
  }

  &__map {
    margin: 0 !important;
    padding: 10px 10px !important;
    position: relative;
    height: 100%;
    width: 100vw;

    #sitessMap {
      height: 100% !important;
      max-height: 700px;
    }

    #map_view {
      height: 100% !important;
      max-height: 700px;
    }
  }

  &__items {
    width: 100%;
    position: relative;
    height: 100%;
    padding: 0 12px 0 0 !important;
  }

  .dock {
    position: absolute;
  }

  .right-side-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
  }

  .background-white {
    background-color: white;
  }

  .background-yellow {
    background-color: yellow !important;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .widgets {
      position: absolute;
      right: 0;
    }

    .phone {
      position: relative;
    }
  }

  .image-button {
    position: relative;
    cursor: pointer;
    display: inline-block;

    .message-notification-view.badge {
      position: absolute !important;
      right: 0px !important;
      top: 0px !important;
    }

    &--float-right {
      float: right;
    }
  }

  .proposal-button {
    float: right;
    border-radius: 4px;
    width: 100%;
    min-width: 180px;
    max-width: 180px;
  }

  .header-price-date {
    display: flex;
    flex-direction: column;

    .price {
      min-width: 60px;
      text-align: end;
    }
  }

  &--Mob {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    min-height: 100vh;
    overflow: hidden;
    width: 100vw;

    .showMap {
      opacity: 1;
    }

    > div {
      padding: 0 0 0 0 !important;
    }
    .customerView__header{
      padding-left: 10px !important;
      padding-bottom: 10px !important;
    }
    .customerView__items{
      padding-left: 10px !important;
    }

    .col-xs-12 {
      margin: 0 !important;
    }

    .customerView {
      &__header {
        margin: 0 !important;
        background-color: white;
        grid-template-columns: 1fr;
        flex-direction: column;
        row-gap: 1.5em;

        .cVh {
          &__divide {
            font-size: 12px;
          }
        }

        > div {
          padding: 0 !important;
          margin: 0 !important;
        }


      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        row-gap: 1em;
        height: calc(100vh - 150px);

        .events__left-menu {
          display: block;

          &--mobile {
            color: #2a2a2a;
          }
        }

        .items-container {
          padding: 0 10px !important;
        }

        .events {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.proposal-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .image-button {
    position: relative;
    cursor: pointer;
    display: inline-block;

    .message-notification-view.badge {
      position: absolute !important;
      right: 0px !important;
      top: 0px !important;
    }
  }
}

#customer-view-modal .modal-body {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

#customer-payment-modal {
  .pre-pay-button {
    margin: 15px 5px;
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    background-image: none;
  }

  .pre-pay-button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }
}


#customerView .sheet-table {
  overflow-y: auto;
  padding: 0 0 0 10px !important;
}

#customerView .fit-content-height {
  word-break: break-word;
  box-sizing: border-box;
  height: fit-content;
  width: 100vw;
}


::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


#request-modal {

  color: black;

  .modal-body {
    padding: 25px 15px 5px !important;
  }

}

@media (max-width: 1400px) {
  .customerView__header-wrapper {
    flex-direction: column;

    .proposal-details {
      padding-bottom: 0 !important;
      word-break: break-word;
    }

    .proposal-actions {
      padding-top: 0 !important;
    }
  }

  .customerView__header {

    .cVh__actions {
      align-items: center;
    }

    .cVh__divide {
      flex: none;
    }
  }

  .customerView .header-price-date {
    flex-direction: row;

    .justify-flex-end {
      align-items: flex-end;
    }
  }

  .proposal-button:not(:first-child) {
    display: flex;
    margin-left: 10px;
    max-width: none;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  #customer-view-modal .modal-dialog {
    width: unset !important;
  }

  #map_view {
    height: calc(250px) !important;
    width: auto !important;
  }
}

@media (width: 768px) and (height: 1024px) {
  .padding-left-10 {
    padding-left: 10px !important;
  }
}

@media (max-width: 1023px) {
  #customer-view-modal .modal-dialog > div {
    width: 100%;
    height: 90vh !important;
  }

  #customerView .sheet-table {
    height: 70vh;
  }

  .customerView__content {
    flex-direction: column;
    width: 100vw;
  }

  .customerView__header {
    justify-content: flex-start;
  }

  .cVh__actions {
    display: flex;
    justify-content: flex-end;

    p {
      margin: 0;
    }

    .proposal-status {
      align-items: center;
    }

    .header-price-date {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .price {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-width: 60px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .right-side-header {
    display: flex;
    justify-content: space-evenly;
  }
}

@media (max-width: 650px) {
  .cVh__actions {
    .header-price-date {
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .proposal-button:last-child {
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }

  .vertical-align {
    align-items: flex-start !important;
  }
}

@media (max-width: 550px) {
  .cVh__divide--align-right {
    min-width: 0;
  }
}

@media (max-width: 350px) {
  .cVh__actions {
    flex-direction: column;
    align-items: flex-end;
  }
}

.customer-assets {
  margin-top: 2rem;
  column-gap: .5rem;

  &__photos {
    display: flex;
    column-gap: .5rem;
    margin-top: .5rem;
    flex-wrap: wrap;

    & img {
      width: 25px;
      height: 25px;
    }
  }
}

#request-modal {
  .form-group b {
    margin-bottom: 1rem;
  }

  .modal-image {
    display: block;
    max-height: calc(90vh - 150px);
    width: auto;
    height: auto;
  }

  .new-address {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;

    .contact {
      grid-column: 1/4;
    }

    .sitename {
      grid-column: 4/7;
    }

    .address {
      grid-column: 1/4;
    }

  }

  .new-adr-btn {
    color: #7DB00E;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: color, background-color, outline;
    font-weight: 600;
  }

  .new-adr-btn:hover {
    color: #517209;
  }
}


.bundle-service .panel-body {
  padding: 5px 10px !important;
}

.customer-assets {
  margin-top: 2rem;
  column-gap: .5rem;

  &__photos {
    display: flex;
    column-gap: .5rem;
    margin-top: .5rem;

    & img {
      width: 25px;
      height: 25px;
    }
  }
}


#bundle-service-customer-view .panel-collapse {
  display: block;
  overflow: hidden;
  height: 20px;
  white-space: nowrap;
  max-width: 400px;
}

#bundle-service-customer-view .collapse.in {
  display: block;
  overflow: hidden;
  height: auto;
  white-space: inherit;
}

#bundle-service-customer-view .blue-underline-text {
  color: #0a68b4;
  text-decoration: underline;
  transition: all 500ms;
}

#bundle-service-customer-view .bs-hidden-notes {
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 10%, #777777 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#bundle-service-customer-view .no-border > div {
  min-height: 20px;
}