.general-progress-container{
  margin-top: 20px;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
  .confetti {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  @media screen and (max-width: 450px) {
    .sections-container{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  @media screen and (min-width: 451px) {
    .sections-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }
  }
  .sections-container{
    margin-top: 20px;
  }
}