#equipment-checklist {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }

  .hide-checklist {
    .modal-content {
      min-width: auto !important;
      width: 400px;
      transition: all 1s;
    }
  }

  .show-checklist {
    .modal-content {
      width: 768px;
      transition: all 1s;
    }
  }

  .question-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .question-answer-icon {
      cursor: pointer;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .small-button-margin-left {
    margin-left: 5px;
  }
}