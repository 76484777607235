.options-history-modal {
    .modal-dialog {
        width: 90vw;
    }

    .modal-content {
        height: 800px !important;
        max-height: 90vh !important;
        color: #505050;

        .modal-body {
            padding: 15px !important;

            .history-loader {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .rows-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }

        .modal-header {
            .oh-modal-header {

                display: flex;
                justify-content: space-between;

                p {
                    font-size: 14px;
                }

                &__btns {
                    display: flex;
                    column-gap: .5em;
                    font-size: 1.3em;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .footer-wrapper {
            display: flex;
            justify-content: space-between;

            .history-pagination {
                display: flex;
                justify-content: flex-end;
                gap: 5px;

                .button {
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(245, 74, 61);
                    border-radius: 5px;
                    cursor: pointer;
                }

                .button:hover {
                    background-color: rgba(245, 74, 61, 0.8);
                }

                .inactive {
                    background-color: rgba(245, 74, 61, 0.7);
                }

                .active {
                    border: 2px solid black;
                }
            }
        }

        .history-modal-row {
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 0 24px -10px rgba(66, 68, 90, 1);


            .medium-label {
                font-size: 100%;
            }

            .big-label {
                font-size: 120%;
            }

            .flex-col-row {
                display: flex;
                flex-direction: column;
            }

            .checkboxes-row {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
            }
        }
    }
}