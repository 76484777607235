#chemical-compliance-modal {
  .modal-dialog {
    width: 90vw;
  }

  .proposal-service-cc {
    display: flex;
    flex-direction: column;

    .service-name {
      padding: 10px;
      background-color: lightgrey;
    }

    .service-assets {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .service-asset {
        display: flex;
        flex-direction: column;
        width: 100%;

        .asset-row {
          display: flex;
          border-bottom: lightgrey 1px solid;

          .asset-name {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .dry-checkbox {
              display: flex;
              gap: 5px;
            }
          }

          .cc-input {
            padding-left: 5px;
            padding-right: 5px;
          }

          .border-right {
            border-right: lightgrey 1px solid;
          }
        }

        .asset-new-cc {
          margin-top: 5px;
          width: 100%;
        }
      }
    }
  }
}