.customer-invoices {
  @media (max-width: 768px) {
    max-width: 100vw;
    .react-bs-table-container {
      max-width: 100vw;
      overflow: auto;
    }

    .react-bs-container-footer, .react-bs-container-header {
      overflow: visible;
    }

    .react-bs-table-pagination {
      position: fixed;
      padding-right: 40px;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }

    .react-bs-table-container {
      padding-bottom: 100px;
    }
  }

}