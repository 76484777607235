#issue-list {
    padding-top: 15px;
}
.issue-preview-footer-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#issue-list .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#issue-list .react-bs-table {
    max-height: calc(100vh - 265px) !important;
    height: auto !important;
    overflow: auto !important;
}

#issue-list .react-bs-container-body {
    margin-top: 36px;
}

#issue-list .vertical-responsive-height {
    height: calc(100vh - 210px);
    overflow-y: hidden;
}

.issue-list-header-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.issue-list-title {
    font-weight: bold;
    color: #777777;
    letter-spacing: 0.25px;
}

.issue-list-count {
    margin: 10px 0 0 4px;
    font-size: 12px;
    color: #d9230f;
}

.issue-record-video {
  max-width: 250px !important;
  height: 150px !important;
  cursor: pointer !important
}

@media (min-width: 1024px) {
    #issue-list .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #issue-list .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}