
.counter {
    padding: 2px 3px;
    border-bottom: 3px solid lightgreen;
}

.proposal-service-status-declined {
    color: red;
}

.proposal-service-status-accepted {
    color: green;
}

.price {
    float: right;
}

.single-service {
    width: 100%;
    padding: 0px 10px;
}

.single-service:nth-child(odd) {
    background-color: #f3f3f3;
}

.service-details {
    padding-bottom: 10px;
}

.trees {
    padding: 1px 1px;
    border-bottom: 2px solid darkgray;
}

.assets-container {
    padding: 10px 0px;
}

table {
    width: 100%;
}

.header-space {
    display: none;
}

#legend-assetSummary {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-assetSummary > div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#legend-assetSummary span {
    margin: 1px 10px;
    display: inline-flex;
}

#legend-assetSummary span div {
    min-width: 25px;
}

#legend-assetSummary span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#legend-serviceType {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-serviceType > div {
    display: flex;
    flex-direction: column;
}

#legend-serviceType span {
    cursor: pointer;
    margin: 1px 10px;
    display: inline-flex;
}

#legend-serviceType span div {
    min-width: 25px;
}

#legend-serviceType span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#legend-serviceSummary {
    width: 100%;
    display: flex;
    margin-top: 30px;
    width: 180px;
}

#legend-serviceSummary > div {
    display: flex;
    flex-direction: column;
}

#legend-serviceSummary span {
    cursor: pointer;
    margin: 1px 10px;
    display: inline-flex;
}

#legend-serviceSummary span div {
    min-width: 25px;
}

#legend-serviceSummary span span {
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

#print_proposal {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: left !important;
    max-width: 100vw;
}

#print_proposal h3 {
    font-size: 1.8em;
}

#print_proposal .title {
    font-size: 1.4em;
}

#print_proposal .header-row {
    font-size: 1.2em;
    padding: 10px 0px;
}

#print_proposal h4 {
    font-size: 1.4em !important;
}

#print_proposal h5 {
    font-size: 1em;
}

#print_proposal .proposal-page-header {
    margin: 12px 0;
    padding: 24px 0;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
}

#print_proposal .map-wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

#print_proposal .map-wrapper > img {
    max-width: 100% !important;
}

#print_proposal .map-print > img {
    max-width: 100% !important;
}

.chart-wrapper {
    height: fit-content;
    width: fit-content;
    display: flex;
    padding-bottom: 20px;
}

.chart-wrapper > canvas {
    height: 200px !important;
}

#print_proposal .service-content {
    display: inline;
}

#print_proposal .asset-wrapper {
    display: block;
}

#print_proposal .asset-wrapper-inline {
    margin: 2px 2px;
    display: inline-block;
    vertical-align: top;
}

#print_proposal .asset-details {
}

#print_proposal .asset-details-vertically > span {
    display: block;
}


#print_proposal .thumbnails-vertically {
    margin: 0px 2px;
    display: block;
}

#print_proposal .thumbnails-vertically > img {
    margin: 2px 0px;

}

#print_proposal .thumbnails-horizontally {
    margin: 0px 2px;
    display: inline;
}

#print_proposal .thumbnails-horizontally > img {
    margin: 2px 0px;
}

#print_proposal .charts-align {
    display: flex !important;
}

.flex-basis-30 {
    flex-basis: 30%;
}

.flex-basis-40 {
    flex-basis: 40%;
}

.flex-basis-60 {
    flex-basis: 60%;
    padding-left: 5px;
}

.flex-basis-70 {
    flex-basis: 70%;
    padding-left: 5px;
}

#print-proposal-terms-edit-modal .modal-dialog {
    min-width: 90vw !important;
    max-width: 1200px !important;
}

#print-proposal-terms-edit-modal .rdw-editor-main {
    max-height: 380px;
}

#print-proposal-terms-edit-modal .terms-preview {
    font-size: initial;
    height: 500px;
    overflow: scroll;
}

@media (min-width: 1200px) {
    #print_proposal {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 1170px !important;
    }
}

@media (min-width: 992px) {
    #print_proposal {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 970px;
    }
}

/*@media (min-width: 768px) {*/
/*    #print_proposal {*/
/*        width: 750px;*/
/*    }*/
/*}*/

@media only screen and (min-width: 426px) and (max-width: 992px) {

    #print_proposal .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_proposal .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
    }

    #print_proposal .chart-wrapper {
        justify-content: center;
        height: fit-content;
        width: 100% !important;
    }

    #print_proposal .charts-align {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 425px) {

    #print_proposal {
        width: 90vw;
    }

    #print_proposal .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_proposal .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
    }

    #print_proposal .chart-wrapper {
        justify-content: center;
        height: fit-content;
        width: 100% !important;
    }

    #print_proposal .charts-align {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #print_proposal .map-wrapper {
        display: flex !important;
        flex-direction: column !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media print {

    .footer-space {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px
    }

    .header-space {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    .box {
        min-width: 12px !important;
        width: 12px !important;
        height: 7px !important;
        -webkit-print-color-adjust: exact;
    }

    .chart-wrapper > canvas {
        height: 100px !important;
    }

    #print_proposal .proposal-info {
        text-align: left !important;
        padding: 0 0 0 15px;
        display: flex;
        justify-content: flex-end;
    }

    #print_proposal .proposal-page-header {
        display: flex;
        margin: 12px 0;
        padding: 24px 0;
        border-top: 1px solid #aaaaaa;
        border-bottom: 1px solid #aaaaaa;
    }

    #print_proposal .map-wrapper {
        page-break-inside: avoid;
    }

    #print_proposal .asset-wrapper {
        page-break-inside: avoid;
    }

    #print_proposal .asset-wrapper-inline {
        page-break-inside: avoid;
    }

    #print_proposal .asset-details-vertically {
        page-break-inside: avoid;
    }

    #print_proposal .asset-details {
        page-break-inside: avoid;
    }

    #print_proposal .services-page {
        page-break-inside: avoid;
    }

    #print_proposal .services-page > thead {
        page-break-inside: avoid;
    }

    #print_proposal .photo {
        page-break-inside: avoid;
        page-break-after: always;
    }

    #print_proposal .services-page > tr {
        border: 1px solid #dddddd !important;
    }

    .terms-wrapper {
        page-break-inside: avoid;
    }

    .map-print {
        page-break-inside: avoid;
    }

}

