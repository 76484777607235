#work_order_modal .modal-dialog > div {
    height: 72vh !important;
    overflow-y: hidden !important;
}

@media (max-width: 1024px) {
    #work_order_modal .modal-dialog > div {
        height: 85vh !important;
    }
}

@media (max-height: 980px) {
    #work_order_modal .modal-dialog > div {
        height: 80vh !important;
    }
}

@media (max-height: 900px) {
    #work_order_modal .modal-dialog > div {
        height: 85vh !important;
    }
}

@media (max-height: 830px) {
    #work_order_modal .modal-dialog > div {
        height: 90vh !important;
    }
}

@media (max-width: 480px) {
    #work_order_modal .modal-dialog > div {
        height: 85vh !important;
    }
}

