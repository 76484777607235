.attachment {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80px;
    margin-right: 10px;
}

.attachment_checkbox {
    margin: 0 0 4px !important;
}

.attachment_name, .proposal_attachment_name {
    width: 60px;
    font-size: 10px;
    word-wrap:break-word;
    display:inline-block;
    text-align: center;
}

.attachment_name:hover {
    text-decoration: underline;
}

.grid_drop_zone {
    height: 135px;
    padding: 10px;
    overflow-y: auto;
}

.grid_drop_zone div {
    width: auto !important;
}

.attachment-preview img {
    max-height: 50px;
}