.margin-top-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

a#noanim-tab-example-tab-undefined {
  outline: none !important;
  background-color: transparent;
  color: #d9230f;
}

.name-field {
  position: relative;
  .error-message {
    font-size: 11px;
    color: #d9230f;
    position: absolute;
  }
  .error-border {
    border: 1px solid #d9230f !important;
  }

}
