.day-header {
  font-size: 10px;
  font-color: #000000;
  border-radius: 5px;
  text-align: center;
  display: list-item;
  list-style: none;

  .events-container{
    position: relative;

    .close-btn{
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }

  .header-inner {
    padding: 3px;

    &.holiday {
      background: #FCF75E;
    }
  }

  .employee-date-header {
    padding: 7px 0;
  }

  .day-number-text {
    right: 0;
    font-size: 13px;
    color: #777777;
    margin-right: 2px;
  }

  .day-text {
    font-size: 13px;
    color: #777777;
  }

  img {
    width: 25px;
    padding-right: 5px;
  }
}