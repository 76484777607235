.oauth_page_wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    justify-content: center;
}

.oauth_page_plaid_logo {
    height: 100px;
    margin-bottom: -15px;
}

.oauth_page_error_icon {
    color: red
}
