#client_transactions {
    padding: 20px;
}

#client_transactions .transactions_title {
    font-weight: bold;
    color: #777777;
    letter-spacing: 0.25px;
}

#client_transactions .transactions_header_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

#client_transactions .transactions_count {
    margin: 10px 0 0 4px;
    font-size: 12px;
    color: #d9230f;
}

#client_transactions .react-bs-table {
    max-height: calc(100vh - 407px) !important;
    height: auto !important;
    overflow: auto;
}

#client_transactions .react-bs-container-header.table-header-wrapper {
    z-index: 10 !important;
}


#client_transactions .vertical-responsive-height {
    height: calc(100vh - 350px) !important;
}

#client_transactions .transactions_btn {
    padding: 7px 8px;
}

#client_transactions .react-bs-container-header.table-header-wrapper {
    position: absolute;
    padding-right: 15px;
    background-color: white;
}

#client_transactions .react-bs-container-body {
    margin-top: 41px;
}

#client_transactions .uppercase {
    text-transform: uppercase;
    font-weight: bold;
}

#client_transactions .get_transactions_btn {
    background: #FF7619;
    border: solid 1px #FF7619
}

@media (min-width: 1025px) {
    #client_transactions .react-bs-table-pagination {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}