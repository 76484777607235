.pdf_attachment {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 60px;
    max-width: 80px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    padding: 5px;
    cursor: pointer;
}

.pdf_attachment .pdf_object {
    user-select: none;
    width: 55px !important;
    height: 50px;
    overflow: hidden;
    pointer-events: none;
}

.attachment_link {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 100;
    height: 50px;
    width: 50px;
}

.remove_attachment_icon {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 99;
}
.download_attachment_icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 99;
}
.download_attachment_icon {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1000;
}
