#matched-modal {
  .customer {
    padding: 15px;
  }

  .quickbooks-customer {
    width: 98%;
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }

  .selected {
    background: #e0e0e0;
  }

  .result-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-panel {
    background: rgba(5, 152, 0, 0.85);
    color: white;
    font-size: 30px;
  }

  .error-panel {
    background: rgba(168, 0, 0, 0.85);
    color: black;
    font-size: 18px;
    display: block;
    overflow-x: auto;
  }

  .loading-panel {
    background: rgba(255, 255, 255, 0.85);
  }
}