#checkbox-answer-type {
  .checkbox-options-wrapper {
    display: flex;

    input[type=checkbox] {
      scale: 1.5;
      cursor: pointer;
    }

    label {
      word-break: break-all;
      cursor: pointer;
    }
  }

  .horizontally-wrapper {
    margin: 15px 0 10px;
    justify-content: space-evenly;

    .horizontally{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;

      input[type=checkbox] {
        margin-bottom: 5px;
      }

      label {
        text-align: center;
        padding: 0 5px;
      }
    }
  }

  .vertically-wrapper {
    margin: 10px 20px;
    flex-direction: column;

    .vertically {
      display: flex;
      align-items: flex-start;

      input[type=checkbox] {
        margin: 3px 10px 0 0 !important;
      }
    }
  }
}