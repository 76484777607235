#work-order-list-page{
  .nav {
    li {
      a{
        padding-left: 4px;
      }
    }
    .dropdown-toggle {
      padding: 0;
    }
  }
}