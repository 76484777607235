#employee-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);

  .color-circle {
    border: 1px solid #ddd;
    min-height: 50px;
    min-width: 50px;
  }

  .responsive-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .picker {
    position: absolute !important;
    z-index: 1001;
  }

  .require-field {
    position: relative;
  }

  .error-message {
    position: absolute;
    color: red;
    font-size: 10px;
    bottom: -10px;
    left: 20px;
  }

  .error-color-message {
    position: absolute;
    bottom: -40px;
    color: red;
    font-size: 10px;
  }

  .user_color {
    display: flex;
    align-items: center;

    span {
      margin-left: 3px;
    }

    @media screen and (max-width: 620px){
      flex-direction: column;

      span {
        margin: 0;
        text-align: center;
      }
    }
  }

  .success_buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .reset_password_button {
      margin-right: 10px;

      @media screen and (max-width: 650px){
        margin: 10px 0 0 0;
      }
    }

    @media screen and (max-width: 650px){
      flex-direction: column-reverse;
    }
  }
}

@media (max-width:420px) {
  #employee-page {
    margin-top: 20px !important;

    .generate-password {
      button {
        margin-top: 5px;
      }
    }
  }
}

@media (min-width:421px) {
  #employee-page {
    margin-top: 100px !important;

    .generate-password {
      button {
        margin-top: 5px;
      }
    }
  }
}