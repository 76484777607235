.terms-modal {
  .modal-dialog {
    width: 800px;
  }

  .terms-modal-btn {
    max-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    background: #1b8dbb;
    border: 1px solid #5bc0de;
    font-weight: bold;
  }

  .terms-modal-header {
    text-align: center;
  }

  .terms-modal-title {
    color: #358856;
    font-size: 40px;
    font-weight: bold;
  }

  .terms-modal-header {
    border: none;
  }

  .terms-modal-body {
    padding: 15px;
    margin: 0 7px 5px 0;
    overflow-x: hidden;
    background-color: #F7FBFE;
  }

  .terms-modal-body > div {
    margin: 0px 10px 0 0px;
    font-size: 17px;

    p {
      color: #959baf;
    }

    span {
      margin-right: 5px;
    }

    .header {
      margin: 10px 0 20px 0;
      font-size: 29px;
      font-weight: bold;
      color: #404a70;
    }

    .important {
      margin: 20px 0 20px 0px;
      padding: 10px 0 10px 20px;
      border-left: 5px solid #70c200;
      line-height: 28px;
    }

    .info {
      font-weight: bold;
    }

    .list {
      padding-left: 18px;
    }

    .list-item {
      color: #959baf;
      font-weight: bold;
      margin-top: 20px;

      .sublist, p {
        margin-top: 10px;
        font-weight: normal;

        &.info {
          font-weight: bold;
        }

        .sublist-item {
          text-indent: 20px;
          margin-left: -40px;
        }
      }
    }

    OL {
      counter-reset: item
    }

    LI {
      display: block;
    }

    LI:before {
      content: counters(item, ".") ". ";
      counter-increment: item
    }
  }

  .terms-modal-body::-webkit-scrollbar {
    width: 12px;
  }

  .terms-modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .terms-modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 768px) {
  .terms-modal {

    .modal-dialog {
      width: calc(100vw - 20px)
    }
  }
}
