#holidays .react-bs-table {
    max-height: calc(100vh - 155px) !important;
    height: auto !important;
    overflow: auto !important;
}

#holidays .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#holidays .react-bs-container-body {
    margin-top: 41px;
}

@media (min-width: 1024px) {
    #holidays .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #holidays .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}
