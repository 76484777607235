.modal-content {
    height: 80vh !important;
}

@media (max-width: 768px) {
    .modal-content {
        height: 90vh !important;
    }
}

.modal-body {
    margin-top: 0 !important;
}
