.master-form {
    padding: 0 !important;
    border-right: 1px solid #dddddd;
}

.master-form h3 {
    margin: 5px 0 5px 0 !important;
}

.master-form-header {
    padding: 10px;
}

.master-form.in.modal .master-form-header {
    padding: 10px 10px 0 10px !important;
}

@media (max-width:767px) {
    .master-form-header {
        padding-top: 15px !important;
        margin: 0 !important;
    }
}

.master-form-body {
    padding: 5px;
    padding-top: 10px;
}

.master-form-content {
    height: calc(100vh - 350px);
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #dddddd;
}

@media (max-width: 991px) {
    .master-form-content {
        height: fit-content;
        overflow: hidden;
    }
}

.master-form-content::-webkit-scrollbar {
    width: 10px;
}

.master-form-content::-webkit-scrollbar-track {
    background-color: #ebebeb;
}

.master-form-content::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
}

.master-form-header small,
.master-form-header .glyphicon,
.master-form-header svg,
.master-form-header h3,
.master-form-header h4,
.master-form-header a {
    color: white !important;
}

.master-form-header .Select svg {
    color: gray !important;
}