#customer-header{
  @media(min-width: 1200px){
    margin-bottom: 10px;
    .top-menu a {
      line-height: 16px;
    }
    h5{
      margin: 0;
    }
  }
  @media(max-width: 991px){
    .buttons-mobile{
      display: flex;
      justify-content: space-between;
      margin: 12px 0;
      gap: 10px;
    }
    .customer-info-mobile{
      display: flex;
      margin-top: 8px;
      justify-content: center;
    }
    .top-menu a {
      line-height: 24px;
    }
    .customer-arborist{
      margin-bottom: 12px !important;
      margin-top: 8px !important;
    }
  }

}