#holiday-modal{
  .attachment-preview{
    margin-right: 10px;
    vertical-align: top;
    img{
      max-width: 55px !important;
    }
  }
  .doc-attachment-crew-icon, .doc-attachment-icon, .doc-attachment-crew-remove-icon, .doc-attachment-remove-icon{
    margin-left: 10px;
  }
  .pdf-attachment-preview{
    margin-right: 10px;
  }
  .doc-attachment-preview{
    max-height: 90px;
  }
}