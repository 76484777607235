.c-bundle-services {
  display: grid;
  margin-top: 2.5rem;

  heading {
    font-weight: bold;
  }

  .panel {
    margin-bottom: .5rem !important;

    .panel-heading {
      padding: 0 !important;

      a {
        color: grey;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .bundle-service-header {
    width: 100%;
    position: relative;
    background: #DCEBE8;
    display: grid;
    border-radius: 4px;
    padding: 10px;
    align-items: center;
    grid-column-gap: 5px;

    .bundle-service-header-paragraph {
      margin-bottom: 0;
    }

    &--MapViewPage {
      cursor: pointer;
      grid-template-columns:4fr 1fr 1fr 2fr 3fr 1fr;
      grid-template-areas:
      "name asset-numbers man-hours priority service-status-name service-total btn-delete";
    }

    &--StatusProposalServiceList {
      grid-template-columns: 1fr 3fr 3fr 2fr 1fr 1fr 1fr;
      grid-template-areas:
      "checkbox promised-date name service-status-name timing man-hours service-total";
    }

    &--ProposalServicesList {
      grid-template-columns:1fr 3fr 2fr 1fr 1fr 1fr 3fr;
      grid-template-areas:
      "checkbox promised-date name priority service-status-name man-hours service-total";
    }

    &--WoProposalServiceList {
      grid-template-columns:1fr 2fr 2fr 2fr 3fr 2fr;
      grid-template-areas:
      "checkbox name trees-plants service-status-name service-total completed-at";
    }

    &--NewInvoice {
      grid-template-columns:1fr 2fr 1fr 1fr 1fr 6fr;
      grid-template-areas:
      "checkbox name priority service-status-name man-hours service-total";
    }

    &__checkbox {
      grid-area: checkbox;
    }

    &__name {
      min-width: 80px;
    }

    &__trees-plants {
      grid-area: trees-plants;
    }

    &__promised-date {
      grid-area: promised-date;
      pointer-events: none !important;
    }

    &__name {
      grid-area: name;
    }

    &__completed-at {
      grid-area: completed-at;
      pointer-events: none !important;
    }

    &__service-status-name {
      grid-area: service-status-name;
      font-weight: bold;
    }

    &__timing {
      grid-area: timing;
    }

    &__man-hours {
      grid-area: man-hours;
      color: #3c763d;
    }

    &__btn-delete {
      grid-area: btn-delete;
      display: flex;
      justify-content: flex-end;
    }

    &__service-total {
      grid-area: service-total;
    }

    &__asset-numbers {
      grid-area: asset-numbers;
    }

    &__priority {
      grid-area: priority;
    }

    &__completed_at {
      grid-area: completed-at;
    }

    .btn {
      padding: .25rem .4rem !important;
      background: #EB1F0F;
      border: none !important;
    }
  }

  .bundle-service-body {
    display: grid;
    grid-template-areas:
          "trees trees"
          "service-notes wo-notes";

    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    min-height: 50px;
    grid-row-gap: 2rem;

    &__trees {
      grid-area: trees;

      span {
        display: inline-block;
        margin-left: .6rem;
      }
    }

    &__service-notes {
      grid-area: service-notes;
    }

    &__wo-notes {
      grid-area: wo-notes;

      textarea {
        display: block;
        position: relative;
        width: 100%;
        min-height: 50px;
      }
    }
  }

  .bundle-service-body-map-view {
    div {
      padding-top: 10px;
    }

    &__assets {
      table {
        border-top: 1px solid black;
        background-color: #f9f9f9;
        tr {
          padding-bottom: 2px;
        }
      }
    }

    &__price {
      display: flex;
      justify-content: space-between;
    }
  }
}
