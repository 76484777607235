.invalid{
  border-color: #FF0000 !important;
  .css-bg1rzq-control{
    border-color: #FF0000 !important;
  }
}

#moved-site-modal {
  .proposal-table {
    .table-button {
      text-align: center;
      font-weight: bold;
      color: black;
      border-radius: 10px;
    }

    .green-background {
      background: rgba(0,255,5,0.3);
    }

    .red-background {
      background: rgba(255,24,0,0.3);
    }

    .proposal-bootstrap-table-show {
      max-height: 250px;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: all 0.5s;
    }

    .proposal-bootstrap-table-hide {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
    }

    .table-striped {
      tr {
        transition: all 1s;
      }
    }
  }
}