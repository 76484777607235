.top-menu .links a {
    color: dimgray;
}

.top-menu a {
    line-height: 45px;
}

.top-menu a {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 12px;
}

.top-menu a.disabled, .top-menu a.disabled:hover {
    color: lightgrey;
    border-bottom: none;
    margin-bottom: 5px;
}

.top-menu a:hover {
    color: #2e97d9;
    border-bottom: 3px solid #2e97d9;
    text-decoration: none;
}

.top-menu .active {
    color: #2e97d9;
    border-bottom: 3px solid #2e97d9;
}

.col-md-6 .small-button {
    float: right;
}

.top-menu .nav {
    display: inline-block;
}

.top-menu .nav > li > a {
    padding: 0 10px;
    line-height: 40px;
    background: none !important;
}

.top-menu .nav > li > ul > li > a {
    border-bottom: none;
}

.alertStyles {
    position: fixed;
    width: 20%;
    top: 35px;
    right: 0;
    z-index: 3773;
}