#completed-phc-proposals {
  .title-bar{
    margin-top: 0;
    margin-bottom: 16px;
  }
  .filters-bar{
    @media screen and (min-width: 768px){
      display: grid;
      grid-template-columns: 6fr 2fr 3fr 0.6fr;
      grid-template-rows: 1fr;
      gap: 10px;
    }
    @media screen and (max-width: 767px){
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      grid-row-gap: 10px;
    }
  }

  .find-proposals-button{
    max-width: 120px;
  }

  .react-bs-container-header.table-header-wrapper {
    position: absolute;
    max-width: calc(100vw - 42px);
    background-color: white;
  }

  .react-bs-container-body {
    margin-top: 40px;
  }

  .react-bs-table {
    min-height: calc(100vh - 250px) !important;
    max-height: calc(100vh - 250px) !important;
    height: auto !important;
    overflow: auto !important;
  }

  .pagination {
    margin: -2px 0;
  }
}