.site_notes_wrapper {
  span {
    word-wrap: break-word;
  }

  .site_notes-edition {
    padding-left: 7px;
    height: 100px;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .site_notes {
      resize: none;
      padding: 5px 5px 5px 0;
      height: 97px;
      width: 100%;
      border: none;
      overflow-y: auto;
      outline: none;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: grey;
      }

      &::-webkit-scrollbar-track {
        margin: 5px;
      }
    }

    .site_notes_buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid rgb(119, 119, 119);
      padding: 0 15px;
      margin-left: 3px;
      height: 100px;

      .glyphicon-ok {
        margin-bottom: 8px;
      }
    }
  }
}
