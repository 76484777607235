#scheduler .fc .fc-timegrid-event-harness {
  pointer-events: none;
  width: 100%;
  left: 0!important;
}

.calendar-date-picker {
  position: absolute;
  top: 150px;
  left: 50px;
  z-index: 1000;
}

.calendar-date-picker-accept {
  position: absolute;
  right: 3px;
  top: 10px;
  z-index: 1001;
  transform: translateY(-50%);
  cursor: pointer;
  color: #f04124;
}

#scheduler .fc-state-disabled {
  opacity: 0.65;
  cursor: not-allowed;

  .fc-button-primary {
    pointer-events: none;
  }
}

#scheduler .fc-today-state-disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: none;
}

#scheduler .fc-scroller {
  height: auto !important;
  overflow-y: auto !important;
}

#scheduler .fc .fc-scrollgrid table {
  border-right-style: inherit !important;
}

#scheduler .fc-toolbar {
  margin-bottom: 27px !important;
}

.classic-balance-container {
  position: absolute !important;
  top: 42px !important;
  right: 15px !important;
  display: flex !important;
  font-weight: bold;
  font-size: 12px;
}

.classic-panel {
  height: 85vh !important;
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.classic-panel::-webkit-scrollbar {
  width: 12px;
}

.classic-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

.classic-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.classic-right-panel {
  z-index: 5;
  padding: 0 !important;
}

.classic-right-panel-opened {
  height: 85vh !important;
  z-index: 5;
  padding: 0 !important;
}

.classic-filter-btn {
  max-height: 30px !important;
  display: flex !important;
  align-items: center !important;
  background: #2C3E50 !important;
}

.classic-filter-element-name {
  color: #5c5c5c;
  font-weight: 600;
  background: #e3e3e3;
  padding: 2px 7px;
}

.classic-filter-element-icon {
  background: #e3e3e3;
  padding: 2px 5px 2px 5px;
}

.header-margin {
  margin: 0 0 10px 0 !important;
}

.classic-filter-element-name:focus {
  color: #ffffff;
  font-weight: 600;
}

.classic-input-search {
  width: 200px !important;
  position: absolute !important;
  height: 32px !important;
}

.classic-filters {
  width: 100% !important;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f5f5f5;
  padding-left: 3px;

  &:hover {
    background: rgba(245, 245, 245, 0.5);
  }
}

.classic-save-btn {
  background: #2C3E50 !important;
  padding: 2px 10px !important;
  max-height: 30px !important;

  &:hover {
    background: rgba(44, 62, 80, 0.9) !important;
  }
}

.classic-wo-filters-header {
  padding-left: 10px !important;
  padding-right: 15px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.fc {
  a {
    color: #777777;

    &:hover {
      color: #777777;
    }
  }

  table {
    background-color: transparent !important;
  }

  .fc-col-header-cell-cushion {
    width: 100%;
  }

  .fc-daygrid-day-number {
    padding: 0 !important;
    z-index: 10;
    width: 100%;
  }

  .fc-more-popover {
    z-index: 11;
  }

  .fc-timegrid-event {
    pointer-events: auto;

    .fc-event-main {
      overflow: hidden;
      padding: 0;
    }
  }

  .fc-v-event {
    border: none !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4) !important;
  }

  .fc-daygrid-event-harness{
    height: 30px;
    overflow: hidden;
  }

  .all-day-slot  {
    height: 40px;
    overflow: hidden;
  }


  @media (max-height: 700px) {
    .fc-button {
      padding: revert;
    }
  }

  @media (max-width: 676px) {

    .all-day-event {
      overflow: hidden;
      height: 60px;
    }

    .fc-timegrid-event {
      .fc-event-main {
        font-size: 8px;
        padding: 0;

        .event-content {
          line-height: 8px !important;
          padding: 2px;
        }
      }
    }

  }

  @media (max-width: 476px) {
    .classic-balance-container {
      position: absolute !important;
      top: 0 !important;
      right: 15px !important;
      display: flex !important;
      font-weight: bold;
    }

    .fc-toolbar {
      display: flex;
      flex-direction: column;

      .fc-toolbar-title {
        margin: 10px 0;
      }
    }

  }
}

@media (min-width: 476px) and (max-height: 700px) {
  .classic-balance-container {
    position: absolute !important;
    top: 35px !important;
    right: 15px !important;
    display: flex !important;
    font-weight: bold;
    font-size: 12px;
  }
}

@media (max-width: 1400px) {
  .classic-input-search {
    width: 140px !important;
    position: absolute !important;
    height: 32px !important;
  }
}

@media (max-width: 476px) {
  .classic-balance-container {
    position: absolute !important;
    top: 0 !important;
    right: 15px !important;
    display: flex !important;
    font-weight: bold;
    font-size: 10px !important;
    text-align: center !important;
    line-height: 1.15 !important;
    margin-top: 15px !important;
  }

  #scheduler .fc-toolbar {
    margin: 35px 0 20px 0 !important;
  }
}


