#map-scheduler .SplitPane {
  max-height: calc(100vh - 134px) !important;
}

#map-scheduler .fc-scroller {
  height: auto !important;
  overflow-y: auto !important;
}

#map-scheduler .fc-toolbar-chunk {
  display: flex !important;
}

#map-scheduler .fc .fc-scrollgrid table {
  border-right-style: inherit !important;
}

#map-scheduler .pointer-events-disabled {
  pointer-events: none;
}

#map-scheduler .Pane {
  padding: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow-y: hidden !important;
}

#map-scheduler .vertical-responsive-height {
  height: calc(100vh - 245px);
}

#map-scheduler .scheduler-wrapper {
  height: calc(100vh - 135px) !important;
}

#filter-modal .modal-dialog {
  display: flex;
  justify-content: center;
}

#filter-modal .modal-dialog > div {
  height: 60vh !important;
  min-width: 35vw !important;
}

#map-scheduler .events-balance-container {
  position: absolute !important;
  top: 35px !important;
  right: 15px !important;
  display: flex !important;
  font-weight: bold;
  font-size: 12px;
}

@media (max-width: 1594px) {
  #map-scheduler .vertical-responsive-height {
    height: calc(100vh - 240px);
  }
}


@media (max-width: 1461px) {
  #map-scheduler .vertical-responsive-height {
    height: calc(100vh - 275px);
  }
}

#filter-modal .modal-content {
  height: auto;
}

.padding10 {
  padding: 10px 0;
}

.title-border {
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
}

.item-background {
  background-color: #f2f2f2;
}

.left-panel {
  height: auto;
  max-height: 100%;
  overflow: auto;
}

.left-panel .row {
  margin: 0 !important;
}

#map-scheduler > div > div {
  height: 90% !important;
}

.btn-smaller {
  padding: 3px 8px !important;
  float: right;
}

.map-total {
  font-size: 16px;
  border: 1px #dddddd solid;
  border-radius: 10px;
}

.map-total .row:first-child {
  font-size: 12px;
}

.map-total .row:nth-child(2) {
  font-weight: bold;
}

.map-total > div:last-of-type {
  cursor: pointer;
  padding: 0;
}

.map-total .route {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: white;
  background-color: #dcdcdc;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

#map-scheduler .text-orange {
  font-size: unset !important;
}

#mapSchedulerMap {
  height: calc(100vh - 255px);
}

#filter-modal .select__menu > div {
  max-height: 200px;
  overflow-y: scroll;
}

#map-scheduler .fc-title > b, #map-scheduler .fc-title br:nth-child(2), #map-scheduler .fc-title small b:first-child {
  display: none;
}

@media (max-width: 992px) {
  #map-scheduler .SplitPane {
    flex-direction: column !important;
  }

  #map-scheduler .Pane {
    width: 100% !important;
    overflow-y: visible !important;
  }

  #map-scheduler .fc-left {
    text-align: left !important;
    margin-left: 0 !important;
  }

  #map-scheduler .fc-right {
    margin-left: 0 !important;
  }

  #map-scheduler .btn-default {
    float: left !important;
  }

  #map-scheduler .fc-toolbar {
    margin-top: 55px !important;
    margin-bottom: 0 !important;
  }

  .modal-dialog > div {
    height: 80vh !important;
  }
}

@media (max-height: 700px) {
  .fc-view-harness {
    margin-top: 24px;
  }
}

@media (max-width: 1240px) {
  #map-scheduler .fc-toolbar-title {
    font-size: 16px !important;
  }
}

@media (max-width: 476px) {
  #map-scheduler .fc-right {
    display: flex !important;
    justify-content: center !important;
  }

  #map-scheduler .fc-left {
    display: flex !important;
    justify-content: center !important;
  }

  #map-scheduler .events-balance-container {
    position: absolute !important;
    top: 0 !important;
    display: flex !important;
    justify-content: center;
    font-weight: bold;
    font-size: 11px !important;
    text-align: center !important;
    line-height: 1.15 !important;
    margin-top: 15px !important;
  }
}

#map-scheduler .Pane {
  padding-top: 0;
}

#map-scheduler .info-window {
  white-space: normal;

  .text-blue {
    top: 0;
    padding: 10px 0;
    z-index: 1;
    background: white;
  }

  .bundle-services {
    display: flex;

    .form-check-label {
      color: #777777;
    }

    &__panel {
      a {
        color: grey;
      }
    }

    &__status {
      color: #8a8a8a;
      font-weight: bold;
      display: flex;
      column-gap: .75rem;
    }

    .panel-default {
      border-color: transparent !important;
    }

    .panel {
      margin-bottom: 0 !important;
      background-color: transparent;
      border: transparent;
      box-shadow: none;

      .panel-body {
        padding: 5px 15px !important;
      }

      .panel-heading {
        transform: translateY(30%);
        background-color: transparent;
        border-color: transparent;
        padding: 0;

        &:hover {
          cursor: pointer;
        }

        .panel-title {
          font-size: 13px;

          a {
            color: #777777;
            font-weight: bold;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .text-orange {
          a {
            color: #FF9900 !important;
            font-weight: 600 !important;
          }
        }

        .text-purple {
          a {
            color: #9b479f !important;
          }
        }
      }
    }

  }

  .save-work-order {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;

    button {
      opacity: 1 !important;
    }
  }
}

#map-scheduler .fc .fc-timegrid-event-harness {
  width: 100%;
  left: 0 !important;
  pointer-events: none;
}

