.customer-sites-btn {
    border-radius: 0 !important;
    background: #474949 !important;
    border: 1px solid #474949 !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    padding: 4px;
}

.map_styles {
    height: calc(100vh - 270px) !important;
    width: calc(50vw - 30px) !important;
}

.customer-sites-map-options {
    height: 20px;
    margin-top: 30px;
    width: 100%;
}

.customer-sites-delete-map {
    color: #d9230f;
    font-weight: bold;
    cursor: pointer;
    font-size: 13px;
}

.customer-sites-delete-map:hover {
    opacity: 0.8;
    text-decoration: underline;
}

.customer-sites-map {
    padding-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
}

@media (max-width: 1400px) {
    .map_styles {
        height: calc(100vh - 270px) !important;
        width: calc(50vw - 20px) !important;
    }
}

@media (max-width: 1024px) {
    .map_styles {
        width: 100% !important;
        height: 50vh !important;
    }

    .customer-sites-btn {
        padding: 5px !important;
    }
}

@media (max-width: 480px) {
    .map_styles {
        width: 100% !important;
    }
}
