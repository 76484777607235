#service-priorities{
  .table-toolbar {
    display: flex;
    padding: 15px;
    width: 100%;
    justify-content: space-between;

    .buttons {
      button {
        padding: 6px 12px;
        margin-right: 10px;
      }

      @media screen and (max-width: 1024px) {
        button {
          margin-bottom: 2px;
          min-width: 150px;
          border-radius: 4px !important;
        }
      }
    }
  }
}