#leads .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;
}

#leads .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#leads .SplitPane {
    max-height: calc(100vh - 135px) !important;
}

#leads .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#leads .vertical-responsive-height {
    height: calc(100vh - 135px);
}

#leads .panel {
    margin-bottom: 10px !important;
}

#leads .panel > div > div {
    padding-bottom: 0 !important;
}

#leads .react-bs-container-body {
    margin-top: 41px;
}
#leads .wrapped-column {
    display: flex;
    flex-wrap: wrap;
}
#leads .wrapped-column .date-part {
    margin-right: 2px;
    margin-bottom: 2px;
    min-width: 50px;
}
#leads .wrapped-column .time-part {
    min-width: 35px;
}

@media (min-width: 1024px) {
    #leads .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #leads .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}