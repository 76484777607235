.conversation-wrapper {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 50px);
}

.conversation-footer {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid lightgray;
}

.conversation-textarea {
    flex: 1;
    width: 100%;
    margin: 10px;
}

.conversation-button {
    width: 90%;
    margin: 10px;
    margin-left: 0;
}

.sticky {
    width: 90%;
}