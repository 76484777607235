.dropzone_with_thumbnails {
    width: 100%;
    border: 1px dashed lightgray;
    text-align: center;
}

.dropzone {
    width: 100%;
    height: 70px;
    border: 1px dashed lightgray;
    text-align: center;
    display: flex;
    align-items: center;
}

.dropzone p{
    width: 100%;
    margin: 0;
}

.dropzone.small p {
    font-size: 11px;
    line-height: 17px;
}

.uploaded_files {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 10px 20px;
}