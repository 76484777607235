.event-content {
  word-break: break-word;
  white-space: normal;
  border-radius: 3px;
  line-height: 12px !important;
  padding: 2px 5px 2px 5px;

  &.month {
    overflow: hidden;
    max-height: 40px !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
    width: 100%;
  }
}
#user-tooltip .tooltip-inner {
  text-align: left !important;
}
