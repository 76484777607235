#customerSearch .react-bs-table {
    max-height: calc(100vh - 220px) !important;
    height: auto !important;
    overflow: auto !important;
}

#customerSearch .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#customerSearch .vertical-responsive-height {
    height: calc(100vh - 135px);
}

#customerSearch .SplitPane {
    max-height: calc(100vh - 135px) !important;
    overflow-y: auto;
}

#customerSearch .Resizer {
    z-index: 11 !important;
}

#customerSearch .react-bs-container-header.table-header-wrapper {
    z-index: 10 !important;
}

@media (min-width: 1025px) {
    #customerSearch .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
        background-color: white;
    }

    #customerSearch .react-bs-table-pagination {
        position: absolute;
        padding-right: 20px;
        bottom: 0;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .pagination {
        display: flex !important;
    }

    #customerSearch .react-bs-table-pagination {
        margin-top: 10px !important;
    }

    #customerSearch .vertical-responsive-height {
        height: calc(100vh - 160px);
    }
}

#customerSearch .Pane {
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#customerSearch .panel {
    margin-bottom: 10px !important;
}

#customerSearch .panel > div > div {
    padding-bottom: 0 !important;
}

#customerSearch .react-bs-container-body {
    margin-top: 41px;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 468px) {
    #customerSearch .pagination > li > a {
        padding: 8px 9px !important;
    }

    #customerSearch .btn-default {
        padding: 8px !important;
    }
}

@media (height: 1024px) {
    .modal {
        margin-top: 20px !important;
    }
}