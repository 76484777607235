#users-list > div {
    height: auto !important;
}

#users-list .nav-stacked li.active a {
    background-image: none !important;
}

#users-list .react-bs-container-body {
    overflow: hidden !important;
    overflow-y: scroll !important;
    height: auto;
    max-height: calc(100vh - 800px);
}

#users-list .full-height .react-bs-container-body {
    height: auto !important;
    overflow: auto !important;
    max-height: calc(100vh - 320px) !important;
}

#users-list > div > div.Pane.vertical.Pane1 .nav-pills{
    height: auto;
    overflow: auto;
    max-height: calc(100vh - 320px);
}

#users-list > div > div.Pane.vertical.Pane2 > div > div.Pane.vertical.Pane1, #users-list > div > div.Pane.vertical.Pane2 {
    padding-top: 0 !important;
}

#users-list > div > div.Pane.vertical.Pane2 > div > div.Pane.vertical.Pane1 > form > div > div.col-xs-12 > div > div.react-bs-table-pagination > div {
    display: flex;
    align-items: center;
}

#users-list > div > div.Pane.vertical.Pane2 > div > div.Pane.vertical.Pane2 {
    min-width: 250px;
}
