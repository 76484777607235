#batch-services{

  .buttons{
    margin-bottom: 20px;
    width: 100%;
    max-width: 110px;
  }
  .buttons-tooltip{
    margin-bottom: 20px;
    width: 100%;
    max-width: 130px;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .display-table-row{
    display: table-row !important;
  }
}