#dashboard_map .gm-style-iw-c {
    padding-top: 7px !important;
}

#dashboard_map .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#dashboard_map .react-bs-table-pagination {
    margin-top: 5px !important;
}

#dashboard_map .react-bs-table-pagination > div {
    margin-top: 3px !important;
}

#dashboard_map .dashboard-statuses {
    text-align: center;
    margin: 12px;
}

#dashboard_map .top-nav-btn {
    min-width: 12vw !important;
    height: 90px !important;
}

#dashboard_map .backlog-border {
    border-top: 1px solid #dddddd;
}

#dashboard_map .paddingTable {
    padding: 8px 10px 30px 10px !important;
}

#dashboard_map .btnHeight {
    max-height: 36px !important;
}

@media (min-width: 1025px) {
    #dashboard_map .react-bs-container-header.table-header-wrapper {
        position: absolute;
        padding-right: 45px;
    }
}

#dashboard_map .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#dashboard_map .react-bs-container-body {
    margin-top: 41px;
}

@media (width: 768px) and (height: 1024px) {
    #dashboard_map .proposalBadge {
        margin-left: 0 !important;
    }
}

@media (width: 1024px) {
    .top_nav_btn {
        padding: 3px 0 0 0 !important;
        min-width: 120px !important;
        max-width: 120px !important;
        font-size: 14px !important;
        height: 60px !important;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    #dashboard_map .btn-breadcrumb .btn {
        line-height: normal !important;
    }

    #dashboard_map .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    #dashboard_map .vertical-responsive-height {
        height: calc(100vh - 500px);
    }

    .tableHeight {
        height: calc(100vh - 415px) !important;
        margin-bottom: 40px !important;
    }

    #dashboard_map .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 320px !important;
        max-width: 320px !important;
        font-size: 14px !important;
        height: 50px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #dashboard_map .react-bs-container-header.table-header-wrapper {
        z-index: 10 !important;
    }

    #dashboard_map .react-bs-table-pagination {
        position: absolute !important;
        bottom: -25px !important;
        width: 100% !important;
    }

    #dashboard_map .proposalBadge {
        margin-left: 0 !important;
    }
}

@media (max-width: 768px) and (min-height: 900px) {
    .pagination {
        display: flex !important;
    }

    #dashboard_map .react-bs-table {
        height: calc(100vh - 450px) !important;
    }

    #dashboard_map .react-bs-table-pagination {
        margin-top: 10px !important;
        position: absolute !important;
        width: 100% !important;
    }

    #dashboard_map .vertical-responsive-height {
        height: calc(100vh - 450px);
    }

    .tableHeight {
        height: calc(100vh - 400px) !important;
        margin-bottom: 50px !important;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    #dashboard_map .btn-breadcrumb .btn {
        line-height: normal !important;
        padding: 3px 0 0 0 !important;
        min-width: 90px !important;
        max-width: 90px !important;
        font-size: 11px !important;
        height: 60px !important;
    }

    #dashboard_map .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    #dashboard_map .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        max-width: 220px !important;
        font-size: 12px !important;
        height: 50px !important;
        border: none !important;
        border-radius: inherit !important;
    }

}

@media (max-width: 468px) {
    #dashboard_map .pagination > li > a {
        padding: 8px 9px !important;
    }

    #dashboard_map .btn-breadcrumb .btn {
        line-height: normal !important;
    }

    #dashboard_map .react-bs-table-pagination {
        margin-top: 10px !important;
        position: absolute !important;
        bottom: 0 !important;
        width: 100% !important;
    }

    #dashboard_map .table_margin {
        margin: 30px 10px 0 10px !important;
    }

    #dashboard_map .vertical-responsive-height {
        height: calc(100vh - 390px);
    }

    #dashboard_map .btn-group btn-breadcrumb {
        width: 100% !important;
        line-height: normal !important;
    }

    .tableHeight {
        height: calc(100vh - 300px) !important;
    }

    #dashboard_map .btn_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 80px !important;
        max-width: 80px !important;
        font-size: 10px !important;
        height: 35px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #dashboard_map .btn_accepted_statuses {
        padding: 3px 0 0 0 !important;
        min-width: 100px !important;
        max-width: 100px !important;
        font-size: 10px !important;
        height: 35px !important;
        border: none !important;
        border-radius: inherit !important;
    }

    #dashboard_map .table_btn {
        padding: 6px 6px !important;
        font-size: 12px !important;
        height: 36px !important;
        border-radius: inherit !important;
    }

    #dashboard_map .btn-wrapper {
        display: flex;
        justify-content: center;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):after {
        display: none !important;
    }

    #dashboard_map .btn-breadcrumb .btn:not(:last-child):before {
        display: none !important;
    }

    .tableHeader {
        display: flex;
        flex-direction: column;
    }
}
