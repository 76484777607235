.licences {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  hr {
    width: 100%;
  }

  h4 {
    font-weight: bold;
    color: #777777;
    letter-spacing: 0.25px;
  }

  button.licences-button {
    background: #4379FF;
    border: 1px solid #326af5;
    outline: none;

    &:hover, &:active, &:focus {
      background: #1954e7;
      border: 1px solid #1147ce;
    }
  }

  .top-margin {
    margin-top: 10px;
  }

  .progress-bar {
    background: #00afe1;
  }

  .licences-section {
    padding: 0px 20px;
  }
}