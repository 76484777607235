 .hover-cell:hover div.text-left.col-xs-7 p {
text-decoration: underline;
 }

 #quickbooks .table-hover > tbody > tr:hover {
  background-color: rgba(86,193,254,0.3);
 }

 #quickbooks .row-customer-hidden {
  height: 100px;
 }

 #quickbooks .refresh-customer-active {
  color: green;
  animation: rotating 2s linear infinite;
 }

 @keyframes rotating {
  from {
   transform: rotate(0deg);
  }
  to {
   transform: rotate(360deg);
  }
 }
