.loading-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #eeeded;
}
#invoice_payment {

  .image-button {
    position: relative;
    cursor: pointer;
    display: inline-block;

    .message-notification-view.badge {
      position: absolute !important;
      right: 0px !important;
      top: 0px !important;
    }
  }
}

.modal-image {
  display: block;
  max-height: calc(90vh - 150px);
  width: auto;
  height: auto;
}

#invoice_payment .company-logo {
  display: flex;
  align-items: center;
}

#invoice_payment .company-logo > img {
  max-height: 120px;
  max-width: 120px;
}

#invoice_payment .sheet-table {
  overflow-y: scroll;
  padding: 0 0 0 10px !important;
}

#invoice_payment .sheet-table::-webkit-scrollbar {
  width: 12px;
}

#invoice_payment .sheet-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#invoice_payment .sheet-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

#invoice_payment .menu-column {
  height: fit-content;
}

#invoice_payment .space-evenly {
  justify-content: space-evenly;
}

#invoice_payment {
  .panel-body {
    padding: 10px;
  }
  .collapse {
    display: block;
    overflow: hidden;
    height: 20px;
    white-space: nowrap;
    max-width: 400px;
  }

  .collapse.in {
    display: block;
    overflow: hidden;
    height: auto;
    white-space: inherit;
  }

  .blue-underline-text {
    color: #0a68b4;
    text-decoration: underline;
    transition: all 500ms;
  }

  .bs-hidden-notes {
    background: linear-gradient(to top, rgba(255,255,255,0) 10%, #777777 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .no-border > div {
    min-height: 35px;
  }

  .cVh__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      margin: 0;
    }

    .proposal-status {
      align-items: center;
    }

    .header-price {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .price {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-width: 60px;
      }
    }
  }
  .due-date {
    font-size: 14px;
    color: #444444;
    font-weight: bold;
  }
  .balance {
    font-size: 32px;
    color: #444444;
  }
}

@media (max-width: 1400px) {
  #invoice_payment {
    .cVh__actions {
      align-items: center;
    }
  }
}

@media (max-width: 1024px) {
  #invoice_payment .sheet-table {
    height: 70vh;
  }
}

#invoice_payment .fit-content-height {
  box-sizing: border-box;
  height: fit-content;
}