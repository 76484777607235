.images-panel {
  display: flex;
  flex-direction: column;

  .panel-heading {
    padding: 0 15px !important;
  }

  .switches-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
  }

  .left-switches-wrapper {
    display: flex;
    gap: 10px;
  }

  @media screen and (max-width: 767px) {
    .left-switches-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px
    }
    .right-switches-wrapper {
      .timestamp-select {
        right: -5% !important;
        width: 220px !important;
      }
    }
    .material-switch {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @media screen and (max-width: 380px){
    .right-switches-wrapper {
      .timestamp-select {
        right: -5% !important;
        width: 160px !important;
      }
    }
  }

  @media screen and (max-width: 320px){
    .switches-container {
      display: block;
    }

    .right-switches-wrapper {
      .timestamp-select {
        right: -5% !important;
        width: 160px !important;
        transform-origin: 0 0;
        margin: 8px 0 0 -12px;
      }
    }
  }

  .right-switches-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .timestamp-select {
      width: 250px;
      color: black;
      scale: 0.8;
      z-index: 999;
      right: -25px;
    }
  }

  .material-switch > input[type="checkbox"] {
    display: none;
  }

  .material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
  }

  .material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
  }

  .material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
  }

  .material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
  }

  .material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
  }

  .panel-body {
    padding: 0;
    display: flex;
    flex-direction: column;

    .images-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .new-attachment-preview {
      width: 158px;
      margin: 10px 6px;
      padding: 8px 5px;

      &:nth-child(2n) {
        background-color: #e2ebef;
      }
    }

    .image-miniature {
    }
  }

  .dropzone {
    margin: 0px 0px 10px 0px;
  }

  .dropzone-wrapper {
    padding: 10px;
  }
}
