@media (max-width: 435px) {

    #print_invoice {
        width: 90vw;
    }


    #print_invoice .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_invoice .invoice-info {
        text-align: left !important;
        padding: 0;
    }

    #print_invoice .invoice-info h6 {
        padding-left: 15px !important;
    }

    #print_invoice .flex-basis-40 {
        flex-basis: 40%;
    }

    #print_invoice .flex-basis-30 {
        flex-basis: 40%;
    }

    #print_invoice .flex-basis-60 {
        flex-basis: 60%;
        padding-left: 5px;
    }

    #print_invoice .flex-basis-30 {
        flex-basis: 70%;
        padding-left: 5px;
    }
}