.justify-flex-start.account.col-xs-7 > div > div > div:nth-child(1) {
    display: none;
}

.justify-flex-start.account.col-xs-7 > div > div > div.col-xs-8 {
    width: 100%;
    padding: 0;
}

.justify-flex-start.account.col-xs-7 > div > div > div.col-xs-8 > span > div > div.col-xs-10 {
    margin-top: 11px;
}

.justify-flex-end.account-placeholder.col-xs-5 > p {
    margin: 11px 0 0 0;
}

.margin-minus-3 {
    margin-top: -3px;
}

.hover-cell:hover div.text-left.col-xs-7 p {
    text-decoration: underline;
}
#quickbooks .table-hover > tbody > tr:hover {
    background-color: rgba(86,193,254,0.3);
}

#error-modal .modal-dialog{
    width: clamp(200px, 90vw, 600px);
    min-width: auto !important;
}